export const environment = {
  production: false,
  hmr: false,
  app: 'ggcadmin',
  apiUrl: 'https://api.sandystarfish.site',
  gingr_login: 'https://api.sandystarfish.site',
  gingr_api: 'https://api.sandystarfish.site',
  mediaUrl: 'https://api.sandystarfish.site/media/',
  coinUrl: 'https://devapi.ggcoin.io',
  kycUrl: 'https://kyc-api.sandystarfish.site',
  ggcoinUrl: 'https://devapi.ggcoin.io',
  domain: 'admin.sandyseashell.site',
}
