import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'

// import { filter, map } from 'rxjs/operators'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatIconRegistry, MatSnackBar } from '@angular/material'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { SessionQuery } from '@app/admin/store/session.query'
import { IUser } from '@app/admin/store/session.store'
// import { SessionStore } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { StepStore } from '@app/admin/store/step.store'
import { BackendService } from '@app/shared/services/backend.service'

import { STEPS } from '../../models/wizardFlow.model'

export const selectedIdType = {
  0: 'passport',
  1: 'drivers_license',
  2: 'id',
}

export const selectedAddressType = {
  0: 'bill',
  1: 'tax_report',
  2: 'bank',
}
@Component({
  selector: 'vr-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []

  private userStatus = new ReplaySubject<number>(null)
  public userStatus$ = this.userStatus.asObservable()
  private idStatus = new ReplaySubject<number>(null)
  public idStatus$ = this.idStatus.asObservable()
  private aStatus = new ReplaySubject<number>(null)
  public aStatus$ = this.aStatus.asObservable()
  private wStatus = new ReplaySubject<number>(null)
  public wStatus$ = this.wStatus.asObservable()
  private psStatus = new ReplaySubject<number>(null)
  public psStatus$ = this.psStatus.asObservable()

  private currentUser$ = new Subject()
  public currentUser = this.currentUser$.asObservable()

  allSteps: Observable<any>
  steps: any
  wizardComplete: boolean = false
  whitelisted: boolean = false
  privateSaleOpen: boolean = false

  status = {
    complete: 1,
    declined: 2,
    missing: 3,
    pending: 4,
    resubmit: 5,
  }

  public user: IUser

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private wizardDataService: WizardDataService,
    private backend: BackendService,
    private wizardFlowService: WizardFlowService,
    private snackBar: MatSnackBar
  ) {
    iconRegistry.addSvgIcon(
      'private-sale',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/ggc_logo_white.min.svg'
      )
    )

    this.wizardDataService.getPersonalFormData()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.backend.getUser().subscribe(
        (res: any) => {
          this.user = res.result

          /*this.user.wizard_complete === 1
            ? (this.wizardComplete = true)
            : (this.wizardComplete = false)*/

          this.user.compliance_status === 3
            ? (this.whitelisted = true)
            : (this.whitelisted = false)

          this.whitelisted
            ? (this.privateSaleOpen = true)
            : (this.privateSaleOpen = false)

          this.currentUser$.next(res)
        },
        err => {
          throw err
        },
        () => {
          this.getKycProgress(this.user)
          this.wizardFlowService.stepsCompleted(this.user)
        }
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * launch a specific wizard page
   * @param event
   * @param {string} wizard
   * @param {number} status
   */
  public startWizard(event, wizard: string, status: any) {
    let stat
    this.subscriptions.push(
      status.subscribe(res => {
        stat = res
        if (wizard === 'details') {
          this.router.navigateByUrl('admin/profile/kyc/' + wizard)
          this.wizardFlowService.initWizard()
        } else {
          if (this.user.compliance_status !== 3) {
            if (stat !== null && stat !== 2 && stat !== 3) {
              return
            } else if (stat === 2) {
              this.findDeclined(wizard)
            } else if (stat === 3) {
              this.findOld(wizard)
            } else {
              this.router.navigateByUrl('admin/profile/kyc/' + wizard)
              this.wizardFlowService.initWizard()
            }
          } else {
            return
          }
        }
      })
    )
  }

  /**
   * find any declined images
   * @param wizard
   */
  findDeclined(wizard: string) {
    if (wizard === 'type-address') {
      const images: any = this.user.files.filter(
        (files: any) => files.type === 0
      )

      if (images[0].status === 2) {
        this.wizardFlowService.invalidateStep(STEPS.upload)
        if (this.user.address_type) {
          return this.router.navigate(['admin/profile/kyc/upload-address'], {
            queryParams: { type: selectedAddressType[this.user.address_type] },
          })
        } else {
          this.subscriptions.push(
            this.wizardDataService.getAddressType().subscribe(res => {
              return this.router.navigate(
                ['admin/profile/kyc/upload-address'],
                {
                  queryParams: {
                    type: selectedAddressType[res.result.address_type],
                  },
                }
              )
            })
          )
        }
      }
    }

    if (wizard === 'type-id') {
      const files1 = this.user.files.filter((file: any) => file.type === 1)
      const files2 = this.user.files.filter((file: any) => file.type === 2)
      const files3 = this.user.files.filter((file: any) => file.type === 3)
      const allFiles = []

      if (files1.length > 0) {
        allFiles.push(files1[files1.length - 1])
      }

      if (files2.length > 0) {
        allFiles.push(files2[files2.length - 1])
      }

      if (files3.length > 0) {
        allFiles.push(files3[files3.length - 1])
      }

      const files = allFiles.filter((item: any) => item.status === 2)

      if (files.length > 0) {
        files.forEach(file => {
          if (file.type === 1 || file.type === 2) {
            this.wizardFlowService.invalidateStep(STEPS.uploadId)
          }

          if (file.type === 3) {
            this.wizardFlowService.invalidateStep(STEPS.uploadSelfie)
          }
        })
      }

      if (files.length > 0) {
        if (files[0].type === 1 || files[0].type === 2) {
          if (this.user.id_type) {
            this.router.navigate(['admin/profile/kyc/upload-id'], {
              queryParams: { type: selectedIdType[this.user.id_type] },
            })
          } else {
            this.subscriptions.push(
              this.wizardDataService.getIdType().subscribe(res => {
                this.router.navigate(['admin/profile/kyc/upload-id'], {
                  queryParams: { type: selectedIdType[res.result.id_type] },
                })
              })
            )
          }
        } else {
          this.router.navigateByUrl('admin/profile/kyc/upload-selfie')
        }
      }
    }
  }

  /**
   * find any old images
   * @param wizard
   */
  findOld(wizard: string) {
    if (wizard === 'type-address') {
      const images: any = this.user.files.filter(
        (files: any) => files.type === 0
      )

      if (images[0].status === 3) {
        this.wizardFlowService.invalidateStep(STEPS.upload)

        if (this.user.address_type) {
          return this.router.navigate(['admin/profile/kyc/upload-address'], {
            queryParams: { type: selectedAddressType[this.user.address_type] },
          })
        } else {
          this.subscriptions.push(
            this.wizardDataService.getAddressType().subscribe(res => {
              return this.router.navigate(
                ['admin/profile/kyc/upload-address'],
                {
                  queryParams: {
                    type: selectedAddressType[res.result.address_type],
                  },
                }
              )
            })
          )
        }
      }
    }

    if (wizard === 'type-id') {
      const files1 = this.user.files.filter((file: any) => file.type === 1)
      const files2 = this.user.files.filter((file: any) => file.type === 2)
      const files3 = this.user.files.filter((file: any) => file.type === 3)
      const allFiles = []

      if (files1.length > 0) {
        allFiles.push(files1[files1.length - 1])
      }

      if (files2.length > 0) {
        allFiles.push(files2[files2.length - 1])
      }

      if (files3.length > 0) {
        allFiles.push(files3[files3.length - 1])
      }

      const files = allFiles.filter((item: any) => item.status === 3)

      if (files.length > 0) {
        files.forEach(file => {
          if (file.type === 1 || file.type === 2) {
            this.wizardFlowService.invalidateStep(STEPS.uploadId)
          }

          if (file.type === 3) {
            this.wizardFlowService.invalidateStep(STEPS.uploadSelfie)
          }
        })
      }

      if (files.length > 0) {
        if (files[0].type === 1 || files[0].type === 2) {
          if (this.user.id_type) {
            this.router.navigate(['admin/profile/kyc/upload-id'], {
              queryParams: { type: selectedIdType[this.user.id_type] },
            })
          } else {
            this.subscriptions.push(
              this.wizardDataService.getIdType().subscribe(res => {
                this.router.navigate(['admin/profile/kyc/upload-id'], {
                  queryParams: { type: selectedIdType[res.result.id_type] },
                })
              })
            )
          }
        } else {
          this.router.navigateByUrl('admin/profile/kyc/upload-selfie')
        }
      }
    }
  }

  /**
   * programatically change page
   * @param {string} page
   */
  public goBuyCoin() {
    if (this.user.compliance_status === 3)
      this.router.navigateByUrl('admin/buy-coins')
  }

  /**
   * programatically change page
   * @param {string} page
   */
  public whitelist(page: string) {
    if (this.wizardComplete) {
      this.router.navigateByUrl('admin/' + page + '/public')
    } else {
      return
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    })
  }

  /**
   * get the current users KYC status
   * @param user
   */
  getKycProgress(user: IUser) {
    this.userStatus.next(user.compliance_status)

    this.getAddressStatus()
    this.IdStatus()
    this.whitelistStatus(user)
    this.privateSaleStatus(user)
  }

  /**
   * get address verification status
   */
  getAddressStatus() {
    this.subscriptions.push(
      this.wizardDataService.getAddressStatus().subscribe(res => {
        this.aStatus.next(res)
      })
    )
  }

  /**
   * get ID verification status
   */
  IdStatus() {
    this.subscriptions.push(
      this.wizardDataService.getIdStatus().subscribe(res => {
        this.idStatus.next(res)
      })
    )
  }

  /**
   * get the whitelist status
   * @param user
   */
  whitelistStatus(user: IUser) {
    if (user.ico_status === 1) {
      this.wStatus.next(user.ico_status)
    }
  }

  /**
   * get the private sale status
   * @param user
   */
  privateSaleStatus(user: IUser) {
    if (user.ico_status_private > 0 && user.ico_status_private <= 4) {
      this.psStatus.next(user.ico_status_private)
    }
  }
}
