import { CookieService } from 'ngx-cookie-service'

import { CommonModule } from '@angular/common'
// 3rd party modules
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { TranslateModule } from '@ngx-translate/core'

// Components
import { LanguageSelectorComponent } from './language-selector.component'

@NgModule({
  imports: [CommonModule, MatButtonModule, MatMenuModule, TranslateModule],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
  providers: [CookieService],
})
export class LanguageSelectorModule {}
