import { Component, OnInit } from '@angular/core'

// import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// import { Store } from '@ngrx/store';
//
// import * as fromRoot from '../../reducers/index';
// import {
//   SelectLayoutAction,
//   SetCardElevationAction
// } from '../layout/shared/layout.action';
// import * as sidenavAction from '../sidenav/shared/sidenav.action';
// import { SidenavItem } from '../sidenav/sidenav-item/sidenav-item.model';

@Component({
  selector: 'vr-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss'],
})
export class RouteHandlerComponent implements OnInit {
  constructor() // private store: Store<fromRoot.State>,
  // private router: Router,
  // private route: ActivatedRoute
  {}

  ngOnInit() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.store.dispatch(
    //       new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects)
    //     );
    //   }
    // });
    //
    // this.store.dispatch(new SelectLayoutAction('beta'));
    //
    // this.route.queryParamMap.subscribe(params => {
    //   const layout = params.get('layout');
    //
    //   switch (layout) {
    //     case 'alpha': {
    //       this.store.dispatch(new SelectLayoutAction('alpha'));
    //       break;
    //     }
    //
    //     case 'beta': {
    //       this.store.dispatch(new SelectLayoutAction('beta'));
    //       break;
    //     }
    //
    //     case 'gamma': {
    //       this.store.dispatch(new SelectLayoutAction('gamma'));
    //       break;
    //     }
    //   }
    //
    //   const elevation = params.get('elevation');
    //
    //   if (elevation) {
    //     this.store.dispatch(
    //       new SetCardElevationAction('card-elevation-z' + elevation)
    //     );
    //   }
    // });
    //
    // // Define Menu Items here
    //
    // const admin = new SidenavItem({
    //   name: 'Admin',
    //   icon: 'layers',
    //   route: '/admin',
    //   subItems: [],
    //   position: 1,
    // });
    //
    // const adminSubItems = [
    //   new SidenavItem({
    //     name: 'Buy GGC Token',
    //     route: '/',
    //     parent: admin,
    //     subItems: [],
    //     position: 1,
    //     routerLinkActiveOptions: {
    //       exact: true,
    //     },
    //   }),
    //   new SidenavItem({
    //     name: 'Earn Tokens',
    //     route: '/admin/earn-tokens',
    //     parent: admin,
    //     subItems: [],
    //     position: 1,
    //   }),
    //   new SidenavItem({
    //     name: 'Token Balance',
    //     route: '/admin/token-balance',
    //     parent: admin,
    //     subItems: [],
    //     position: 1,
    //   }),
    //   new SidenavItem({
    //     name: 'Transactions',
    //     route: '/admin/transactions',
    //     parent: admin,
    //     subItems: [],
    //     position: 1,
    //   }),
    // ];
    // admin.subItems.push(...adminSubItems);
    //
    // const auth = new SidenavItem({
    //   name: 'Auth',
    //   icon: 'person_pin',
    //   route: null,
    //   subItems: [],
    //   position: 1,
    // });
    //
    // const authSubItems = [
    //   new SidenavItem({
    //     name: 'Login',
    //     route: '/auth/login',
    //     parent: auth,
    //     subItems: [],
    //     position: 1,
    //   }),
    //   new SidenavItem({
    //     name: 'Register',
    //     route: '/auth/register',
    //     parent: auth,
    //     subItems: [],
    //     position: 1,
    //   }),
    //   new SidenavItem({
    //     name: 'Forgot Password',
    //     route: '/auth/forgot-password',
    //     parent: auth,
    //     subItems: [],
    //     position: 1,
    //   }),
    // ];
    //
    // auth.subItems.push(...authSubItems);
    //
    // // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
    // this.store.dispatch(new sidenavAction.AddSidenavItemAction(admin));
    // this.store.dispatch(new sidenavAction.AddSidenavItemAction(auth));
  }
}
