import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material'
import { Router } from '@angular/router'

// import { WizardDataService } from '@app/admin/services/wizard-data.service'

@Component({
  selector: 'vr-verify-identity-approval',
  templateUrl: './verify-identity-approval.component.html',
  styleUrls: ['./verify-identity-approval.component.scss'],
})
export class VerifyIdentityApprovalComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<VerifyIdentityApprovalComponent> // private wizardDataService: WizardDataService
  ) {}

  ngOnInit() {}

  goTo(url: string) {
    // this.wizardDataService.wizardCompleted().subscribe(res => {
    this.dialogRef.close()
    // this.wizardFlowService.closeWizard()
    this.router.navigateByUrl('admin/profile')
    // })
  }
}
