// 3rd party modules
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'

// Components
import { PaymentCardWidgetComponent } from './payment-card-widget.component'

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatIconModule, TranslateModule],
  declarations: [PaymentCardWidgetComponent],
  exports: [PaymentCardWidgetComponent],
})
export class PaymentCardWidgetModule {}
