import { Subscription } from 'rxjs'

import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Router } from '@angular/router'
import { IUser } from '@app/admin/store/session.store'
// import { GgcApiService } from '@app/admin/services'
import { BackendService } from '@app/shared/services/backend.service'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'

// import { ContractSigningDownloadService } from '../contract-signing-download.service'
import { ContractSigningService } from '../contract-signing.service'

@Component({
  selector: 'vr-dialog-contract',
  templateUrl: './dialog-contract.component.html',
  styleUrls: ['./dialog-contract.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogContractComponent implements OnInit, OnDestroy {
  @ViewChild('pdf') pdfRef
  @ViewChild('contentToConvert') content

  subscriptions: Subscription[] = []

  public user: IUser
  public investment

  public checkedTerms
  public checkedAcknowledge
  public checkedAgreements
  public checkedPrivacyAgreement

  public type: string

  file

  saving: boolean = false

  constructor(
    public dialogRef: MatDialogRef<DialogContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contractSigningService: ContractSigningService,
    private router: Router,
    private backend: BackendService,
    private ggcoinapiService: GgcoinapiService // private contractSigningDownloadService: ContractSigningDownloadService
  ) {}

  ngOnInit() {
    this.type === 'public'
      ? this.data.user.subscribe((res: any) => (this.user = res.user))
      : (this.user = this.data.user)

    this.investment = this.data.investmentAmount
    this.type = this.data.type
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  agree() {
    this.saving = true
    this.data.agreed = 1

    this.subscriptions.push(
      this.contractSigningService
        .sendData(this.user.id, this.data, this.type)
        .subscribe(res => {
          this.saving = false
          if (this.type === 'public') {
            this.ggcoinapiService
              .post('/rest/setIcoStatus', {
                status: 1,
                type: this.type,
              })
              .subscribe()
          }

          window.open(res.result.download_url, '_blank')
        })
    )
  }

  getContract(fileId: number) {
    this.dialogRef.close(this.data.agreed)
    // TODO: Fix the donwload of contract

    // this.contractSigningDownloadService
    //   .get('/contract/download/' + fileId)
    //   .subscribe(
    //     res => {
    //       this.dialogRef.close()
    //     },
    //     err => {
    //       throw err
    //     }
    //   )
  }

  cancel() {
    this.dialogRef.close(this.data.agreed)
  }
}
