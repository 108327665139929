import { Routes } from '@angular/router'

import { ContractSigningComponent } from './contract-signing.component'

export const ContractSigningRoutes: Routes = [
  {
    path: '',
    component: ContractSigningComponent,
  },
]
