import { Observable, Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { StepQuery } from '@app/admin/store/step.query'

// import { WizardDataService } from '@app/admin/services/wizard-data.service';

// import { StepStore } from '@app/admin/store/step.store'

@Component({
  selector: 'vr-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []
  allSteps: Observable<any>
  steps: any
  valid: number = 0

  constructor(
    private stepQuery: StepQuery,
    // private stepStore: StepStore,
    // private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService
  ) {}

  ngOnInit() {
    this.allSteps = this.stepQuery.selectAll()

    this.subscriptions.push(
      this.allSteps.subscribe(
        res => {
          this.steps = res
          this.getValid(this.steps)
        },
        err => {
          throw err
        }
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  getValid(steps: any) {
    let count = 0
    steps.forEach(step => {
      if (step.valid) {
        count += 1
      }
    })
    const amount = (count / 7) * 100
    this.valid = 100 - amount
  }
}
