import 'hammerjs'

import { ToasterModule, ToasterService } from 'angular2-toaster'
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent'

import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools'
import { environment } from '@env/environment'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { AdminModule } from './admin/admin.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthPageModule } from './auth-page/auth-page.module'
import { CookiePolicyModule } from './cookie-policy/cookie-policy.module'
import { CoreModule } from './core/core.module'
import { RouteHandlerModule } from './core/route-handler/route-handler.module'
import { reducers } from './reducers/index'
import { ApiService } from './shared/services/api.service'
import { BackendService } from './shared/services/backend.service'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'edgeless',
  position: 'bottom',
  content: {
    href: '//' + environment.domain + '/cookie-policy',
  },
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : [],
    EffectsModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    CoreModule,
    AuthPageModule,
    AdminModule,
    RouteHandlerModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    CookiePolicyModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    ToasterModule.forRoot(),
    // NgForageModule.forRoot({ name: 'GGCICO' }),
  ],
  providers: [BackendService, ApiService],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
