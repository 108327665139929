import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
// import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router'

@Injectable()
export class StatusInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  /**
   * interceptor to check response token status
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const that = this
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.status === 403) {
              this.router.navigateByUrl('/logout')
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 403 || err.status === 401) {
              // console.log('error logging in');
              this.router.navigateByUrl('/auth/login')
              // this.router.navigateByUrl('/logout');
            }
          }
        }
      )
    )
  }
}
