import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
// Services
import { BackendService } from '@app/shared/services/backend.service'
import { TransactionsService } from '@app/shared/services/transactions.service'

import { ROUTE_TRANSITION } from '../../../app.animation'

@Component({
  selector: 'vr-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class TransactionsComponent implements OnInit {
  displayedColumns = [
    'Coins',
    'Payment Currency',
    'Payment Ammount',
    'Ammount Paid',
    'Status',
  ]
  transactions: any
  balance: number

  constructor(
    private cd: ChangeDetectorRef,
    private backend: BackendService,
    private ta: TransactionsService
  ) {}

  ngOnInit() {
    this.getBalance()

    this.ta.safeGet().subscribe(function(data: any) {
      this.transactions = data
    })
  }

  getBalance() {
    this.backend.getBalance().subscribe(
      (data: any) => {
        if (data) {
          this.balance = data.balance
          this.cd.markForCheck()
        }
      },
      error => {
        console.warn('error', 'Failed to get Balance: ', error)
      }
    )
  }
}
