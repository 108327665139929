import { Component, OnInit } from '@angular/core'

import { AUTH_TRANSITION } from '../../app.animation'

@Component({
  selector: 'vr-auth2fa',
  templateUrl: './auth2fa.component.html',
  styleUrls: ['./auth2fa.component.scss'],
  animations: [...AUTH_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class Auth2faComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
