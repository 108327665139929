import { Injectable } from '@angular/core'
import {
  EntityState,
  EntityStore,
  ID,
  Store,
  StoreConfig,
} from '@datorama/akita'

export interface IStep {
  id: ID
  step: string
  valid: boolean
  path: string
  active: boolean
}

// export function createStep({
//   id = null,
//   step = '',
//   valid = false,
//   path = '',
// }): Partial<Step> {
//   return {
//     id,
//     step,
//     valid,
//     path,
//   };
// }

export interface IStepState extends EntityState<IStep> {}

@Injectable()
@StoreConfig({ name: 'steps' })
export class StepStore extends EntityStore<IStepState, IStep> {
  constructor() {
    super()
  }
}
