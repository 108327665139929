import { Injectable } from '@angular/core'
import { ID, Store, StoreConfig } from '@datorama/akita'

export interface ITransaction {
  current_transaction_id: string
  current_transaction: object
}

export interface ITransactionState {
  transaction: ITransaction | null
}

export function createInitialState(): ITransactionState {
  return {
    transaction: null,
  }
}

export function createSession(transaction: ITransaction) {
  return { ...transaction }
}

@Injectable()
@StoreConfig({ name: 'transaction', resettable: true })
export class TransactionStore extends Store<ITransactionState> {
  constructor() {
    super(createInitialState())
  }

  login(data: ITransaction) {
    const transaction = createSession(data)
    this.update({ transaction })
  }

  logout() {
    this.update(createInitialState())
  }
}
