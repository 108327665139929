import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SessionStore } from '@app/admin/store/session.store'
import { StepStore } from '@app/admin/store/step.store'

import { AuthService } from '../../../core/auth/services/auth.service'

@Component({
  selector: 'vr-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss'],
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {
  @Input()
  username: string

  isOpen: boolean

  constructor(
    private auth: AuthService,
    private router: Router,
    private stepStore: StepStore,
    private sessionStore: SessionStore
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  toggleDropdown() {
    this.isOpen = !this.isOpen
  }

  onClickOutside() {
    this.isOpen = false
  }

  logout() {
    this.auth.logout()
    this.auth.removeRememberToken()
    this.stepStore.remove()
    this.sessionStore.logout()
    this.router.navigateByUrl('/auth/login')
  }

  goToSite() {
    window.location.href = 'https://ggcoin.io'
  }
}
