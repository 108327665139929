import { forkJoin, Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { HttpClient } from '@angular/common/http'
import { EventEmitter, Injectable } from '@angular/core'
import { Response } from '@angular/http'
import { Router } from '@angular/router'
import { IUser, SessionStore } from '@app/admin/store/session.store'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { RegUserClient, UserIdentify } from '@app/shared/models'
import { Rate, RateGroups, Symbol } from '@app/shared/models/currency-rates'
import { ExchangeRatesService } from '@app/shared/services/exchange-rates.service'
import { StateService } from '@app/shared/services/state.service'

// import { environment } from '@env/environment';
import { ApiService } from './api.service'
import { CoinApiService } from './coin-api.service'
import { GgcoinapiService } from './ggcoinapi.service'
import { JwtService } from './jwt.service'
import { WizardDataService } from './wizard-data.service'

@Injectable()
export class BackendService extends ApiService {
  protected update_event: EventEmitter<any> = new EventEmitter<any>()

  protected user: any = null
  protected settings: any = null
  protected balance: number = -1

  protected input_currency: string = 'USD'

  constructor(
    protected http: HttpClient,
    protected jwtService: JwtService,
    protected storage: LocalStorageService,
    protected router: Router,
    protected xrates: ExchangeRatesService,
    protected state: StateService,
    protected apiService: ApiService,
    private sessionStore: SessionStore,
    private ggcoinapiService: GgcoinapiService,
    private coinapiService: CoinApiService,
    private wizardDataService: WizardDataService
  ) {
    super(http, jwtService, storage, router)
  }

  getUpdateEventEmitter(): EventEmitter<any> {
    return this.update_event
  }

  loadData() {
    /* return Observable.create(observer => {
      this.getState().subscribe((state: any) => {
        this.getBalance().subscribe((balance: any) => {
          const syms = Array<Symbol>()

          syms.push({ cur1: 'EUR', cur2: 'BTC' })
          syms.push({ cur1: 'CHF', cur2: 'EUR' })
          syms.push({ cur1: 'ETH', cur2: 'BTC' })
          syms.push({ cur1: 'LTC', cur2: 'BTC' })
          syms.push({ cur1: 'BCH', cur2: 'BTC' })
          syms.push({ cur1: 'DASH', cur2: 'BTC' })

          this.xrates.getRates(syms).subscribe(data => {
            const p = 0.1 * (state.active_stage.discount / 100)
            this.state.set('token_price', p)

            observer.next(true)
            observer.complete()
          })
        })
      })
    })*/
  }

  getInputCurrency(): string {
    return this.input_currency
  }

  setInputCurrency(cur: string) {
    this.input_currency = cur

    this.update_event.emit({
      parameter: 'input_currency',
      value: this.input_currency,
      balance: this.balance,
    })
  }

  isAuthenticated(): boolean {
    if (this.user != null) {
      return this.user.authenticated
    }

    return false
  }

  setAuthenticated() {
    this.user.authenticated = true
  }

  get2fa(): string {
    if (this.user != null) {
      return this.user._2fa
    }

    return 'DISABLED'
  }

  verify2fa(email: string, code: string): Observable<Response> {
    // console.log('audit', code)
    return this.ggcoinapiService.post('/rest/user/verify2fa', { email, code })
  }

  test2fa(email: string, code: string): Observable<Response> {
    return this.postKycApi('/user/verify2fa', { email, code })
  }

  registerUser(profile: RegUserClient): Observable<Response> {
    return this.coinapiService.post('/rest/user/register', profile)
  }

  setReferrer(refcode): Observable<Response> {
    return this.coinapiService.post('/rest/user/setreferer', {
      referrer: refcode,
    })
  }

  /*getUser(): Observable<Response> {
    const that = this
    return Observable.create(observer => {
      const unix = Math.round(+new Date() / 1000)

      if (this.user != null && this.user.updated + 3600 > unix) {
        observer.next(that.user)
        observer.complete()
      } else {
        this.coinapiService.get('/rest/user/get').subscribe(
          function(user: any) {
            if (user.status === 200) {
              user.updated = unix

              that.user = user
              if (user.user.country !== 'CH') {
                that.input_currency = 'EUR'
                that.update_event.emit({
                  parameter: 'input_currency',
                  value: 'EUR',
                  balance: this.balance,
                })
              } else {
                that.input_currency = 'CHF'
                that.update_event.emit({
                  parameter: 'input_currency',
                  value: 'CHF',
                  balance: this.balance,
                })
              }

              observer.next(user)
              observer.complete()
            } else {
              that.user = null

              that.jwtService.destroyToken()
              that.router.navigateByUrl('/auth/login')

              observer.next(false)
              observer.complete()
            }
          },
          error => {
            console.log('auth error', error)
          }
        )
      }
    })
  }*/

  /**
   * Register new user
   * @param profile
   */
  registerKycUser(profile: RegUserClient): Observable<Response> {
    // return this.apiService.postKycApi('/register', profile)
    return this.ggcoinapiService.post('/rest/register', profile)
  }

  /**
   * Get current user
   */
  getUser(): Observable<Response> {
    return Observable.create(observer => {
      const unix = Math.round(+new Date() / 1000)

      if (
        this.user != null &&
        this.user.result &&
        this.user.result.updated_at + 3600 > unix
      ) {
        observer.next(this.user)
        observer.complete()
      } else {
        const currentUser = this.ggcoinapiService.get('/currentUser')
        const icoStatus = this.ggcoinapiService.get('/rest/getIcoStatus')
        const userVerified = this.ggcoinapiService.get(
          '/rest/getUserVerification'
        )

        /*const wizardComplete = this.ggcoinapiService.get(
          '/rest/getWizardStatus'
        )*/

        forkJoin([
          currentUser,
          icoStatus,
          userVerified,
          //wizardComplete,
        ]).subscribe(
          results => {
            const user = {
              ...results[0].result,
              ...results[1].result,
              ...results[2].result,
              //...results[3].result,
            }

            user.updated_at = unix

            this.user = user
            this.sessionStore.login(user)

            if (!this.wizardDataService.currentUser.getValue()) {
              this.wizardDataService.currentUser.next(this.user)
            }

            this.jwtService.saveKycToken(this.user.kyc_token)

            const full = {
              result: user,
              status: 200,
            }

            observer.next(full)
            observer.complete()

            if (!this.wizardDataService.currentUser.getValue().addresses)
              this.wizardDataService.getPersonalFormData()
          },
          err => {
            console.warn(err)
            this.user = null

            this.jwtService.destroyToken()
            this.router.navigateByUrl('/auth/login')

            observer.next(false)
            observer.complete()
          }
        )
      }
    })
  }

  getAccount(): Observable<Response> {
    return this.coinapiService.get('/rest/token/account')
  }

  getState(): Observable<Response> {
    return this.coinapiService.get('/rest/ico/state')
  }

  sendTransaction(address, amount, metadata): Observable<Response> {
    return this.coinapiService.post('/rest/token/send', {
      dest_addr: address,
      amount,
      metadata,
    })
  }

  buyCoin(amount, currency): Observable<Response> {
    this.balance = -1
    this.getBalance()

    // console.log('buy coin', amount, currency)

    // TODO: This returns error 403 failed, find out why
    return this.coinapiService.post('/rest/token/buy', {
      amount,
      cur: currency,
    })
  }

  getTransactions(): Observable<Response> {
    return this.coinapiService.get('/rest/transactions/history')
  }

  getTransaction(id: number): Observable<Response> {
    return this.coinapiService.get('/rest/transactions/get/' + id)
  }

  getBalance(): Observable<Response> {
    return Observable.create(observer => {
      if (this.balance !== -1) {
        this.update_event.emit({
          parameter: 'input_currency',
          value: this.input_currency,
          balance: this.balance,
        })

        this.update_event.emit({
          parameter: 'balance',
          value: this.balance,
        })

        observer.next({
          balance: this.balance,
        })
        observer.complete()
      } else {
        this.coinapiService
          .get('/rest/transactions/balance')
          .subscribe((data: any) => {
            if (data) {
              this.balance = data.balance

              this.update_event.emit({
                parameter: 'input_currency',
                value: this.input_currency,
                balance: data.balance,
              })

              this.update_event.emit({
                parameter: 'balance',
                value: data.balance,
              })

              observer.next(data)
              observer.complete()
            }
          })
      }
    })
  }

  getSettings(userId: any): Observable<Response> {
    // const that = this
    return Observable.create(observer => {
      if (this.settings != null) {
        observer.next(this.settings)
        observer.complete()
      } else {
        // this.getCoinApi('/rest/user/settings/get').subscribe(
        //   (settings: any) => {
        //     this.settings = settings
        //     observer.next(this.settings)
        //     observer.complete()
        //   }
        // )

        this.apiService
          .getKycApi('/getPersonal2fa?user_id=' + userId)
          .subscribe((settings: any) => {
            this.settings = settings
            observer.next(this.settings)
            observer.complete()
          })
      }
    })
  }

  setSettings(userId: any, settings: any): Observable<Response> {
    this.settings = settings
    return this.postKycApi('/definePersonal2fa', {
      user_id: userId,
      settings,
    })
    // return this.postCoinApi('/rest/user/settings/set', settings)
  }

  sendAuthEmail(): Observable<Response> {
    return this.coinapiService.get('/rest/user/authemail/send')
  }

  reset() {
    this.user = null
    this.settings = null
    this.balance = -1
  }

  requestForgotPassword(email: string): Observable<Response> {
    return this.ggcoinapiService.post('/rest/forgotPassword', { email })
  }

  resetPassword(token: string, password: string): Observable<Response> {
    return this.ggcoinapiService.post('/rest/resetPassword', {
      token,
      password,
    })
  }

  updatePassword(
    current_password: string,
    password: string
  ): Observable<Response> {
    return this.coinapiService.post('/rest/user/updatepassword', {
      current_password,
      password,
    })
  }

  /**
   * update user password
   * @param password
   * @param new_password
   */
  changePassword(password: string, new_password: string): Observable<Response> {
    return this.putKycApi('/changePassword', {
      password,
      new_password,
    })
  }

  /**
   * update email address
   * @param email
   */
  changeEmail(email: string): Observable<Response> {
    return this.putKycApi('/changeEmail', {
      email,
    })
  }

  updateMasterPassword(password: string): Observable<Response> {
    return this.coinapiService.post('/rest/user/updatemasterpassword', {
      password,
    })
  }

  getReferrals(): Observable<Response> {
    return this.ggcoinapiService.get('/referrals')
  }

  validateEmail(token: string): Observable<Response> {
    return this.ggcoinapiService.get('/rest/register/verify/' + token)
  }

  /*setCountry(country: String): Observable<Response> {
    return this.ggcoinapiService.post('/rest/user/setcountry', {
      country,
    })
  }*/

  hideTransaction(txn_id: string) {
    return this.coinapiService.post('/rest/transactions/hide', {
      txn_id,
    })
  }

  disable2fa() {
    this.user._2fa = 'DISABLED'
  }

  /**
   * get user's address
   */
  getUserAddress() {
    return this.getKycApi('/address')
  }

  /**
   * update user's address
   * @param user_id
   * @param country
   * @param address1
   * @param address2
   * @param postal_code
   * @param city
   */
  updateUserAddress(
    user_id: string,
    country: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    postal_code: string
  ) {
    return this.postKycApi('/address', {
      user_id,
      country,
      address1,
      address2,
      city,
      state,
      postal_code,
    })
  }

  sendSupportMessage(subject: string, message: string): Observable<Response> {
    return this.ggcoinapiService.post('/rest/user/supportform', {
      subject,
      message,
    })
  }

  registerStripePayment(txn_id, token_id, value): Observable<Response> {
    return this.coinapiService.post('/rest/payment/stripe', {
      txn_id,
      token: token_id,
      value,
    })
  }

  resendValidationEmail(email): Observable<Response> {
    return this.ggcoinapiService.post('/rest/resendEmailValidation', { email })
  }

  getRates(): Observable<any> {
    return this.ggcoinapiService.get('/rates')
  }

  getKycToken(): Observable<any> {
    return this.ggcoinapiService.get('/getKycToken')
  }
}
