import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'

import { WizardDataService } from '../../shared/services/wizard-data.service'

@Injectable()
export class WorkflowGuard implements CanActivate {
  constructor(
    private router: Router,
    private wizardDataService: WizardDataService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const path: string = route.routeConfig.path
    return this.verifyStepValid(path)
  }

  verifyStepValid(path): boolean {
    let valid = true
    let user

    if (
      path === 'type-id' ||
      path === 'upload-id' ||
      path === 'upload-selfie'
    ) {
      this.wizardDataService.getIdStatus().subscribe(
        res => {
          if (res !== null && res !== 3 && res !== 2) {
            this.router.navigateByUrl('/admin/profile')
            return (valid = false)
          }
        },
        err => {
          return (valid = true)
        }
      )
    }

    if (path === 'type-address' || path === 'upload-address') {
      this.wizardDataService.getAddressStatus().subscribe(
        res => {
          if (res !== null && res !== 3 && res !== 2) {
            this.router.navigateByUrl('/admin/profile')
            return (valid = false)
          }
        },
        err => {
          return (valid = true)
        }
      )
    }

    return valid
  }
}
