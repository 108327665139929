export interface ReferralsInterface {
  id: number
  user_type: string
  user_id: number
  refcode: string
  created: number
  tokens: number
}

export class Referrals {
  private referrals: ReferralsInterface[] = []

  protected sort(): void {
    this.referrals.sort(function(a, b) {
      return b.created - a.created
    })
  }

  public exists(id): boolean {
    this.referrals.forEach(function(t) {
      if (t.id === id) {
        return true
      }
    })
    return false
  }

  public insert(transaction: ReferralsInterface): void {
    if (!this.exists(transaction.id)) {
      this.referrals.push(transaction)
      this.sort()
    }
  }

  public insertList(referrals: ReferralsInterface[]): void {
    const that = this
    referrals.forEach(function(t) {
      if (!that.exists(t.id)) {
        that.referrals.push(t)
      }
    })
    this.sort()
  }

  public get(): ReferralsInterface[] {
    return this.referrals
  }

  public clear() {
    this.referrals = []
  }

  public length() {
    return this.referrals.length
  }
}
