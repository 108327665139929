import { Subscription } from 'rxjs'

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { BackendService } from '@app/shared/services/backend.service'
import { ExchangeRatesService } from '@app/shared/services/exchange-rates.service'
import { StateService } from '@app/shared/services/state.service'

@Component({
  selector: 'vr-toolbar-beta',
  templateUrl: './toolbar-beta.component.html',
  styleUrls: ['./toolbar-beta.component.scss'],
})
export class ToolbarBetaComponent implements OnInit, OnDestroy {
  @Input()
  quickpanelOpen: boolean
  user: IUser = null
  username: string = ''
  email: string = ''
  currency: string = this.backend.getInputCurrency()
  token_value: number = 0
  token_price: number = 0
  balance: number = 0
  subscriptions: Subscription[] = []
  // wizardEnabled: boolean = false

  @Output()
  toggledSidenav = new EventEmitter()
  @Output()
  toggledQuickpanel = new EventEmitter()

  constructor(
    private cd: ChangeDetectorRef,
    private backend: BackendService,
    private state: StateService,
    private xrates: ExchangeRatesService,
    private wizardFlow: WizardFlowService
  ) {}

  ngOnInit() {
    this.getUser()
    this.getBalance()

    // this.backend.getUpdateEventEmitter().subscribe(function(evt: any) {
    //   switch (evt.parameter) {
    //     case 'balance':
    //       that.balance = evt.value;
    //       that.token_value = that.token_price * that.balance;
    //       break;
    //   }
    // });
    this.subscriptions.push(
      this.backend.getUpdateEventEmitter().subscribe(evt => {
        switch (evt.parameter) {
          case 'input_currency':
            this.currency = evt.value
            this.balance = evt.balance
            this.token_price = this.state.get('token_price')
            this.token_value = this.token_price * evt.balance

            if (this.currency === 'CHF') {
              this.token_value = this.xrates.convertEurToChf(this.token_value)
              if (this.token_value < 0) {
                this.token_value = 0
              }
            }

            this.cd.markForCheck()
            break

          case 'balance':
            this.balance = evt.value
            this.token_value = this.token_price * this.balance
            break
        }
      })
      // this.wizardFlow.wizardEnabled$.subscribe(res => {
      //   this.wizardEnabled = res
      // })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  getDateTime(timestamp: number): string {
    const date = new Date(timestamp)
    const dv = [date.getHours(), date.getMinutes(), date.getSeconds()]

    return dv[0] + ':' + dv[1] + ':' + dv[2]
  }

  getUser() {
    this.subscriptions.push(
      this.backend.getUser().subscribe(
        (user: any) => {
          if (user.status === 200) {
            this.user = user
            this.username = user.result.username
          } else {
            this.username = ''
          }
        },
        error => {
          console.warn('auth error', error)
        }
      )
    )
  }

  getBalance() {
    this.subscriptions.push(
      this.backend.getBalance().subscribe((res: any) => {
        this.balance = res.balance
      })
    )
  }
}
