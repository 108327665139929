import { ToasterConfig, ToasterService } from 'angular2-toaster'
import { Observable, Subscription, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { HttpClient, HttpParams } from '@angular/common/http'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { Errors } from '@app/shared/models/errors.model'
import { BackendService } from '@app/shared/services/backend.service'
import { environment } from '@env/environment'

import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'vr-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  regForm: FormGroup
  isSubmitting: boolean
  errors: Errors = new Errors()
  readonly ROLE: number = 11
  subscriptions: Subscription[] = []
  location: any

  env = environment
  captcha: boolean = false

  public config: ToasterConfig = new ToasterConfig({
    animation: 'flyRight',
    tapToDismiss: true,
    timeout: 5000,
    showCloseButton: true,
    preventDuplicates: true,
  })

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toasterService: ToasterService,
    private backend: BackendService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public dialog: MatDialog // private snackbar: MatSnackBar
  ) {
    // const that = this

    this.activatedRoute.queryParamMap.subscribe(params => {
      const refcode = params.get('refcode')

      this.regForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        referrer: [refcode ? refcode : ''],
        captcha: [''],
      })
    })
  }

  get email() {
    return this.regForm.get('email')
  }

  get password() {
    return this.regForm.get('password')
  }

  private formatErrors(error: any) {
    return throwError(error)
  }

  ngOnInit() {
    // this.ipLookUp().subscribe(res => {
    //   console.log(res);
    // });
  }

  register() {
    this.isSubmitting = true
    this.errors = new Errors()

    const credentials = this.regForm.value
    credentials['role'] = this.ROLE

    if (!environment.production) {
      this.captcha = true
    }

    if (this.captcha) {
      this.subscriptions.push(
        this.backend.registerKycUser(credentials).subscribe(
          (data: any) => {
            if (data.status === 200) {
              let message
              data.message ? (message = data.message) : (message = '')

              this.toasterService.pop(
                'success',
                'VERIFICATION EMAIL SENT',
                'Please check your inbox and confirm your email address to activate your account ' +
                  message
              )

              this.router.navigateByUrl('/auth/login')
            } else if (data.status !== 200) {
              this.openDialog(data.result)
              this.isSubmitting = false
              this.regForm.reset()
            }
          },
          (error: any) => {
            console.warn(error)
            this.openDialog(error.error.result)
            this.isSubmitting = false
          }
        )
      )
    } else {
      this.openDialog('This captcha is not valid')
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && subscription) {
        subscription.unsubscribe()
      }
    })
  }

  openDialog(msg: string): void {
    const dialogRef = this.dialog.open(RegisterErrorComponent, {
      width: '300px',
      data: { message: msg },
    })
  }

  resolved(evt) {
    this.regForm.controls.captcha.setValue(evt)
    this.captcha = evt != null
  }

  ipLookUp(): Observable<any> {
    return this.http
      .get('https://geoip-db.com/json/5e82b220-1e3e-11e9-8db0-ad980ee79534')
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }
}

@Component({
  selector: 'vr-registration-dialog',
  templateUrl: './register-failed.component.html',
  styles: [
    '.color-accent { color: #FFC62D } .color-accent::first-letter { text-transform: uppercase }',
  ],
})
export class RegisterErrorComponent {
  message: string = ''

  constructor(
    public dialogRef: MatDialogRef<RegisterErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message
  }
}
