// 3rd party modules
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatIconModule } from '@angular/material/icon'
import { InlineSVGModule } from 'ng-inline-svg'
import { TranslateModule } from '@ngx-translate/core'

// Components
import { PaymentBankWidgetComponent } from './payment-bank-widget.component'

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    InlineSVGModule,
    TranslateModule,
  ],
  declarations: [PaymentBankWidgetComponent],
  exports: [PaymentBankWidgetComponent],
})
export class PaymentBankWidgetModule {}
