import { CommonModule } from '@angular/common'
// 3rd party modules
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule } from '@angular/forms'
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSnackBarModule,
} from '@angular/material'
import { RouterModule } from '@angular/router'
import { AuthModule } from '@app/core/auth'
import { LanguageSelectorModule } from '@app/core/language-selector/language-selector.module'
import { TranslateModule } from '@ngx-translate/core'

// Components
import { LoginPageComponent } from './login-page.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    AuthModule,
    TranslateModule,
    LanguageSelectorModule,
  ],
  declarations: [LoginPageComponent],
})
export class LoginPageModule {}
