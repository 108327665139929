import { filter, map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Query, toBoolean } from '@datorama/akita'

import { ITransactionState, TransactionStore } from './transaction.store'

@Injectable()
export class TransactionQuery extends Query<ITransactionState> {
  constructor(protected store: TransactionStore) {
    super(store)
  }

  transaction() {
    return !!this.getValue().transaction
  }
}
