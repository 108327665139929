import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { AUTH_TRANSITION } from '../../app.animation'

@Component({
  selector: 'vr-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
  animations: [...AUTH_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class RegisterPageComponent implements OnInit {
  name: string
  email: string
  password: string
  passwordConfirm: string

  constructor(private router: Router) {}

  ngOnInit() {}

  register() {
    this.router.navigate(['/'])
  }
}
