import { Injectable } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { PasswordMatcher } from '../validators/password.validator'
import { CaptchaValidator } from '../validators/captcha.validator'

@Injectable()
export class ValidatorService {
  // Regex
  public EMAIL_REGEX: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  public PASSWORD_REGEX = /([A-Za-z0-9])\w{5,12}/
  public PHONE_REGEX: RegExp = /^(0|\(0\))?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/

  // Custom validators object
  public validators: Validators = new Validators()

  constructor() {}

  getValidators(): Validators {
    return Validators
  }
  // Required
  RequiredFormControl(): FormControl {
    return new FormControl('', [Validators.required])
  }

  // Email
  EmailFormControl(): FormControl {
    return new FormControl('', [
      Validators.required,
      Validators.pattern(this.EMAIL_REGEX),
    ])
  }

  // Phone
  PhoneFormControl(): FormControl {
    return new FormControl('', [
      Validators.required,
      Validators.pattern(this.PHONE_REGEX),
    ])
  }

  // Password
  PasswordFormControl(): FormControl {
    return new FormControl('', [
      Validators.required,
      Validators.pattern(this.PASSWORD_REGEX),
    ])
  }

  PasswordConfirmFormControl(): FormControl {
    return new FormControl('', [
      PasswordMatcher,
      Validators.required,
      Validators.pattern(this.PASSWORD_REGEX),
    ])
  }

  // Captcha
  CaptchaFormControl(): FormControl {
    return new FormControl('', [CaptchaValidator, Validators.required])
  }

  CustomControl(validators: Array<any>): FormControl {
    const tmpArray = Array()

    for (let i = 0; i < validators.length; i++) {
      const cv = validators[i]
      if (cv.indexOf('(') > -1) {
        const method = cv.split('(')[0]
        let argument = cv.split('(')[1].replace(')', '')

        if (parseInt(argument) !== NaN) {
          argument = parseInt(argument)
        }

        tmpArray.push(Validators[method].apply(null, [argument]))
      } else {
        tmpArray.push(Validators[validators[i]])
      }
    }

    return new FormControl('', tmpArray)
  }
}
