export interface TransactionsInterface {
  id: number
  type: string
  status: string
  token_transfered: string
  timestamp: number
  expires: number
  ico_stage: number
  txn_id: string
  confirms_needed: number
  confirms: number
  amount: number
  payment_currency: string
  payment_amount: number
  payment_address: string
  token_amount: number
  metadata: string
  expired: false
}

export class Transactions {
  private transactions: TransactionsInterface[] = []

  protected sort(): void {
    this.transactions.sort(function(a, b) {
      return b.timestamp - a.timestamp
    })
  }

  public exists(id): boolean {
    this.transactions.forEach(function(t) {
      if (t.id === id) {
        return true
      }
    })
    return false
  }

  public insert(transaction: TransactionsInterface): void {
    if (!this.exists(transaction.id)) {
      this.transactions.push(transaction)
      this.sort()
    }
  }

  public insertList(transactions: TransactionsInterface[]): void {
    const that = this
    transactions.forEach(function(t) {
      if (!that.exists(t.id)) {
        that.transactions.push(t)
      }
    })
    this.sort()
  }

  public get(): TransactionsInterface[] {
    return this.transactions
  }

  public clear() {
    this.transactions = []
  }

  public remove(txn_id) {
    for (let i = 0; i < this.transactions.length; i++) {
      if (this.transactions[i].txn_id == txn_id) {
        this.transactions.splice(i, 1)
        break
      }
    }
  }
}
