import { Observable } from 'rxjs'

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'

import { JwtService } from '../../../shared/services/jwt.service'

import { environment } from '@env/environment'

export const InterceptorSkipHeader = 'X-Skip-Interceptor'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private jwt: JwtService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader)
      return next.handle(req.clone({ headers }))
    }

    let token = this.jwt.getToken()

    let headers = req.headers

    if (req.url.startsWith(environment.kycUrl)) {
      token = this.jwt.getKycToken()
    }

    headers = req.headers.set('Authorization', 'Bearer ' + token)
    headers = headers.set('X-CSRF-TOKEN', '')

    const authReq = req.clone({ headers: headers })
    return next.handle(authReq)
  }
}
