import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BackendService } from '@app/shared/services/backend.service'

import { AUTH_TRANSITION } from '../../app.animation'

@Component({
  selector: 'vr-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
  animations: [...AUTH_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class ValidateComponent implements OnInit {
  validated: boolean = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private backend: BackendService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const token = params.get('token')

      this.backend.validateEmail(token).subscribe(result => {
        this.validated = true
      })
    })
  }

  enterSite() {
    this.router.navigateByUrl('/auth/login')
  }
}
