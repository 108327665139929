import { ToasterConfig, ToasterService } from 'angular2-toaster'
import { NgcCookieConsentService } from 'ngx-cookieconsent'
import { Subscription } from 'rxjs/Subscription'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

import { MediaReplayService } from './core/utils/media-replay.service'

@Component({
  selector: 'vr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription
  private popupCloseSubscription: Subscription
  private initializeSubscription: Subscription
  private statusChangeSubscription: Subscription
  private revokeChoiceSubscription: Subscription
  private noCookieLawSubscription: Subscription

  public config: ToasterConfig = new ToasterConfig({
    animation: 'flyRight',
    tapToDismiss: true,
    timeout: 5000,
    showCloseButton: true,
    mouseoverTimerStop: true,
    preventDuplicates: true,
  })

  //noinspection JSUnusedLocalSymbols
  constructor(
    private ccService: NgcCookieConsentService,
    mediaReplay: MediaReplayService, // workaround for Flex-Layout to receive the initial value
    private router: Router
  ) {
    // Nothing here.
    // You probably want to go to /core/layout/ :)
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ;(<any>window).ga('set', 'page', event.urlAfterRedirects)
        ;(<any>window).ga('send', 'pageview')
      }
    })
  }

  ngOnInit() {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    })

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    })

    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    //
    //   this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe()
    this.popupCloseSubscription.unsubscribe()
    this.initializeSubscription.unsubscribe()
    this.statusChangeSubscription.unsubscribe()
    this.revokeChoiceSubscription.unsubscribe()
    this.noCookieLawSubscription.unsubscribe()
  }
}
