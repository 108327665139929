import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Response } from '@angular/http'
import { Router } from '@angular/router'
// import { LocalStorage } from '@ngx-pwa/local-storage'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
import { ApiService } from '@app/shared/services/api.service'
import { JwtService } from '@app/shared/services/jwt.service'
import { environment } from '@env/environment'

@Injectable({
  providedIn: 'root',
})
export class ContractSigningDownloadService {
  constructor(
    protected http: HttpClient,
    protected jwtService: JwtService,
    protected storage: LocalStorageService,
    protected router: Router,
    handler: HttpBackend,
    private apiService: ApiService
  ) {
    this.http = new HttpClient(handler)
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/pdf;charset=UTF-8',
      Accept: 'application/pdf',
    }

    if (window.localStorage['appToken']) {
      headersConfig['x-csrf-token'] = window.localStorage['appToken']
    }

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`
    }

    return new HttpHeaders(headersConfig)
  }

  private formatErrors(error: any) {
    return observableThrowError(error)
  }

  /**
   * GET request to the KYC API
   * @param path
   * @param params
   */
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.kycUrl}${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }

  /**
   * POST request to the KYC API
   * @param path
   * @param body
   */
  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.kycUrl}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(
        catchError(this.formatErrors),
        map(res => res)
      )
  }

  downloadContract(fileId: number): Observable<any> {
    return this.apiService
      .getKycApiContract('/contract/download/' + fileId)
      .pipe(map(res => res))
  }
}
