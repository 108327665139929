import { ToasterService } from 'angular2-toaster'
import { Subscription, timer } from 'rxjs'
import { map } from 'rxjs/operators'

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material'
import { MatDialog } from '@angular/material/dialog'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { SessionQuery } from '@app/admin/store/session.query'
import { IUser } from '@app/admin/store/session.store'
import { TransactionStore } from '@app/admin/store/transaction.store'
import { Rate, Symbol } from '@app/shared/models/currency-rates'
import { BackendService } from '@app/shared/services/backend.service'
import { ExchangeRatesService } from '@app/shared/services/exchange-rates.service'
import { StateService } from '@app/shared/services/state.service'
import { TransactionsService } from '@app/shared/services/transactions.service'

import { ROUTE_TRANSITION } from '../../../app.animation'
import { NocommaPipe } from '../../../shared/pipes/nocomma.pipe'
import { DialogContractComponent } from '../../components/contract-signing/dialog-contract/dialog-contract.component'

@Component({
  selector: 'vr-buy-token',
  templateUrl: './buy-token.component.html',
  styleUrls: ['./buy-token.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class BuyTokenComponent implements OnInit, OnDestroy {
  // @Language() lang: string;

  stage_title = ''
  token_price = 0.016
  backers = 0
  total = 0
  eur_total = 0
  max = 1400000000
  remaining: number = this.max
  buying = false
  payment_currency = ''
  payment_rate = 0
  payment_amount = 0

  capped_stage = false

  multibar = []
  stage_percentage = []
  amount = 20000
  pay_button_diabled: boolean = true

  purchase = {
    payment_type: '',
    amount: (20000).toLocaleString('en-US'),
    cur: 0,
    stage: 0,
  }

  allow_small_purchase: boolean = false
  input_cur: string = this.backend.getInputCurrency().trim()
  cur_selected: number = 0
  chf: number = 0
  eur: number = 0
  ggc: number = 0
  bonus: number = 0
  bonusp: number = 150
  public belowMinAmount: boolean = false

  subscriptions: Subscription[] = []

  rates: any[]

  user: IUser
  session = this.sessionQuery.select()
  kycDone: boolean = false

  constructor(
    private cd: ChangeDetectorRef,
    private global_state: StateService,
    private backend: BackendService,
    private xrates: ExchangeRatesService,
    private toasterService: ToasterService,
    private router: Router,
    private transactions: TransactionsService,
    private wizardFlowService: WizardFlowService,
    private wizardDataService: WizardDataService,
    private sessionQuery: SessionQuery,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private nocommaPipe: NocommaPipe,
    private transactionStore: TransactionStore
  ) {
    iconRegistry.addSvgIcon(
      'coin-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/ggcoin_logo.min.svg')
    )
  }

  ngOnInit() {
    const timer$ = timer(15000, 15000)
    const timerSub = timer$.subscribe(t => {})

    this.subscriptions.push(
      this.backend.getRates().subscribe(rates => {
        const that = this
        that.rates = []
        this.rates.push({
          iso_code: 'BUSD',
          rate: 1,
        })
        rates.result.forEach(x => {
          that.rates.push({
            iso_code: x.iso_code,
            rate: x.rate,
          })
        })
      })
    )

    this.purchase.payment_type = 'BK' + this.backend.getInputCurrency()
    this.input_cur = this.backend.getInputCurrency().trim()

    // USer is not updated with currentUser.next
    this.subscriptions.push(
      this.backend.getUpdateEventEmitter().subscribe(res => {
        this.input_cur = this.backend.getInputCurrency().trim()
      }),
      this.session.subscribe((res: any) => {
        this.user = res.user

        if (res && res.user !== null) {
          this.wizardFlowService.stepsCompleted(res.user)

          if (this.user.compliance_status === 3) {
            this.kycDone = true
          }
        }
      }),
      this.wizardDataService.currentUser$.subscribe(user => {
        this.user = user
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  getRate(iso_code) {
    for (let i = 0; i < this.rates.length; i++) {
      if (this.rates[i].iso_code == iso_code) return this.rates[i].rate
    }
    return 0
  }

  payValue(val: number) {
    this.amount = val
    this.purchase.amount = '' + val.toLocaleString('en-US')
  }

  valueUpdated() {
    const dchr = this.getDecimalCharacter()
    const decimal = this.purchase.amount.split(dchr)
    let v = decimal[0].replace(/\D/g, '')
    if (decimal.length > 1) {
      v += dchr + decimal[1]
    }

    v.length > 8 ? (this.amount = +v.substr(0, 8)) : (this.amount = +v)

    if (+v >= 5000) {
      this.belowMinAmount = false
    } else {
      this.belowMinAmount = true
    }

    this.purchase.amount = this.amount.toLocaleString('en-US')
    this.calculateRates()
  }

  getDecimalCharacter(): string {
    const eg = (0.1).toLocaleString('en-US')
    return eg[1]
  }

  getPurchaseValue(): number {
    let v = this.purchase.amount

    this.allow_small_purchase = v.indexOf('*') !== -1
    if (this.allow_small_purchase) {
      const i = v.indexOf('*')

      const tmp = v.substr(0, i)
      v = tmp + v.substr(i + 1)

      this.allow_small_purchase = true
    }
    const dchr = this.getDecimalCharacter()
    const decimal = v.split(dchr)
    v = decimal[0].replace(/\D/g, '')
    if (decimal.length > 1) {
      v += dchr + decimal[1]
    }

    this.amount = +v

    return +v
  }

  buyTokens() {
    if (this.user.compliance_status !== 3) {
      if (this.kycDone) {
        this.wizardFlowService.openDialog()
      } else {
        this.router.navigateByUrl('admin/profile')
      }
    } else {
      // this.buying = true
      this.calculateRates()

      let ptype = this.purchase.payment_type
      if (ptype === 'BK' || ptype === 'CD' || ptype === 'CA') {
        ptype += this.input_cur
      }

      const purchaseAmount = this.nocommaPipe.transform(this.purchase.amount)

      const dialogRef = this.dialog.open(DialogContractComponent, {
        width: '790px',
        panelClass: 'contract-dialog',
        data: {
          user: this.user,
          ggc: this.ggc,
          usd_amount: purchaseAmount,
          currency: this.payment_currency,
          amount: this.cur_selected,
          type: 'private',
          agreed: 0,
        },
        disableClose: true,
      })

      dialogRef
        .afterClosed()
        // .pipe(map(result => (!result ? 'Default data' : result)))
        .subscribe(result => {
          if (result === 1) {
            this.buying = true

            return this.backend
              .buyCoin(this.getPurchaseValue(), ptype)
              .subscribe(
                (data: any) => {
                  if (data === false) {
                    return
                  }

                  this.transactions.insert(data)

                  this.transactionStore.update({
                    transaction: {
                      current_transaction: data,
                      current_transaction_id: data.id,
                    },
                  })

                  this.global_state.set('current_transaction', data)
                  this.global_state.set('current_transaction_id', data.id)

                  this.router.navigateByUrl('/admin/transfer')
                  this.buying = false
                },
                error => {
                  // console.warn('auth error', error)
                  this.toasterService.pop('error', 'Transaction error ')
                  this.buying = true
                }
              )
          }

          return
        })

      // this.subscriptions.push(
      //   this.backend.buyCoin(this.getPurchaseValue(), ptype).subscribe(
      //     (data: any) => {
      //       console.log('get purchase value', data)
      //       if (data === false) {
      //         return
      //       }
      //
      //       this.transactions.insert(data)
      //
      //       this.global_state.set('current_transaction', data)
      //       this.global_state.set('current_transaction_id', data.id)
      //
      //       this.router.navigateByUrl('/admin/transfer')
      //       this.buying = false
      //     },
      //     error => {
      //       console.warn('auth error', error)
      //       this.toasterService.pop('error', 'Transaction error ')
      //       this.buying = true
      //     }
      //   )
      // )
    }
  }

  selectPayment(cur) {
    if (cur == 'BK' || cur == 'CA') {
      if (this.user.addresses[0].country == 'CH') cur = 'CHF'
      else cur = 'EUR'
    }

    this.payment_currency = cur

    this.pay_button_diabled = false

    this.purchase.payment_type = cur
    this.calculateRates()
  }

  setInputCurrency() {
    this.pay_button_diabled = false

    this.switchCurrency()
    this.calculateRates()
  }

  switchCurrency() {
    let amount: number = this.getPurchaseValue()

    this.pay_button_diabled = false
    this.cd.markForCheck()

    this.allow_small_purchase
      ? (this.purchase.amount = '*' + amount.toLocaleString('en-US'))
      : (this.purchase.amount = '' + amount.toLocaleString('en-US'))

    this.calculateRates()
  }

  calculateRates() {
    const purchaseAmount = this.nocommaPipe.transform(this.purchase.amount)

    this.payment_rate = this.getRate(this.payment_currency)
    this.cur_selected = parseInt(purchaseAmount) * this.payment_rate
    this.ggc = parseInt(purchaseAmount) / 0.0116

    this.cd.markForCheck()
  }
}
