import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { MatIconModule } from '@angular/material/icon'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { TranslateModule } from '@ngx-translate/core'
import { MatTooltipModule } from '@angular/material/tooltip'

import {
  TableTransactionsComponent,
  TransactionCancelComponent,
  TransactionSingleComponent,
} from './table-transactions.component'
import { SharedModule } from '@app/shared/shared.module'

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    SharedModule,
    TranslateModule,
  ],
  declarations: [
    TableTransactionsComponent,
    TransactionCancelComponent,
    TransactionSingleComponent,
  ],
  exports: [
    TableTransactionsComponent,
    TransactionCancelComponent,
    TransactionSingleComponent,
  ],
  entryComponents: [TransactionCancelComponent, TransactionSingleComponent],
})
export class TableTransactionsModule {}
