import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vr-verify-address-check-readability',
  templateUrl: './verify-address-check-readability.component.html',
  styleUrls: ['./verify-address-check-readability.component.scss'],
})
export class VerifyAddressCheckReadabilityComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
