import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs'
import { catchError, map, mapTo, switchMap } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'
import { ID } from '@datorama/akita'

import { VerifyIdentityApprovalComponent } from '../components/verify-identity-approval/verify-identity-approval.component'
import { STEPS } from '../models/wizardFlow.model'
import { StepQuery } from '../store/step.query'
import { IStepState, StepStore } from '../store/step.store'

@Injectable()
export class WizardFlowService {
  private workflow: any[] = [
    {
      id: 1,
      step: STEPS.details,
      valid: false,
      path: 'details',
      group: 'personal',
    },
    {
      id: 2,
      step: STEPS.address,
      valid: false,
      path: 'address',
      group: 'personal',
    },
    {
      id: 3,
      step: STEPS.wallet,
      valid: false,
      path: 'wallet',
      group: 'personal',
    },
    {
      id: 4,
      step: STEPS.typeAddress,
      valid: false,
      path: 'type-address',
      group: 'address',
    },
    {
      id: 5,
      step: STEPS.upload,
      valid: false,
      path: 'upload-address',
      group: 'address',
    },
    {
      id: 6,
      step: STEPS.typeId,
      valid: false,
      path: 'type-id',
      group: 'identity',
    },
    {
      id: 7,
      step: STEPS.uploadId,
      valid: false,
      path: 'upload-id',
      group: 'identity',
    },
    {
      id: 8,
      step: STEPS.uploadSelfie,
      valid: false,
      path: 'upload-selfie',
      group: 'identity',
    },
  ]

  // private step: Step = new Step();

  private currentStep = new Subject<number>()
  public step$ = this.currentStep.asObservable()

  private wizardEnabled = new BehaviorSubject<boolean>(false)
  public wizardEnabled$ = this.wizardEnabled.asObservable()

  private steps = new BehaviorSubject<any>(null)
  public steps$ = this.steps.asObservable()

  constructor(
    private stepStore: StepStore,
    private stepQuery: StepQuery,
    public dialog: MatDialog,
    private ggcoinapiService: GgcoinapiService
  ) {}

  initWizard() {
    this.wizardEnabled.next(true)
  }

  closeWizard() {
    this.wizardEnabled.next(false)
  }

  /**
   * get all wizard steps
   */
  getSteps(): Observable<IStepState> {
    return this.stepQuery.selectAll()
  }

  /**
   * set the wizard steps into the store
   */
  setSteps() {
    this.stepStore.set(this.workflow)
  }

  /**
   * get a specific step
   * @param {ID} id
   */
  getStep(id: ID) {
    const step = this.workflow.find(current => +current.id === +id)
    this.stepStore.add(step)
  }

  activateStep({ id }) {
    this.stepStore.update(id, {
      valid: true,
    })
  }

  deactivateStep({ id, active }) {
    this.stepStore.update(id, {
      valid: false,
    })
  }

  /**
   * set the current step on the wizard
   * @param {number} step
   */
  setCurrentStep(step: number) {
    this.currentStep.next(step)
  }

  getCurrentStep(): Observable<any> {
    return this.currentStep
  }

  /**
   * check if all fields on the step are valid
   * @param {string} step
   */
  validateStep(step: string) {
    let found = false

    for (let i = 0; i < this.workflow.length && !found; i++) {
      if (this.workflow[i].step === step) {
        const id = i + 1

        found = this.stepStore.update(id, {
          valid: true,
        })
      }
    }
  }

  /**
   * check if all fields on the step are valid
   * @param {string} step
   */
  invalidateStep(step: string) {
    let found = false

    for (let i = 0; i < this.workflow.length && !found; i++) {
      if (this.workflow[i].step === step) {
        const id = i + 1
        found = this.stepStore.update(id, {
          valid: false,
        })
      }
    }
  }

  /**
   * get the first invalid step in the wizard
   * @param {string} step
   */
  getFirstInvalidStep(step: string): string {
    let found = false
    let valid = true
    let redirectToStep = ''

    for (let i = 0; i < this.workflow.length && !found && valid; i++) {
      const item = this.workflow[i]
      if (item.step === step) {
        found = true
        redirectToStep = ''
      } else {
        valid = item.valid
        redirectToStep = item.step
      }
    }
    return redirectToStep
  }

  getNextInvalidStep(step: string): string {
    let found = false
    let valid = true
    let redirectToStep = ''

    const currentStep = this.workflow.find(wf => wf.path === step)
    const start = currentStep.id - 1

    for (let i = start; i < this.workflow.length && !found && valid; i++) {
      const item = this.workflow[i]
      valid = item.valid
      redirectToStep = item.step
    }

    return redirectToStep
  }

  /**
   * check if the wizard has been completed
   */
  wizardComplete(user: any): Observable<any> {
    const allSteps = this.stepQuery.selectAll()
    let status: boolean = true
    let steps: any

    allSteps.subscribe(
      res => {
        steps = res
        steps.forEach(step => {
          if (step.valid === false) {
            status = false
          }
        })
      },
      err => {
        throw err
      }
    )
    return of(status)
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(VerifyIdentityApprovalComponent, {
      width: '692px',
      panelClass: 'info-dialog',
    })
    dialogRef.afterClosed().subscribe(result => {
      this.closeWizard()
    })
  }

  /**
   * check all steps and update their validation status
   * @param user
   */
  stepsCompleted(user: any) {
    if (user.compliance_status !== 0) {
      this.validateStep(STEPS.details)
      this.validateStep(STEPS.address)
    }

    if (user.files && user.files.length > 0) {
      for (const file of user.files) {
        if (file.status === 0 || file.status === 1) {
          if (file.type === 0) {
            this.validateStep(STEPS.typeAddress)
            this.validateStep(STEPS.upload)
          } else if (
            (file.type === 1 && file.type !== 2) ||
            (file.type === 1 && file.type === 2)
          ) {
            this.validateStep(STEPS.typeId)
            this.validateStep(STEPS.uploadId)
          } else if (file.type === 3) {
            this.validateStep(STEPS.uploadSelfie)
          }
        } else {
          if (file.type === 0) {
            this.validateStep(STEPS.typeAddress)
            this.invalidateStep(STEPS.upload)
          } else if (
            (file.type === 1 && file.type !== 2) ||
            (file.type === 1 && file.type === 2)
          ) {
            this.validateStep(STEPS.typeId)
            this.invalidateStep(STEPS.uploadId)
          } else if (file.type === 3) {
            this.invalidateStep(STEPS.uploadSelfie)
          }
        }
      }
    }
  }
}
