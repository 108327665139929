import { BehaviorSubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { ContractSigningService } from '@app/admin/components/contract-signing/contract-signing.service'
import { SessionQuery } from '@app/admin/store/session.query'
import { IUser } from '@app/admin/store/session.store'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'

import { DialogInfoComponent } from '../dialog-info/dialog-info.component'
import { cryptoValidator } from './crypto.validator'
import { DialogContractComponent } from './dialog-contract/dialog-contract.component'

@Component({
  selector: 'vr-contract-signing',
  templateUrl: './contract-signing.component.html',
  styleUrls: ['./contract-signing.component.scss'],
})
export class ContractSigningComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = []
  loading: boolean = true
  saving: boolean
  isPublic: boolean

  investmentPublic = new FormGroup({
    ethAddress: new FormControl('', [Validators.required, cryptoValidator]),
    investmentAmount: new FormControl('', [Validators.required]),
  })

  investmentPrivate = new FormGroup({
    ethAddress: new FormControl('', cryptoValidator),
    investmentAmount: new FormControl('', [
      Validators.required,
      Validators.min(5000),
    ]),
    investmentReason: new FormControl('', Validators.required),
    personalBackground: new FormControl(''),
    telegramId: new FormControl(''),
  })

  ethaddress: any

  user: IUser
  whitelisted: boolean = false
  whitelisted_private: boolean = false
  backToProfile: boolean = false
  dialogRef: any

  get ethAddress() {
    return (
      this.investmentPublic.get('ethAddress') ||
      this.investmentPrivate.get('ethAddress')
    )
  }

  get investmentAmount() {
    return (
      this.investmentPublic.get('investmentAmount') ||
      this.investmentPrivate.get('investmentAmount')
    )
  }

  get investmentReason() {
    return this.investmentPrivate.get('investmentReason')
  }

  get personalBackground() {
    return this.investmentPrivate.get('personalBackground')
  }

  get telegramId() {
    return this.investmentPrivate.get('telegramId')
  }

  constructor(
    public dialog: MatDialog,
    private sessionQuery: SessionQuery,
    private router: Router,
    private ggcoinapiService: GgcoinapiService,
    private contractSigningService: ContractSigningService,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const session = this.sessionQuery.select()
    session.pipe(map((res: any) => res.user)).subscribe((user: IUser) => {
      this.user = user

      if (user && user.ico_status > 0) {
        this.whitelisted = true
      }

      if (user && user.ico_status_private > 0) {
        this.whitelisted_private = true
      }
    })

    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe(params => {
        const type = params.get('type')
        type === 'public' ? (this.isPublic = true) : (this.isPublic = false)
      }),
      this.contractSigningService
        .getContractDetails(this.isPublic ? 'public' : 'private')
        .subscribe(res => {
          if (this.isPublic) {
            this.investmentPublic.patchValue({
              ethAddress: res.result.eth_address,
              investmentAmount: res.result.investment,
            })
          } else {
            this.investmentPrivate.patchValue({
              ethAddress: res.result.eth_address,
              investmentAmount: res.result.investment,
              investmentReason: res.result.investment_reason,
              personalBackground: res.result.personal_background,
              telegramId: res.result.telegram_id,
            })
          }
          this.loading = false
          this.cd.detectChanges()
        })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Submit investment amount and ETH address
   */
  submitInvestment() {
    this.saving = true
    if (!this.whitelisted && this.isPublic) {
      this.subscriptions.push(
        this.ggcoinapiService
          .post('/rest/savePublicWhitelist', {
            user: this.user,
            investment: this.investmentPublic.value,
          })
          .subscribe(() => {
            const dialogRef = this.dialog.open(DialogContractComponent, {
              width: '790px',
              panelClass: 'contract-dialog',
              data: {
                user: this.user,
                investmentAmount: this.investmentPublic.value.investmentAmount,
                type: 'public',
              },
              disableClose: true,
            })

            this.saving = false

            dialogRef.afterClosed().subscribe(() => {
              this.router.navigate(['admin/profile'])
            })
          })
      )

      // this.ggcoinapiService
      //   .post('/rest/savePublicWhitelist', this.investmentPublic.value)
      //   .pipe(
      //     map(() => {
      //       const dialogRef = this.dialog.open(DialogContractComponent, {
      //         width: '790px',
      //         panelClass: 'contract-dialog',
      //         data: {
      //           user: this.user,
      //           investmentAmount: this.investmentPublic.value.investmentAmount,
      //           type: 'public',
      //         },
      //         disableClose: true,
      //       })
      //
      //       dialogRef.afterClosed().subscribe(() => {
      //         this.router.navigate(['admin/profile'])
      //       })
      //     })
      //   )
    } else if (!this.whitelisted_private && !this.isPublic) {
      const newUser = {
        user: {},
        investmentData: {},
      }
      newUser.user = this.user
      newUser.investmentData = this.investmentPrivate.value
      this.subscriptions.push(
        this.ggcoinapiService
          .post('/rest/savePrivateWhitelist', newUser)
          .subscribe(() => {
            this.ggcoinapiService
              .post('/rest/setIcoStatus', {
                status: 2,
                type: 'private',
              })
              .subscribe(() => {
                this.snackbar.open('Application for private sale sent', 'Ok', {
                  duration: 4000,
                })

                this.saving = false

                this.router.navigateByUrl('admin/profile')
              })
          })
      )
    } else {
      this.saving = true
      this.updateEthAddress()
    }
  }

  /**
   * Update user's ETH address
   */
  updateEthAddress() {
    const address = this.isPublic
      ? this.investmentPublic.value.ethAddress
      : this.investmentPrivate.value.ethAddress

    this.subscriptions.push(
      this.ggcoinapiService
        .post('/rest/updateEthAddress', {
          address,
        })
        .subscribe(res => {
          this.saving = false
          this.snackbar.open('ETH address updated successfully', 'OK', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          })
          this.backToProfile = true
          this.cd.detectChanges()
        })
    )
  }

  /**
   * Open info dialog about what an ETH address is
   */
  openInfoDialog(): void {
    const dialogRef = this.dialog.open(DialogInfoComponent, {
      width: '350px',
      data: {
        title: 'What is and ETH address?',
        content:
          '<p>Like other blockchains, Ethereum has a native cryptocurrency called Ether (ETH). ETH is digital money. If you’ve heard of Bitcoin, ETH has many of the same features. It is purely digital, and can be sent to anyone anywhere in the world instantly. The supply of ETH isn’t controlled by any government or company - it is decentralized, and it is scarce. People all over the world use ETH to make payments, as a store of value, or as collateral.</p> <p>Don\'t have an Ether address?</p> <a href="https://myetherwallet.com/" class="color-accent" target="_blank">Create one now</a>',
      },
    })
  }
}
