import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material'
import { TranslateModule } from '@ngx-translate/core'

import { TableReferralsComponent } from './table-referrals.component'

@NgModule({
  imports: [CommonModule, MatTableModule, TranslateModule],
  declarations: [TableReferralsComponent],
  exports: [TableReferralsComponent],
})
export class TableReferralsModule {}
