import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'vr-contract-public-sale',
  templateUrl: './contract-public-sale.component.html',
  styleUrls: ['./contract-public-sale.component.scss'],
})
export class ContractPublicSaleComponent implements OnInit {
  @Input() user
  @Input() investment

  constructor() {}

  ngOnInit() {}
}
