import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'vr-payment-coins-widget',
  templateUrl: './payment-coins-widget.component.html',
  styleUrls: ['./payment-coins-widget.component.scss'],
})
export class PaymentCoinsWidgetComponent implements OnInit {
  @Output()
  select = new EventEmitter<string>()
  @Input()
  coin: String

  public items: Array<any>

  constructor() {}

  ngOnInit() {
    this.items = [
      { icon: '/assets/img/coins/bnb.svg', name: 'BNB', code: 'BNB' },
      {
        icon: '/assets/img/coins/busd.svg',
        name: 'Binance USD',
        code: 'BUSD',
      },
    ]
  }

  selected(cur: string) {
    this.coin = cur
    this.select.emit(cur)
  }
}
