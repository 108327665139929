export class Step {
  id: number
  step: any
  valid: boolean
  path: string
}

export const STEPS = {
  details: 'details',
  address: 'address',
  wallet: 'wallet',
  typeAddress: 'type-address',
  upload: 'upload-address',
  check: 'check',
  typeId: 'type-id',
  uploadId: 'upload-id',
  uploadSelfie: 'upload-selfie',
}
