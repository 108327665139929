import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Response } from '@angular/http'
import { Router } from '@angular/router'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
import { environment } from '@env/environment'

import { JwtService } from './jwt.service'

@Injectable()
export class ApiService {
  public csrfToken: string

  constructor(
    protected http: HttpClient,
    protected jwtService: JwtService,
    protected storage: LocalStorageService,
    protected router: Router
  ) {}

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    }

    if (window.localStorage['appToken']) {
      headersConfig['x-csrf-token'] = window.localStorage['appToken']
    }

    if (this.jwtService.getAccountType()) {
      headersConfig['Account-Type'] = this.jwtService.getAccountType()
    }

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`
    }

    // switch (type) {
    //   case 'kyc':
    //     if (this.jwtService.getToken())
    //       headersConfig[
    //         'Authorization'
    //       ] = `Bearer ${this.jwtService.getToken()}`
    //     break
    //
    //   case 'ggcoin':
    //     if (this.jwtService.getToken())
    //       headersConfig[
    //         'Authorization'
    //       ] = `Bearer ${this.jwtService.getToken()}`
    //     break
    //
    //   case 'coinapi':
    //     if (this.jwtService.getCoinToken())
    //       headersConfig[
    //         'Authorization'
    //       ] = `Bearer ${this.jwtService.getCoinToken()}`
    //     break
    // }

    return new HttpHeaders(headersConfig)
  }

  private setHeadersPdf(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf',
    }

    if (window.localStorage['appToken']) {
      headersConfig['x-csrf-token'] = window.localStorage['appToken']
    }

    if (this.jwtService.getAccountType()) {
      headersConfig['Account-Type'] = this.jwtService.getAccountType()
    }

    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`
    }

    return new HttpHeaders(headersConfig)
  }

  private formatErrors(error: any) {
    return observableThrowError(error)
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.gingr_api}${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }

  getLocalAsset(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http.get(path).pipe(
      catchError(this.formatErrors),
      map((res: Response) => res)
    )
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.gingr_api}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.gingr_api}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => {
          const response = res
          if (response && response.status !== 200) {
            throw new Error(response.text.toString())
          } else {
            return res
          }
        })
      )
  }

  kycrequest(method, url, body) {
    return this.http
      .post(
        `${environment.ggcoinUrl}/kyc`,
        JSON.stringify({ method, url, params: body }),
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => {
          const response = res
          if (response && response.status !== 200) {
            throw new Error(response.text.toString())
          } else {
            return res
          }
        })
      )
  }

  /**
   * POST request to the KYC API
   * @param path
   * @param body
   */
  postKycApi(path: string, body: object = {}): Observable<any> {
    return this.kycrequest('POST', path, body)
  }

  /**
   * PUT request to the KYC API
   * @param path
   * @param body
   */
  putKycApi(path: string, body: object = {}): Observable<any> {
    return this.kycrequest('PUT', path, body)
  }

  /**
   * GET request to the KYC API
   * @param path
   * @param params
   */
  getKycApi(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.kycrequest('GET', path, null)
  }

  getKycApiContract(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.http
      .get(`${environment.kycUrl}${path}`, {
        headers: this.setHeadersPdf(),
        params,
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }

  delete(path): Observable<any> {
    return this.http
      .delete(`${environment.gingr_api}${path}`, {
        headers: this.setHeaders(),
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }

  checkSessionIsValid(): Observable<any> {
    return this.http
      .post(
        `${environment.gingr_api}/userData`,
        {},
        { headers: this.setHeaders() }
      )
      .pipe(
        map(
          data => {
            this.storage.setItem('userData', data)
          },
          err => {
            if (
              err.exception &&
              err.exception.indexOf('Token has expired') > -1
            ) {
              return false
            }
          }
        )
        // map((res: Response) => {
        //   const response = res;
        //   if (response && response.status !== 200) {
        //     if (
        //       response.exception &&
        //       response.exception.indexOf('Token has expired') > -1
        //     ) {
        //       return false;
        //     }
        //   } else {
        //     this.storage.setItem('userData', response.result);
        //     return true;
        //   }
        // })
      )
  }
}
