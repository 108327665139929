import { Routes } from '@angular/router'

import { AdminComponent } from './admin.component'
import { PersonalDetailsAddressComponent } from './components/personal-details-address/personal-details-address.component'
import { PersonalDetailsDetailsComponent } from './components/personal-details-details/personal-details-details.component'
import { PersonalDetailsWalletComponent } from './components/personal-details-wallet/personal-details-wallet.component'
import { VerifyAddressCheckReadabilityComponent } from './components/verify-address-check-readability/verify-address-check-readability.component'
import { VerifyAddressTypeComponent } from './components/verify-address-type/verify-address-type.component'
import { VerifyAddressUploadComponent } from './components/verify-address-upload/verify-address-upload.component'
import { VerifyIdentityApprovalComponent } from './components/verify-identity-approval/verify-identity-approval.component'
import { VerifyIdentityTypeComponent } from './components/verify-identity-type/verify-identity-type.component'
import { VerifyIdentityUploadIdComponent } from './components/verify-identity-upload-id/verify-identity-upload-id.component'
import { VerifyIdentityUploadSelfieComponent } from './components/verify-identity-upload-selfie/verify-identity-upload-selfie.component'
// import { AlwaysAuthGuard } from '@app/shared/guards/always-auth.guard';
import { VerifyPrivateSaleApplicationComponent } from './components/verify-private-sale-application/verify-private-sale-application.component'
import { BuyTokenComponent } from './containers/buy-token/buy-token.component'
import { EarnTokensComponent } from './containers/earn-tokens/earn-tokens.component'
import { ProfileComponent } from './containers/profile/profile.component'
import { SendCoinsComponent } from './containers/send-coins/send-coins.component'
import { SettingsComponent } from './containers/settings/settings.component'
import { SupportComponent } from './containers/support/support.component'
import { TransactionsComponent } from './containers/transactions/transactions.component'
import { TransferComponent } from './containers/transfer/transfer.component'
import { WizardPersonalDetailsComponent } from './containers/wizard-personal-details/wizard-personal-details.component'
// import { PrivateSaleGuard } from './guards/private-sale.guard'
import { WorkflowGuard } from './services/wizard-guard.service'

export const adminRoutes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'buy-coins',
        component: BuyTokenComponent,
        // canActivate: [PrivateSaleGuard],
      },
      {
        path: 'earn-coins',
        component: EarnTokensComponent,
      },
      {
        path: 'send-coins',
        component: SendCoinsComponent,
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'transfer',
        component: TransferComponent,
      },
      {
        path: 'support',
        component: SupportComponent,
      },
      {
        path: 'profile/kyc',
        component: WizardPersonalDetailsComponent,
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'full' },
          {
            path: 'details',
            component: PersonalDetailsDetailsComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'address',
            component: PersonalDetailsAddressComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'wallet',
            component: PersonalDetailsWalletComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'type-address',
            component: VerifyAddressTypeComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'upload-address',
            component: VerifyAddressUploadComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'check-readability',
            component: VerifyAddressCheckReadabilityComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'type-id',
            component: VerifyIdentityTypeComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'upload-id',
            component: VerifyIdentityUploadIdComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'upload-selfie',
            component: VerifyIdentityUploadSelfieComponent,
            canActivate: [WorkflowGuard],
          },
          {
            path: 'approval',
            component: VerifyIdentityApprovalComponent,
            canActivate: [WorkflowGuard],
          },
        ],
      },
      {
        path: 'whitelist/:type',
        loadChildren:
          'app/admin/components/contract-signing/contract-signing.module#ContractSigningModule',
      },
      {
        path: 'private-sale-application/:type/:user',
        component: VerifyPrivateSaleApplicationComponent,
      },
    ],
  },
]
