import { InlineSVGModule } from 'ng-inline-svg'

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { RouterModule } from '@angular/router'
import { ScrollDirective } from '@app/shared/directives/scroll.directive'
import { TranslateModule } from '@ngx-translate/core'

import { NocommaPipe } from '../../../shared/pipes/nocomma.pipe'
import { ContractPrivateSaleComponent } from './contract-private-sale/contract-private-sale.component'
import { ContractPublicSaleComponent } from './contract-public-sale/contract-public-sale.component'
import { ContractSigningDownloadService } from './contract-signing-download.service'
import { ContractSigningComponent } from './contract-signing.component'
import { ContractSigningRoutes } from './contract-signing.routing'
import { ContractSigningService } from './contract-signing.service'
import { DialogContractComponent } from './dialog-contract/dialog-contract.component'

@NgModule({
  declarations: [
    ContractSigningComponent,
    DialogContractComponent,
    ContractPrivateSaleComponent,
    ScrollDirective,
    ContractPublicSaleComponent,
    NocommaPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InlineSVGModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    TranslateModule,
    // PDFExportModule,
    // ScrollDirective,
    RouterModule.forChild(ContractSigningRoutes),
  ],
  exports: [RouterModule],
  entryComponents: [DialogContractComponent],
  providers: [
    ContractSigningService,
    ContractSigningDownloadService,
    NocommaPipe,
  ],
})
export class ContractSigningModule {}
