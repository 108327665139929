// 3rd party modules
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { InlineSVGModule } from 'ng-inline-svg'

// Components
import { PaymentCoinsWidgetComponent } from './payment-coins-widget.component'

@NgModule({
  imports: [CommonModule, FlexLayoutModule, InlineSVGModule],
  declarations: [PaymentCoinsWidgetComponent],
  exports: [PaymentCoinsWidgetComponent],
})
export class PaymentCoinsWidgetModule {}
