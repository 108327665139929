import { Injectable } from '@angular/core'

import {
  Transactions,
  TransactionsInterface,
} from '../models/transactions.model'
import { BackendService } from '@app/shared/services/backend.service'

import { Observable, BehaviorSubject } from 'rxjs'

@Injectable()
export class TransactionsService {
  protected updated: number = 0
  protected transactions: Transactions = new Transactions()
  protected observable: Observable<TransactionsInterface[]> = null

  constructor(private backend: BackendService) {
    this.sync()
  }

  public sync(): Observable<TransactionsInterface[]> {
    const that = this
    if (!this.observable) {
      this.observable = Observable.create(observer => {
        this.transactions.clear()
        this.backend.getTransactions().subscribe(function(transactions: any) {
          that.transactions.clear()

          that.updated = new Date().valueOf()
          that.transactions.insertList(transactions)
          observer.next(that.transactions.get())
          observer.complete()
        })
      })
    }
    return this.observable
  }

  public insert(transaction: TransactionsInterface) {
    this.transactions.insert(transaction)
  }

  public safeGet(): Observable<TransactionsInterface[]> {
    const that = this
    let tm = new Date().valueOf()

    if (this.updated + 3600000 < tm) {
      return this.sync()
    } else {
      return Observable.create(observer => {
        observer.next(that.transactions.get())
        observer.complete()
      })
    }
  }

  public get(): TransactionsInterface[] {
    return this.transactions.get()
  }

  public getLastUpdate(): number {
    return this.updated
  }

  public remove(txn_id): void {
    this.transactions.remove(txn_id)
  }

  public reset() {
    this.transactions = new Transactions()
  }
}
