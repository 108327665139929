import { ToasterService } from 'angular2-toaster'

// 3rd Party Modules
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
// Services
import { BackendService } from '@app/shared/services/backend.service'
import { ReferralsService } from '@app/shared/services/referrals.service'

// Animaitons
import { ROUTE_TRANSITION } from '../../../app.animation'

@Component({
  selector: 'vr-earn-tokens',
  templateUrl: './earn-tokens.component.html',
  styleUrls: ['./earn-tokens.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class EarnTokensComponent implements OnInit {
  isCopied1 = false
  referral_code = ''
  referral_url = ''
  referrals: any = null

  ref_state: string = 'loading'

  constructor(
    private cd: ChangeDetectorRef,
    private backend: BackendService,
    private refs: ReferralsService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.backend.getUser().subscribe((user: any) => {
      const u: any = user.result
      this.referral_code = u.referral
      this.referral_url =
        'https://admin.ggcoin.io/auth/register/?refcode=' + u.referral

      this.cd.markForCheck()

      this.refs.sync().subscribe(data => {
        this.referrals = data
        if (!data.length) {
          this.ref_state = 'empty'
        } else {
          this.ref_state = 'ok'
        }

        this.cd.markForCheck()
      })
    })
  }

  isCopied() {
    this.isCopied1 = true
    this.toasterService.pop('success', 'Referral link copied')
  }
}
