import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import {
  MatButtonModule,
  MatIconModule,
  MatRippleModule,
  MatSidenavModule,
} from '@angular/material'
import { RouterModule } from '@angular/router'

import { SharedModule } from '../../shared/shared.module'
import { ScrollbarModule } from '../scrollbar/scrollbar.module'
import { ToolbarModule } from '../toolbar/toolbar.module'
import { LayoutComponent } from './layout.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatSidenavModule,
    ScrollbarModule,
    ToolbarModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    SharedModule,
  ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
})
export class LayoutModule {}
