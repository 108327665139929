import { Observable, Subscription } from 'rxjs'

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { WizardPersonal, WizPersonal } from '@app/admin/models'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { IStep } from '@app/admin/store/step.store'
import { BackendService } from '@app/shared/services/backend.service'
import { WizardDataService } from '@app/shared/services/wizard-data.service'

import { PhoneCountryCodes } from '../../helpers/phone-prefixes'
import { PersonalDetailsInfoComponent } from '../personal-details-info/personal-details-info.component'

const range = (start, end) =>
  Array.from({ length: end - start }, (v, k) => k + start)

@Component({
  selector: 'vr-personal-details-details',
  templateUrl: './personal-details-details.component.html',
  styleUrls: ['./personal-details-details.component.scss'],
})
export class PersonalDetailsDetailsComponent implements OnInit, OnDestroy {
  wizardPersonal: WizardPersonal
  personal: WizPersonal
  form: any
  steps: any
  currentStep: number = 1
  user: IUser
  private sub: Subscription[] = []
  phoneCountryCodes: object[]

  years: string[] = range(1950, 2001)

  step$: Observable<IStep[]>
  steps$: Observable<IStep[]>

  constructor(
    public dialog: MatDialog,
    private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService,
    private backend: BackendService,
    private router: Router,
    public stepQuery: StepQuery,
    private cd: ChangeDetectorRef
  ) {
    this.sub.push(
      /**
       * get the current user
       */
      this.backend.getUser().subscribe(
        (res: any) => {
          this.user = res.result
        },
        err => {
          throw err
        }
      )
    )
  }

  ngOnInit() {
    this.wizardDataService.getPersonalFormData()
    this.getPersonal()

    this.steps$ = this.stepQuery.selectAll()
    this.sub.push(
      this.steps$.subscribe(res => {
        this.steps = res
      })
    )

    this.phoneCountryCodes = PhoneCountryCodes.getPhoneCountryCodes()
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Get form data
   */
  getPersonal() {
    this.sub.push(
      this.wizardDataService.wizardPersonal.subscribe(res => {
        this.personal = {
          firstName: res.firstName,
          lastName: res.lastName,
          date_of_birth: res.date_of_birth,
          phoneNumber: res.phoneNumber,
          phoneNumberPrefix: res.phoneNumberPrefix,
        }

        this.cd.detectChanges()
      })
    )
  }

  /**
   * Open the info dialog
   */
  openDialog(): void {
    this.dialog.open(PersonalDetailsInfoComponent, {
      width: '692px',
      panelClass: 'info-dialog',
    })
  }

  /**
   * save the form
   * @param form
   */
  save(form: any): boolean {
    if (!form.valid) {
      return false
    }
    this.wizardDataService.setPersonal(this.personal, this.user)
    return true
  }

  /**
   * submit the form and navigate to the next step
   * @param form
   */
  submitForm(form: any) {
    if (this.save(form)) {
      const step = this.steps[this.currentStep]
      this.wizardFlowService.setCurrentStep(this.currentStep + 1)
      this.router.navigate(['admin/profile/kyc/' + step.path])
    }
  }
}
