import { Injectable } from '@angular/core'

const APP_PREFIX = 'vr-'

@Injectable()
export class LocalStorageService {
  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        state = state || {}
        const stateKey = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
        let currentStateRef = state
        stateKey.forEach((key, index) => {
          if (index === stateKey.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey))
            return
          }
          currentStateRef[key] = currentStateRef[key] || {}
          currentStateRef = currentStateRef[key]
        })
      }
      return state
    }, undefined)
  }

  constructor() {}

  setItem(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value))
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`))
  }

  removeItem(key: string) {
    localStorage.removeItem(key)
  }

  isOlder(key: string, days: number) {
    const val = this.getItem(key)

    if (!val) {
      return true
    }

    if (val.timestamp) {
      const td = days * 8640
      const now = Math.floor(Date.now() / 1000)
      const diff = now - val.timestamp

      if (diff > td) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}
