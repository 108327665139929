import { ToasterService } from 'angular2-toaster'

// 3rd Party Modules
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { BackendService } from '@app/shared/services/backend.service'
import { JwtService } from '@app/shared/services/jwt.service'
// Services
import { StateService } from '@app/shared/services/state.service'

// Animations
import { ROUTE_TRANSITION } from '../../../app.animation'

// Models
// import { TransactionsInterface } from '@app/shared/models/transactions.model';

@Component({
  selector: 'vr-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class TransferComponent implements OnInit {
  transaction: any
  code: string
  currency: string = 'BK'
  amount: string = '0'
  address: string
  expired: number
  isCopiedAddress = false
  token: string
  trans_id: number

  processing: boolean = false

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private state: StateService,
    private backend: BackendService,
    private toasterService: ToasterService,
    private jwt: JwtService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'warning-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/warning-icon.svg')
    )
  }

  ngOnInit() {
    // const that = this

    window.scrollTo(0, 0)

    this.token = this.jwt.getToken()
    this.trans_id = Number(this.state.get('current_transaction_id'))

    // console.log('transfer -> token', this.token)
    // console.log('transfer -> trans_id', this.trans_id)

    if (!this.trans_id) {
      this.trans_id = window.localStorage['current_transaction_id']
    } else {
      window.localStorage['current_transaction_id'] = this.trans_id
    }

    this.transaction = this.state.get('current_transaction')
    // console.log('this transactions', this.transaction)

    if (
      typeof this.transaction === 'object' &&
      this.transaction.status !== '403'
    ) {
      this.state.remove('current_transaction')

      this.code = this.transaction.txn_id
      this.currency = this.transaction.payment_currency
      this.amount = this.transaction.payment_amount
      this.address = this.transaction.payment_address

      // console.log('new transaction', this.currency)
      this.cd.markForCheck()
    } else {
      // console.log('get transaction')
      // console.log('Trans id', this.trans_id)
      this.backend
        .getTransaction(this.trans_id)
        .subscribe((transaction: any) => {
          // console.log('transaction', transaction)
          this.transaction = transaction

          // console.log(transaction);

          this.code = transaction.txn_id
          this.currency = transaction.payment_currency
          this.amount = transaction.payment_amount
          this.address = transaction.payment_address

          this.cd.markForCheck()
        })
    }
  }

  isCopied() {
    this.isCopiedAddress = true
    this.toasterService.pop('success', 'Address copied')
  }

  goTransactions() {
    this.router.navigateByUrl('/admin/transactions')
  }

  openCheckout() {
    // const that = this

    const amt = parseInt('' + parseFloat(this.amount) * 100)

    const handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_BILyxGtXqM0yhoTUL0PSe9JV',
      locale: 'auto',
      token: (token: any) => {
        // console.log(that.transaction);

        this.processing = true
        this.cd.markForCheck()

        this.backend
          .registerStripePayment(this.transaction.txn_id, token.id, amt)
          .subscribe(res => {
            // console.log(res);
          })
      },
    })

    handler.open({
      name: 'GG Coin Purchase',
      description: 'coins',
      amount: amt,
    })
  }
}
