import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { IUser } from '@app/admin/store/session.store'
import { BackendService } from '@app/shared/services/backend.service'
import { GenericRequestService } from '@app/shared/services/generic-request.service'
import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'vr-contract-private-sale',
  templateUrl: './contract-private-sale.component.html',
  styleUrls: ['./contract-private-sale.component.scss'],
})
export class ContractPrivateSaleComponent implements OnInit {
  @Input() user: IUser
  @Input() investment

  @ViewChild('iframe') iframe: ElementRef

  public ggc

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private backend: BackendService,
    private request: GenericRequestService
  ) {}

  ngOnInit() {
    const that = this
    const doc =
      this.iframe.nativeElement.contentDocument ||
      this.iframe.nativeElement.contentWindow

    this.backend.getKycToken().subscribe(res => {
      const kyc_token = res.result

      this.request
        .get(
          environment.kycUrl +
            '/contract/preview/' +
            kyc_token +
            '/' +
            that.data.usd_amount +
            '/' +
            that.data.currency +
            '/' +
            that.data.amount +
            '/' +
            that.data.ggc
        )
        .subscribe(res => {
          doc.open()
          doc.write(res)
          doc.close()

          /*doc.addEventListener('scroll', function(event) {
          const height = event.target.defaultView.pageYOffset
        }, false);*/
        })
    })
  }
}
