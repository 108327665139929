import { ToasterModule } from 'angular2-toaster'
import { InlineSVGModule } from 'ng-inline-svg'
import { ClipboardModule } from 'ngx-clipboard'

import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
// 3rd party modules
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDividerModule } from '@angular/material/divider'
// import { MatFileUploadModule } from 'angular-material-fileupload';
// Material Modules
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { UploadModule } from '@app/upload/upload.module'
import { TranslateModule } from '@ngx-translate/core'

// Guards
import { AlwaysAuthGuard } from './guards/always-auth.guard'
// import { NocommaPipe } from './pipes/nocomma.pipe'
import { MediaService } from './services/media.service'
// Services
import { UserService } from './services/user.service'
import { WizardDataService } from '@app/shared/services/wizard-data.service'

// import { ScrollDirective } from './directives/scroll.directive'

// Pipes
// import { TruncatePipe } from './pipes/truncate.pipe';

// Components
// import { CleanNumPipe } from './pipes/clean-num.pipe';
// import {
//   DialogAddressComponent,
//   DialogAddressModalComponent,
// } from './components/dialog-address/dialog-address.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToasterModule.forChild(),
    FormsModule,
    // PerfectScrollbarModule,
    InlineSVGModule.forRoot(),
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    // MatFileUploadModule,
    FlexLayoutModule,
    UploadModule,
  ],
  exports: [
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToasterModule,
    FormsModule,
    // TruncatePipe,
    InlineSVGModule,
    ClipboardModule,
    TranslateModule,
    UploadModule,
    // NocommaPipe,
    // MatFileUploadModule,
    // DialogAddressComponent,
    // DialogAddressModalComponent,
  ],
  declarations: [
    // TruncatePipe,
    // CleanNumPipe,
    // DialogAddressComponent,
    // DialogAddressModalComponent,
    // ScrollDirective
    // NocommaPipe,
  ],
  providers: [
    FormBuilder,
    AlwaysAuthGuard,
    UserService,
    MediaService,
    // NocommaPipe,
  ],
  // entryComponents: [DialogAddressModalComponent],
})
export class SharedModule {}
