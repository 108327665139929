import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CookiePolicyComponent } from './cookie-policy.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatDividerModule, MatIconModule],
  declarations: [CookiePolicyComponent],
})
export class CookiePolicyModule {}
