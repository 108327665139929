import { Observable, of, Subscription } from 'rxjs'

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { WizardPersonal, WizWallet } from '@app/admin/models'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { IStep } from '@app/admin/store/step.store'
import { BackendService } from '@app/shared/services/backend.service'

import { PersonalDetailsInfoComponent } from '../personal-details-info/personal-details-info.component'
import { map } from 'rxjs/operators'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'

const range = (start, end) =>
  Array.from({ length: end - start }, (v, k) => k + start)

@Component({
  selector: 'vr-personal-details-wallet',
  templateUrl: './personal-details-wallet.component.html',
  styleUrls: ['./personal-details-wallet.component.scss'],
})
export class PersonalDetailsWalletComponent implements OnInit, OnDestroy {
  wizardPersonal: WizardPersonal
  personal: WizWallet
  form: any
  steps: any
  currentStep: number = 3
  user: IUser
  private sub: Subscription[] = []

  step$: Observable<IStep[]>
  steps$: Observable<IStep[]>

  constructor(
    public dialog: MatDialog,
    private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService,
    private backend: BackendService,
    private router: Router,
    public stepQuery: StepQuery,
    private cd: ChangeDetectorRef,
    private ggcoinService: GgcoinapiService
  ) {
    this.sub.push(
      /**
       * get the current user
       */
      this.backend.getUser().subscribe(
        (res: any) => {
          this.user = res.result
        },
        err => {
          throw err
        }
      )
    )
  }

  ngOnInit() {
    this.wizardDataService.getPersonalFormData()
    this.getPersonal()

    this.steps$ = this.stepQuery.selectAll()
    this.sub.push(
      this.steps$.subscribe(res => {
        this.steps = res
      })
    )
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Get form data
   */
  getPersonal() {
    this.sub.push(
      this.wizardDataService.wizardPersonal.subscribe(res => {
        this.personal = {
          user_id: res.user_id,
          wallet: res.wallet,
        }
        this.cd.detectChanges()
      })
    )
  }

  /**
   * Open the info dialog
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(PersonalDetailsInfoComponent, {
      width: '692px',
      panelClass: 'info-dialog',
    })
  }

  /**
   * save the form
   * @param form
   */
  save(form: any): Observable<any> {
    if (!form.valid) {
      return of(false)
    }
    return this.wizardDataService.setWallet(this.personal).pipe(map(res => res))
  }

  /**
   * submit the form and navigate to the next step
   * @param form
   */
  async submitForm(form: any) {
    this.save(form).subscribe(result => {
      if (result === true) {
        const step = this.steps[this.currentStep]

        this.wizardFlowService.wizardComplete(this.user).subscribe(
          res => {
            if (!res) {
              if (step.valid === true) {
                // this.isPersonalComplete()
                setTimeout(() => {
                  return this.router.navigate(['admin/profile'])
                }, 100)
              }

              // this.isPersonalComplete()
              this.wizardFlowService.setCurrentStep(this.currentStep + 1)
              this.router.navigate(['admin/profile/kyc/' + step.path])
            } else {
              this.wizardFlowService.openDialog()
            }
          },
          err => console.log(err),
          () => this.isPersonalComplete()
        )
      }
    })

    //console.log(form)

    // SEARCHTAG: todo: kyc eth update
    //await this.backend.postKycApi('/ethAddress', { user_id: this.user.id, public_address })

    // this.wizardFlowService.wizardComplete().subscribe(res => {
    //   if (!res) {
    //     this.wizardFlowService.setCurrentStep(this.currentStep + 1)
    //     this.router.navigate(['admin/profile/kyc/' + step.path])
    //   } else {
    //     this.wizardFlowService.openDialog()
    //   }
    // })
  }

  isPersonalComplete() {
    const isComplete = this.wizardDataService.isPersonalComplete()
    isComplete.subscribe(res => {
      if (res.status === 'success' && this.user.ico_status === null) {
        this.ggcoinService
          .post('/rest/setIcoStatus', { status: 0, type: 'public' })
          .subscribe()
      }

      if (res.status === 'success' && this.user.ico_status_private === null) {
        this.ggcoinService
          .post('/rest/setIcoStatus', { status: 0, type: 'private' })
          .subscribe()
      }
    })
  }
}
