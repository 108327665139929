import { CommonModule } from '@angular/common'
// 3rd party modules
import { LOCALE_ID, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { ImagePreviewDialogComponent } from '@app/admin/components/image-preview-dialog/image-preview-dialog.component'
import { SharedModule } from '@app/shared/shared.module'
import { FileValidator } from '@app/shared/validators/file-input.validator'

import { ScrollbarModule } from '../core/scrollbar/scrollbar.module'
import { AdminComponent } from './admin.component'
import { adminRoutes } from './admin.routing'
import { CardComponent } from './components/card/card.component'
import { ContractSigningModule } from './components/contract-signing/contract-signing.module'
import {
  DialogAddressComponent,
  DialogAddressModalComponent,
} from './components/dialog-address/dialog-address.component'
import { DialogInfoComponent } from './components/dialog-info/dialog-info.component'
import { DialogTwofaEmailComponent } from './components/dialog-twofa-email/dialog-twofa-email.component'
import { DialogTwofaComponent } from './components/dialog-twofa/dialog-twofa.component'
import { FooterComponent } from './components/footer/footer.component'
import { NavBarPaginationComponent } from './components/nav-bar-pagination/nav-bar-pagination.component'
import { PaymentBankWidgetModule } from './components/payment-bank-widget/payment-bank-widget.module'
import { PaymentCardWidgetModule } from './components/payment-card-widget/payment-card-widget.module'
import { PaymentCoinsWidgetModule } from './components/payment-coins-widget/payment-coins-widget.module'
import { PersonalDetailsAddressComponent } from './components/personal-details-address/personal-details-address.component'
import { PersonalDetailsDetailsComponent } from './components/personal-details-details/personal-details-details.component'
import { PersonalDetailsInfoComponent } from './components/personal-details-info/personal-details-info.component'
import { PersonalDetailsWalletComponent } from './components/personal-details-wallet/personal-details-wallet.component'
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component'
import { TableReferralsModule } from './components/table-referrals/table-referrals.module'
import { TableTransactionsModule } from './components/table-transactions/table-transactions.module'
import { VerifyAddressCheckReadabilityComponent } from './components/verify-address-check-readability/verify-address-check-readability.component'
import { VerifyAddressTypeComponent } from './components/verify-address-type/verify-address-type.component'
import { VerifyAddressUploadComponent } from './components/verify-address-upload/verify-address-upload.component'
import { VerifyIdentityApprovalComponent } from './components/verify-identity-approval/verify-identity-approval.component'
import { VerifyIdentityTypeComponent } from './components/verify-identity-type/verify-identity-type.component'
import { VerifyIdentityUploadIdComponent } from './components/verify-identity-upload-id/verify-identity-upload-id.component'
import { VerifyIdentityUploadSelfieComponent } from './components/verify-identity-upload-selfie/verify-identity-upload-selfie.component'
import { VerifyPrivateSaleApplicationComponent } from './components/verify-private-sale-application/verify-private-sale-application.component'
import { WizardNavComponent } from './components/wizard-nav/wizard-nav.component'
import { BuyTokenComponent } from './containers/buy-token/buy-token.component'
import { EarnTokensComponent } from './containers/earn-tokens/earn-tokens.component'
import { ProfileComponent } from './containers/profile/profile.component'
import { SendCoinsComponent } from './containers/send-coins/send-coins.component'
import { SettingsComponent } from './containers/settings/settings.component'
import { SupportComponent } from './containers/support/support.component'
import { TransactionsComponent } from './containers/transactions/transactions.component'
import { TransferComponent } from './containers/transfer/transfer.component'
import { WizardPersonalDetailsComponent } from './containers/wizard-personal-details/wizard-personal-details.component'
import { WizardVerifyAddressComponent } from './containers/wizard-verify-address/wizard-verify-address.component'
import { WizardVerifyIdComponent } from './containers/wizard-verify-id/wizard-verify-id.component'
import { WizardFlowService } from './services/wizard-flow.service'
import { WorkflowGuard } from './services/wizard-guard.service'
import { SessionQuery } from './store/session.query'
import { SessionStore } from './store/session.store'
import { StepQuery } from './store/step.query'
import { StepStore } from './store/step.store'
import { TransactionQuery } from './store/transaction.query'
import { TransactionStore } from './store/transaction.store'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTooltipModule,
    MatListModule,
    ScrollbarModule,
    PaymentBankWidgetModule,
    PaymentCardWidgetModule,
    PaymentCoinsWidgetModule,
    TableReferralsModule,
    TableTransactionsModule,
    ContractSigningModule,
    SharedModule,
    RouterModule.forChild(adminRoutes),
  ],
  declarations: [
    AdminComponent,
    BuyTokenComponent,
    EarnTokensComponent,
    TransactionsComponent,
    SettingsComponent,
    SendCoinsComponent,
    SupportComponent,
    TransferComponent,
    DialogAddressComponent,
    DialogAddressModalComponent,
    NavBarPaginationComponent,
    FooterComponent,
    DialogTwofaComponent,
    DialogTwofaEmailComponent,
    ProfileComponent,
    CardComponent,
    WizardPersonalDetailsComponent,
    WizardVerifyIdComponent,
    WizardVerifyAddressComponent,
    PersonalDetailsInfoComponent,
    PersonalDetailsAddressComponent,
    PersonalDetailsDetailsComponent,
    PersonalDetailsWalletComponent,
    VerifyAddressTypeComponent,
    VerifyAddressUploadComponent,
    VerifyAddressCheckReadabilityComponent,
    VerifyIdentityTypeComponent,
    VerifyIdentityUploadIdComponent,
    VerifyIdentityUploadSelfieComponent,
    VerifyIdentityApprovalComponent,
    WizardNavComponent,
    ImagePreviewDialogComponent,
    FileValidator,
    ProgressBarComponent,
    DialogInfoComponent,
    VerifyPrivateSaleApplicationComponent,
  ],
  entryComponents: [
    DialogAddressModalComponent,
    DialogTwofaComponent,
    DialogTwofaEmailComponent,
    PersonalDetailsInfoComponent,
    ImagePreviewDialogComponent,
    DialogInfoComponent,
  ],
  providers: [
    WizardFlowService,
    WorkflowGuard,
    StepStore,
    StepQuery,
    SessionStore,
    SessionQuery,
    TransactionQuery,
    TransactionStore,
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  exports: [AdminComponent],
})
export class AdminModule {}
