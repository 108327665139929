import { Observable } from 'rxjs'

import { DataSource } from '@angular/cdk/collections'
// 3rd party modules
import { Component, OnInit } from '@angular/core'
// Models
import { ReferralsInterface } from '@app/shared/models/referrals.model'
// Services
import { ReferralsService } from '@app/shared/services/referrals.service'

@Component({
  selector: 'vr-table-referrals',
  templateUrl: './table-referrals.component.html',
  styleUrls: ['./table-referrals.component.scss'],
})
export class TableReferralsComponent implements OnInit {
  displayedColumns = ['date', 'refcode', 'tokens']
  dataSource = new ReferralsDataSource(this.referrals)
  timestamp

  constructor(private referrals: ReferralsService) {}

  ngOnInit() {
    /* tslint:disable:no-bitwise */
    this.timestamp = (new Date().getTime() / 1000) | 0
  }
}

export class ReferralsDataSource extends DataSource<any> {
  constructor(private referrals: ReferralsService) {
    super()
  }

  connect(): Observable<ReferralsInterface[]> {
    if (this.referrals.getLastUpdate()) {
      return Observable.create(observer => {
        observer.next(this.referrals.get())
        observer.complete()
      })
    }

    return this.referrals.sync()
  }

  disconnect() {}
}
