import { Routes } from '@angular/router'
import { NoAuthGuard } from '@app/core/auth/guards/no-auth.guard'

import { Auth2faComponent } from './auth2fa/auth2fa.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { LoginPageComponent } from './login-page/login-page.component'
import { RegisterPageComponent } from './register-page/register-page.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { ValidateComponent } from './validate/validate.component'

export const authPageRoutes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'register',
    component: RegisterPageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'resetpassword/:id',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth2fa',
    component: Auth2faComponent,
  },
  {
    path: 'validate',
    component: ValidateComponent,
  },
]
