import { Observable } from 'rxjs'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BackendService } from '@app/shared/services/backend.service'
import { environment } from '@env/environment'

import { GgcoinapiService } from '../../../shared/services/ggcoinapi.service'
// import { noUndefined } from '@angular/compiler/src/util';
// import { Router } from '@angular/router';
import { JwtService } from '../../../shared/services/jwt.service'

@Injectable()
export class AuthService {
  protected account_type: string

  constructor(
    private http: HttpClient,
    // private router: Router,
    private jwt: JwtService,
    private backend: BackendService,
    private ggcoinapiService: GgcoinapiService
  ) {}

  isAuth(): boolean {
    return true
  }

  setAccountType(type: string): void {
    this.account_type = type
  }

  authenticate(account_type: string, authData): Observable<object> {
    this.account_type = account_type

    // if (account_type === 'GINGR') {
    //   return this.http.post(
    //     environment.ggcoinUrl + '/rest/login',
    //     // environment.kycUrl + '/login',
    //     // environment.gingr_login + '/login',
    //     authData,
    //     this.setHeaders()
    //   )
    // }

    return this.http.post(
      environment.ggcoinUrl + '/rest/login',
      authData,
      this.setHeaders()
    )
  }

  authenticateWithToken(token: string): Observable<object> {
    const authData = {
      token,
    }
    return this.http.post(
      environment.coinUrl + '/rest/user/loginWithRememberToken',
      authData,
      this.setHeaders()
    )
  }

  logout(): Observable<object> {
    this.jwt.destroyToken()
    this.backend.reset()

    if (this.account_type === 'GINGR') {
      return this.http.post(
        environment.gingr_login + '/logout',
        null,
        this.setHeaders()
      )
    }

    return this.http.post(
      environment.coinUrl + '/rest/user/logout',
      null,
      this.setHeaders()
    )
  }

  finishAuth(token, user_id): void {
    if (token !== null) {
      localStorage.setItem('user_id', user_id)
      this.jwt.saveToken(token)
    }
  }

  resendVerificationEmail(email: string) {
    this.ggcoinapiService
      .post('/resend-verification-email/', { email })
      .subscribe()
  }

  private setHeaders(): any {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-TOKEN': 'authorization (JWT Token)',
    }
    return new HttpHeaders(headersConfig)
  }

  getToken() {
    return this.jwt.getToken()
  }

  getUserId() {
    return localStorage.getItem('user_id')
  }

  setRememberToken(token: string) {
    localStorage.setItem('remember_token', token)
  }

  getRememberToken(): string {
    return localStorage.getItem('remember_token')
  }

  removeRememberToken() {
    localStorage.removeItem('remember_token')
  }
}
