import { BehaviorSubject, Observable } from 'rxjs'

import { Injectable } from '@angular/core'

@Injectable()
export class JwtService {
  public kyc_token = new BehaviorSubject<any>([])
  public ggcoin_token = new BehaviorSubject<any>([])

  getToken(): string {
    const token = window.localStorage['token']
    return typeof token !== 'undefined' ? token : null
  }

  getKycToken(): string {
    const token = window.localStorage['kyc_token']
    return typeof token !== 'undefined' ? token : null
  }

  getAccountType(): string {
    const token = window.localStorage['account_type']
    return typeof token !== 'undefined' ? token : null
  }

  saveToken(token: string) {
    window.localStorage['token'] = token
    this.ggcoin_token.next(token)
  }

  saveKycToken(token: string) {
    window.localStorage['kyc_token'] = token
    this.kyc_token.next(token)
  }

  destroyToken() {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('account_type')
  }
}
