import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'vr-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() title: string
  @Input() subtitle: string
  @Input() icon: string
  @Input() isMatIcon: boolean = true
  @Input() footer: string
  @Input() userStatus: number
  @Input() fileStatus?: number
  @Input() type: number

  constructor() {}

  ngOnInit() {}
}
