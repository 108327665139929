import { Observable } from 'rxjs'

import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'

import { JwtService } from '../../../shared/services/jwt.service'

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private jwt: JwtService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.jwt.getToken()
    if (token === undefined || token === null || token === '') {
      return true
    } else {
      this.router.navigateByUrl('/admin/profile')
      return false
    }
  }
}
