import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Errors } from '@app/shared/models/errors.model'
import { RegUserClient } from '@app/shared/models/register.model'
import { UserService } from '@app/shared/services/user.service'

import { AUTH_TRANSITION } from '../../app.animation'

@Component({
  selector: 'vr-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [...AUTH_TRANSITION],
  host: { '[@routeTransition]': '' },
  providers: [RegUserClient, UserService],
})
export class LoginPageComponent implements OnInit {
  email: string
  password: string

  captchaResponse: string
  loginForm: FormGroup
  submittingForm: false
  validators

  isSubmitting = false
  errors: Errors = new Errors()

  constructor(public snackBar: MatSnackBar, public regClient: RegUserClient) {}

  ngOnInit() {}
}
