import { map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'

import { IStep, IStepState, StepStore } from './step.store'

@Injectable()
export class StepQuery extends QueryEntity<IStepState, IStep> {
  constructor(protected store: StepStore) {
    super(store)
  }
}
