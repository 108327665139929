import { ToasterService } from 'angular2-toaster'
import { map } from 'rxjs/operators'

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SessionQuery } from '@app/admin/store/session.query'
import { IUser } from '@app/admin/store/session.store'
import { BackendService } from '@app/shared/services/backend.service'

@Component({
  selector: 'vr-dialog-twofa',
  templateUrl: './dialog-twofa.component.html',
  styleUrls: ['./dialog-twofa.component.scss'],
})
export class DialogTwofaComponent implements OnInit {
  @ViewChild('secretcanvas') canvas: ElementRef
  @ViewChild('backupcanvas') canvas2: ElementRef
  context: CanvasRenderingContext2D
  context2: CanvasRenderingContext2D

  isLinear = false
  // firstFormGroup: FormGroup;
  // secondFormGroup: FormGroup;
  auth2fa_form: FormGroup

  qr_code: string
  code: string = '-'
  user: IUser

  constructor(
    public dialogRef: MatDialogRef<DialogTwofaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private backend: BackendService,
    private toasterService: ToasterService,
    private sessionQuery: SessionQuery
  ) {}

  ngOnInit() {
    const session = this.sessionQuery.select()
    session
      .pipe(
        map((res: any) => {
          this.user = res.user
        })
      )
      .subscribe()

    this.auth2fa_form = this._formBuilder.group({
      auth2fa_verify: [this.data.settings.auth2fa_type, Validators.required],
    })

    this.qr_code = this.data.settings.qr_code
    this.code = this.data.settings.code
  }

  onNoClick(): void {
    this.dialogRef.close({ twofaEnabled: false })
  }

  cancel(): void {
    this.dialogRef.close({ twofaEnabled: false })
  }

  verify2fa() {
    this.backend
      .test2fa(this.user.email, this.auth2fa_form.controls.auth2fa_verify.value)
      .subscribe((res: any) => {
        const val = this.auth2fa_form.controls.auth2fa_verify.value
        if (res.status === 200) {
          // this.toasterService.pop(
          //   'success',
          //   '2FA Enabled',
          //   'You can now log in to your account using 2FA'
          // )

          this.dialogRef.close({
            message: 'Verify',
            val,
            twofaEnabled: true,
          })
        } else {
          this.toasterService.pop(
            'error',
            'SOMETHING WENT WRONG',
            '2fa verification failed'
          )
        }
      })
  }
}
