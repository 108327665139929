import { Observable, Subscription } from 'rxjs'
import Scrollbar from 'smooth-scrollbar'

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MediaChange } from '@angular/flex-layout'
import { NavigationEnd, Router } from '@angular/router'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'

import { ScrollbarComponent } from '../scrollbar/scrollbar.component'
import { MediaReplayService } from '../utils/media-replay.service'

@Component({
  selector: 'vr-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, OnDestroy {
  scrollbar: Scrollbar

  layout = 'beta' // Layout

  isMobile: boolean
  wizardEnabled: boolean = false

  @ViewChild('scrollContainer')
  scrollContainer: ScrollbarComponent

  buyNowToolbarVisible = true

  private _mediaSubscription: Subscription
  private _routerEventsSubscription: Subscription

  constructor(
    private mediaReplayService: MediaReplayService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private wizardFlow: WizardFlowService
  ) {}

  ngOnInit() {
    this._mediaSubscription = this.mediaReplayService.media$.subscribe(
      (change: MediaChange) => {
        const isMobile = change.mqAlias === 'xs' || change.mqAlias === 'sm'

        this.isMobile = isMobile
        this.cd.markForCheck()
      }
    )

    this.wizardFlow.wizardEnabled$.subscribe(res => {
      this.wizardEnabled = res
    })
  }

  onActivate(): void {
    if (this.scrollbar) {
      this.scrollbar.setPosition(0, 0)
    } else if (this.scrollContainer && this.scrollContainer.element) {
      this.scrollbar = Scrollbar.get(this.scrollContainer.element.nativeElement)
      this.scrollbar.setPosition(0, 0)
    }
  }

  ngOnDestroy() {
    this._mediaSubscription.unsubscribe()
    // this._routerEventsSubscription.unsubscribe();
    this.cd.detach()
  }
}
