import { Component, OnInit } from '@angular/core'
import { BackendService } from '@app/shared/services/backend.service'

// import { Router } from '@angular/router';
import { AUTH_TRANSITION } from '../../app.animation'

@Component({
  selector: 'vr-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [...AUTH_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class ForgotPasswordComponent implements OnInit {
  email: string
  sent: boolean = false

  constructor(
    // private router: Router,
    private backend: BackendService
  ) {}

  ngOnInit() {}

  send() {
    const that = this

    this.backend.requestForgotPassword(this.email).subscribe(data => {
      that.sent = true
    })
  }
}
