import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import {
  HttpClient,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
import { ApiService } from '@app/shared/services/api.service'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'
import { environment } from '@env/environment'

import { UserKyc, UserKycAddress } from '../../admin/models/userKyc.model'
import {
  WizAddress,
  WizardAddress,
  WizardId,
  WizardPersonal,
  WizId,
  WizPersonal,
  WizSelfie,
  WizWallet,
} from '../../admin/models/wizardData.model'
import { STEPS } from '../../admin/models/wizardFlow.model'
import { WizardFlowService } from '../../admin/services/wizard-flow.service'
import { SessionQuery } from '../../admin/store/session.query'

const url = environment.kycUrl + '/file/upload'

@Injectable({
  providedIn: 'root',
})
export class WizardDataService {
  private wizardPersonal$ = new Subject<WizardPersonal>()
  public wizardPersonal = this.wizardPersonal$.asObservable()

  private wizardAddress$ = new Subject<WizardAddress>()
  public wizardAddress = this.wizardAddress$.asObservable()

  private wizardId$ = new Subject<WizardId>()
  public wizardId = this.wizardId$.asObservable()

  private isWizPersonalValid: boolean = false
  private isWizAddressValid: boolean = false
  private isWizIdValid: boolean = false
  private isWizSelfieValid: boolean = false

  private stepsComplete = new BehaviorSubject<number>(0)
  public stepsComplete$ = this.stepsComplete.asObservable()

  public currentUser = new BehaviorSubject<any>(null)
  public currentUser$ = this.currentUser.asObservable()

  // private uStatus$ = new Subject<any>()
  // public uStatus = this.uStatus$.asObservable()
  //
  // private aStatus$ = new Subject<any>()
  // public aStatus = this.aStatus$.asObservable()
  //
  // private idStatus$ = new Subject<any>()
  // public idStatus = this.idStatus$.asObservable()

  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private wizardFlowService: WizardFlowService,
    private localStorage: LocalStorageService,
    private sessionQuery: SessionQuery,
    private ggcoinapiService: GgcoinapiService
  ) {}

  /**
   * Wizard Forms: get exisiting user data
   */
  getPersonalFormData() {
    this.apiService
      .getKycApi('/currentUser')
      .pipe(map(res => res.result))
      .subscribe(
        (res: UserKyc) => {
          console.log('get personal form', res)

          if (res.addresses && !res.addresses.length) {
            const obj: UserKycAddress = {
              country: '',
              address: '',
              address_2: '',
              zip_code: '',
              city: '',
            }

            res.addresses.push(obj)
          }

          this.wizardPersonal$.next({
            user_id: res.id,
            firstName: res.first_name,
            lastName: res.last_name,
            date_of_birth: res.date_of_birth,
            country: res.addresses[0].country,
            address1: res.addresses[0].address,
            address2: res.addresses[0].address_2,
            zip: res.addresses[0].zip_code,
            city: res.addresses[0].city,
            phoneNumber: res.phone_number,
            phoneNumberPrefix: res.phone_number_prefix,
            //@ts-ignore
            wallet:
              res.ethAddresses.length > 0
                ? res.ethAddresses[0].public_address
                : '',
          })

          this.wizardAddress$.next({
            file: res.files[0],
          })

          this.wizardId$.next({
            identity_front: res.files[1],
            selfie: res.files[3],
          })

          // SEARCHTAG: Adding kyc values to the user object
          this.currentUser$.subscribe(user => {
            let update_required = false

            if (!user) return

            if (!user.first_name && res.first_name) {
              user.first_name = res.first_name
              update_required = true
            }

            if (!user.last_name && res.last_name) {
              user.last_name = res.last_name
              update_required = true
            }

            if (!user.addresses && res.addresses) {
              user.addresses = res.addresses
              update_required = true
            }

            if (user.compliance_status != res.compliance_status) {
              user.compliance_status = res.compliance_status
              update_required = true
            }

            if (update_required) this.currentUser.next(user)
          })

          //this.currentUser.next(res)
          this.localStorage.setItem('user', res)
        },
        err => {
          if (this.localStorage.getItem('user')) {
            this.localStorage.getItem('user').subscribe(res => {
              this.setupForms(res)
              this.currentUser.next(res)
            })
          }
        }
      )
  }

  setupForms(res: any) {
    if (res.addresses && !res.addresses.length) {
      const obj: UserKycAddress = {
        country: '',
        address: '',
        address_2: '',
        zip_code: '',
        city: '',
      }

      res.addresses.push(obj)
    }

    this.wizardPersonal$.next({
      user_id: res.id,
      firstName: res.first_name,
      lastName: res.last_name,
      date_of_birth: res.date_of_birth,
      country: res.addresses[0].country,
      address1: res.addresses[0].address,
      address2: res.addresses[0].address_2,
      zip: res.addresses[0].zip_code,
      city: res.addresses[0].city,
      phoneNumber: res.phone_number,
      phoneNumberPrefix: res.phone_number_prefix,
      wallet: res.wallet,
    })

    this.wizardAddress$.next({
      file: res.files[0],
    })

    this.wizardId$.next({
      identity_front: res.files[1],
      selfie: res.files[3],
    })
  }

  /**
   * Wizard Personal Details: get personal details data
   */
  getPersonal(): WizPersonal {
    let personal: WizPersonal = new WizPersonal()

    this.wizardPersonal.subscribe(res => {
      // const date = res.date_of_birth.split('-')

      personal = {
        firstName: res.firstName,
        lastName: res.lastName,
        date_of_birth: res.date_of_birth,
        phoneNumber: res.phoneNumber,
        phoneNumberPrefix: res.phoneNumberPrefix,
      }
    })

    return personal
  }

  /**
   * Wizard Personal Details: set pseronal details data
   * @param data
   * @param user
   */
  setPersonal(data: WizPersonal, user: any) {
    this.isWizPersonalValid = true
    this.wizardFlowService.validateStep(STEPS.details)

    const obj = {
      email: user.email,
      first_name: data.firstName,
      last_name: data.lastName,
      date_of_birth: data.date_of_birth,
      phone_number: data.phoneNumber,
      phone_number_prefix: data.phoneNumberPrefix,
    }

    // WILL BE USED FOR OFFLINE CAPABILITES
    this.apiService.putKycApi('/users/' + user.user_id, obj).subscribe(
      res => {
        console.log('success')
      },
      err => {
        console.warn(err)
        // this.localStorage.setItem('user', obj).subscribe(() => {})
      },
      () => {
        // this.stepsComplete.next(+1)
      }
    )
  }

  /**
   * Get the selected ID document type
   */
  getAddressType(): Observable<any> {
    return this.apiService.getKycApi('/getAddressType').pipe(
      catchError(err => of(err)),
      map(res => res)
    )
  }

  /**
   * Update the selected address document type
   */
  updateAddressType(docType: string): Observable<string> {
    const type = { doc_type: docType }

    return this.apiService.postKycApi('/updateAddressType', type).pipe(
      catchError(err => {
        return of(err)
      }),
      map(res => {
        return res
      })
    )
  }

  /**
   * Wizard Personal Details: get address data
   */
  getAddress(): WizAddress {
    let address: WizAddress = new WizAddress()

    this.wizardPersonal.subscribe(res => {
      address = {
        user_id: res.user_id,
        country: res.country,
        address1: res.address1,
        address2: res.address2,
        zip: res.zip,
        city: res.city,
      }
    })

    return address
  }

  /**
   * Wizard Personal Details: update address data
   * @param data
   */
  updateAddress(data: WizAddress) {
    this.isWizAddressValid = true

    this.wizardFlowService.validateStep(STEPS.address)

    // this.localStorage.setItem('wiz-personal', this.wizardPersonal);

    const object = {
      user_id: data.user_id,
      country: data.country,
      address: data.address1,
      address_2: data.address2,
      zip_code: data.zip,
      city: data.city,
    }

    this.apiService.putKycApi('/address/' + data.user_id, object).subscribe(
      res => {
        console.log('success')
      },
      err => {
        console.log(err)
      }
    )
  }

  /**
   * Wizard Personal Details: set address data
   * @param data
   */
  setAddress(data: WizAddress): Observable<any> {
    this.isWizAddressValid = true
    this.wizardFlowService.validateStep(STEPS.address)

    const object = {
      user_id: data.user_id,
      country: data.country,
      address: data.address1,
      address_2: data.address2,
      zip_code: data.zip,
      city: data.city,
    }

    const result = []
    for (const o in object) {
      if (object[o] !== '') {
        result.push(o)
      }
    }

    return this.apiService.postKycApi('/address', object).pipe(
      catchError(err => {
        return of(false)
        // WILL BE USED FOR OFFLINE CAPABILITES
        // this.localStorage.setItem('wizard-address', object).subscribe(() => {})
      }),
      map(res => {
        return true
      })
    )
  }

  /**
   * Wizard Verify Address: get address data
   */
  getVerifyAddress(): WizardAddress {
    let address: WizardAddress = new WizardAddress()

    this.wizardAddress.subscribe(res => {
      address = {
        file: res.file,
      }
    })

    return address
  }

  /**
   * Wizard Verify Address: set address data
   * @param data
   */
  setVerifyAddress(data: Set<File>, type: string, docType: string) {
    this.isWizAddressValid = true

    this.wizardFlowService.validateStep(STEPS.upload)

    return this.upload(data, type, docType)
  }

  /**
   * Wizard Wallet: get wallet data
   */
  getWallet(): WizWallet {
    let wallet: WizWallet = new WizWallet()

    this.wizardPersonal.subscribe(res => {
      wallet = {
        user_id: res.user_id,
        wallet: res.wallet,
      }
    })

    return wallet
  }

  /**
   * Wizard Wallet: update wallet data
   * @param data
   */
  updateWallet(data: WizWallet) {
    this.isWizAddressValid = true

    this.wizardFlowService.validateStep(STEPS.wallet)

    // this.localStorage.setItem('wiz-personal', this.wizardPersonal);

    const object = {
      user_id: data.user_id,
      wallet: data.wallet,
    }

    this.apiService.putKycApi('/ethAddress/' + data.user_id, object).subscribe(
      res => {
        console.log('success')
      },
      err => {
        console.log(err)
      }
    )
  }

  /**
   * Wizard Wallet: set wallet data
   * @param data
   */
  setWallet(data: WizWallet): Observable<any> {
    this.isWizAddressValid = true
    this.wizardFlowService.validateStep(STEPS.wallet)

    const object = {
      user_id: data.user_id,
      wallet: data.wallet,
    }

    const result = []
    for (const o in object) {
      if (object[o] !== '') {
        result.push(o)
      }
    }

    return this.apiService.postKycApi('/ethAddress', object).pipe(
      catchError(err => {
        return of(false)
        // WILL BE USED FOR OFFLINE CAPABILITES
        // this.localStorage.setItem('wizard-address', object).subscribe(() => {})
      }),
      map(res => {
        return true
      })
    )
  }

  /**
   * Update the selected ID document type
   */
  updateIdType(docType: string): Observable<string> {
    const type = { doc_type: docType }

    return this.apiService.postKycApi('/updateIdType', type).pipe(
      catchError(err => {
        return of(err)
      }),
      map(res => {
        return res
      })
    )
  }

  /**
   * Get the selected ID document type
   */
  getIdType(): Observable<any> {
    return this.apiService.getKycApi('/getIdType').pipe(
      catchError(err => of(err)),
      map(res => res)
    )
  }

  /**
   * Wizard Verify ID: get ID data
   */
  getVerifyId(): WizId {
    let id: WizId = new WizId()

    this.wizardId.subscribe(res => {
      id = {
        identity_front: res.identity_front,
        identity_back: res.identity_back,
      }
    })

    return id
  }

  /**
   * Wizard Verify ID: set ID data
   * @param data
   */
  setVerifyId(data: Set<File>, type: string, docType: string) {
    this.isWizIdValid = true
    // this.wizardId.identity_front = data.identity_front;
    // this.wizardId.identity_back = data.identity_back;

    this.wizardFlowService.validateStep(STEPS.uploadId)

    return this.upload(data, type, docType)
  }

  /**
   * Wizard Verify ID: get selfie data
   */
  getSelfie(): WizSelfie {
    let selfie: WizSelfie = new WizSelfie()

    this.wizardId.subscribe(res => {
      selfie = {
        selfie: res.selfie,
      }
    })

    return selfie
  }

  /**
   * Wizard Verify ID: set selfie data
   * @param data
   */
  setSelfie(data: Set<File>, type: string, dataType: string) {
    this.isWizSelfieValid = true
    // this.wizardId.selfie = data.selfie;

    this.wizardFlowService.validateStep(STEPS.uploadSelfie)

    return this.upload(data, type, dataType)
  }

  getFormData(wizard: string) {
    switch (wizard) {
      case 'personal':
        const wizP = this.localStorage.getItem('wiz-personal')
        if (wizP) {
          this.wizardPersonal = wizP
        }
        return this.wizardPersonal
      case 'verify-address':
        const wizA = this.localStorage.getItem('wiz-address')
        if (wizA) {
          this.wizardAddress = wizA
        }
        return this.wizardAddress
      case 'verify-id':
        const wizI = this.localStorage.getItem('wiz-id')
        if (wizI) {
          this.wizardPersonal = wizI
        }
        return this.wizardId
    }
  }

  /**
   * get a file
   */
  getFile() {
    this.apiService.getKycApi('/file/download').subscribe(res => {})
  }

  getImage(id: string): Observable<Blob> {
    return this.http.get(environment.kycUrl + '/file/download/' + id, {
      responseType: 'blob',
    })
  }

  /**
   * reset the wizard form data
   */
  // resetFormData(): WizardPersonal {
  //   this.wizardFlowService.resetSteps();
  //   this.wizardPersonal.clear();
  //   this.isWizPersonalValid = this.isWizAddressValid = false;
  //   return this.wizardPersonal;
  // }

  /**
   * check if the form is valid
   */
  isFormValid() {
    return this.isWizPersonalValid && this.isWizAddressValid
  }

  /**
   * File uploader script
   * @param files
   */
  public upload(
    files: Set<File>,
    type: string,
    docType: string
  ): { [key: string]: Observable<number> } {
    const status = {}
    const t = type

    let uploaded = false

    const uploadFiles = (user, files) => {
      let i = 0
      files.forEach(file => {
        const formData: FormData = new FormData()
        i++
        if (i === 1) {
          formData.append('type', type)
          formData.append('docType', docType)
        } else {
          const type2 = parseInt(type) + 1
          formData.append('type', type2.toString())
          formData.append('docType', docType)
        }

        formData.append('file', file, file.name)

        const req = new HttpRequest('POST', url, formData, {
          reportProgress: true,
        })

        const progress = new Subject<number>()

        this.http.request(req).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round((100 * event.loaded) / event.total)
              progress.next(percentDone)
            } else if (event instanceof HttpResponse) {
              progress.complete()
            }
          },
          err => {
            this.localStorage.setItem('wizard-upload-' + type, formData)
          },
          () => {
            // this.stepsComplete.next(+1)
          }
        )

        status[file.name] = {
          progress: progress.asObservable(),
        }
      })
    }

    //TODO: possible memory leak
    const subscription = this.currentUser$.subscribe(user => {
      if (!uploaded) {
        uploaded = true
        uploadFiles(user, files)
      } else {
        subscription.unsubscribe()
      }
    })

    return status
  }

  /**
   * check if personal details has been completed
   * happens on the API and if is complete sets compliance status to 2
   */
  isPersonalComplete(): Observable<any> {
    let status: boolean = false

    return this.apiService.getKycApi('/isPersonalComplete').pipe(
      catchError(err => {
        return throwError('not complete')
      }),
      map(res => {
        return (status = res)
      })
    )
  }

  /**
   * get the status of verify address
   */
  getAddressStatus(): Observable<number> {
    const observable = Observable.create(observer => {
      this.apiService.getKycApi('/getAddressStatus').subscribe(res => {
        observer.next(res.result)
        observer.complete()
      })
    })

    return observable
  }

  /**
   * get the status of verify ID
   */
  getIdStatus(): Observable<number> {
    const observable = Observable.create(observer => {
      this.apiService.getKycApi('/getIdStatus').subscribe(res => {
        observer.next(res.result)
        observer.complete()
      })
    })

    return observable
  }

  // wizardCompleted(): Observable<any> {
  //   return this.ggcoinapiService
  //     .post('/wizardCompleted', { complete: true })
  //     .pipe(map(res => res))
  // }

  /**
   * Check if the wizard is complete
   * BECOMING OBSOLETE - WILL EVENTUALLY REMOVE
   */
  isWizardComplete(): Observable<any> {
    return Observable.create(observer => {
      const personal = this.isPersonalComplete()
      const address = this.getAddressStatus()
      const id = this.getIdStatus()

      forkJoin([personal, address, id]).subscribe(res => {
        const pStatus = res[0].status
        const aStatus = res[1]
        const iStatus = res[2]

        if (
          pStatus === 'success' &&
          aStatus !== 2 &&
          aStatus !== null &&
          iStatus !== 2 &&
          iStatus !== null
        ) {
          observer.next(true)
          observer.complete()
        } else {
          observer.next(false)
          observer.complete()
        }
      })
    })
  }
}
