import { ToasterService } from 'angular2-toaster'
import { Observable, Subscription } from 'rxjs'

import { DataSource } from '@angular/cdk/collections'
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { MediaObserver } from '@angular/flex-layout'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { TransactionsInterface } from '@app/shared/models/transactions.model'
import { BackendService } from '@app/shared/services/backend.service'
import { JwtService } from '@app/shared/services/jwt.service'
import { StateService } from '@app/shared/services/state.service'
import { TransactionsService } from '@app/shared/services/transactions.service'

@Component({
  selector: 'vr-table-transactions',
  templateUrl: './table-transactions.component.html',
  styleUrls: ['./table-transactions.component.scss'],
})
export class TableTransactionsComponent implements OnInit {
  displayedColumns = [
    'timestamp',
    'token_amount',
    'payment_currency',
    'amount',
    'euro_amount',
    'status',
    'invoice',
    'cancel',
  ]
  dataSource = new TransactionsDataSource(this.transactions)
  timestamp

  watcher: Subscription
  activeMediaQuery = ''

  token: string = ''

  constructor(
    // private router: Router,
    private cd: ChangeDetectorRef,
    private transactions: TransactionsService,
    private backend: BackendService,
    private state: StateService,
    public dialog: MatDialog,
    public mediaObserver: MediaObserver,
    private jwt: JwtService
  ) {}

  ngOnInit() {
    this.timestamp = Math.floor(Date.now() / 1000)

    this.token = this.jwt.getToken()
  }

  openDialog(txn_id): void {
    const dialogRef = this.dialog.open(TransactionCancelComponent, {
      width: '300px',
      height: 'auto',
      data: {},
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('hide ' + txn_id);
        this.hide(txn_id)
      }
    })
  }

  rowSelected(id: number) {
    this.state.set('current_transaction_id', id)

    // set the dialog width according to viewport size
    if (this.mediaObserver.isActive('xs')) {
      const dialogRef = this.dialog.open(TransactionSingleComponent, {
        width: '100%',
      })
    } else if (this.mediaObserver.isActive('sm')) {
      const dialogRef = this.dialog.open(TransactionSingleComponent, {
        width: '75%',
      })
    } else {
      const dialogRef = this.dialog.open(TransactionSingleComponent, {
        width: '50%',
      })
    }
  }

  cancel(event, txn_id) {
    event.stopPropagation()
    this.openDialog(txn_id)
  }

  hide(txn_id: string) {
    this.backend.hideTransaction(txn_id).subscribe(result => {
      // console.log('hid ' + txn_id);
      this.transactions.remove(txn_id)
      this.dataSource = new TransactionsDataSource(this.transactions)
      this.cd.detectChanges()
    })
  }
}

export class TransactionsDataSource extends DataSource<any> {
  constructor(private transactions: TransactionsService) {
    super()
  }

  connect(): Observable<TransactionsInterface[]> {
    if (this.transactions.getLastUpdate()) {
      return Observable.create(observer => {
        observer.next(this.transactions.get())
        observer.complete()
      })
    }

    return this.transactions.sync()
  }

  disconnect() {}
}

@Component({
  selector: 'vr-transaction-dialog',
  templateUrl: './table-transactions-cancel.component.html',
  styles: ['.color-accent { color: #FFC62D }'],
})
export class TransactionCancelComponent {
  constructor(
    public dialogRef: MatDialogRef<TransactionCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}

//
@Component({
  selector: 'vr-transaction-single',
  templateUrl: './table-transactions-single.component.html',
  styleUrls: ['./table-transactions-single.component.scss'],
})
export class TransactionSingleComponent implements OnInit {
  public id: object

  code: string
  currency: string = ''
  amount: string = '0'
  address: string
  expired: number
  expires: number
  isCopiedAddress = false
  status: string
  timestamp

  constructor(
    public dialogRef: MatDialogRef<TransactionSingleComponent>,
    private router: Router,
    private state: StateService,
    private cd: ChangeDetectorRef,
    private backend: BackendService,
    private toasterService: ToasterService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    iconRegistry.addSvgIcon(
      'warning-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/warning-icon.svg')
    )
  }

  ngOnInit() {
    this.timestamp = Math.floor(Date.now() / 1000)

    const trans_id = this.state.get('current_transaction_id')

    if (!trans_id) {
      this.router.navigateByUrl('/admin/transactions')
      return
    }

    const transaction = this.state.get('current_transaction')
    if (typeof transaction === 'object' && transaction != null) {
      this.state.remove('current_transaction')

      // console.log(transaction);

      this.code = transaction.txn_id
      this.currency = transaction.payment_currency
      this.amount = transaction.payment_amount
      this.address = transaction.payment_address
      this.status = transaction.status
      this.expires = transaction.expires
      this.cd.markForCheck()
    } else {
      // console.log('Trans id', trans_id);
      this.backend.getTransaction(trans_id).subscribe((transaction: any) => {
        // console.log(transaction);

        this.code = transaction.txn_id
        this.currency = transaction.payment_currency
        this.amount = transaction.payment_amount
        this.address = transaction.payment_address
        this.status = transaction.status
        this.expires = transaction.expires
        this.cd.markForCheck()
      })
    }
  }

  isCopied() {
    this.isCopiedAddress = true
    this.toasterService.pop('success', 'Address copied')
  }
}
