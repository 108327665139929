import { Component, OnInit } from '@angular/core'
import { WizardDataService } from '@app/shared/services/wizard-data.service'

@Component({
  selector: 'vr-wizard-verify-id',
  templateUrl: './wizard-verify-id.component.html',
  styleUrls: ['./wizard-verify-id.component.scss'],
})
export class WizardVerifyIdComponent implements OnInit {
  file: any

  constructor(private wizardDataService: WizardDataService) {}

  ngOnInit() {
    // this.file = this.wizardDataService.getFile();
    // console.log('file', this.file);
  }
}
