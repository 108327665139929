import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'

@Injectable()
export class UserService {
  constructor(private router: Router, public http: HttpClient) {}

  /**
   * @param params
   * @returns {Observable<Object>}
   */
  getList(params): Observable<Object> {
    const queryParams = {
      offset: params.skip,
      limit: params.range,
      sortBy: params.sort
        ? { name: params.sort, order: params.direction }
        : null,
      where: params.where ? params.where : [],
    }
    if (params.search) {
      queryParams.where.push({ email: params.search })
    }
    if (params.verified !== null && params.verified !== undefined) {
      queryParams.where.push({ verified: params.verified })
    }
    if (params.active !== null && params.active !== undefined) {
      queryParams.where.push({ active: params.active })
    }
    return this.http.post(
      environment.gingr_api + 'admin/listUserLimit',
      queryParams
    )
  }

  /**
   * @returns {Observable<Object>}
   */
  getListToVerify(): Observable<Object> {
    return this.http.post(
      environment.gingr_api + 'admin/listUserToVerify',
      null
    )
  }

  /**
   * @param username
   * @returns {Observable<Object>}
   */
  sendVerifyEmail(username: String): Observable<Object> {
    const queryParams = { username: username }
    return this.http.post(
      environment.gingr_api + 'admin/sendEmail',
      queryParams
    )
  }

  /**
   * @param {string} username
   * @param {string} emailTitle
   * @param {string} emailText
   * @returns {Observable<Object>}
   */
  sendCustomEmail(
    username: String,
    emailTitle: String,
    emailText: String
  ): Observable<Object> {
    const queryParams = {
      username: username,
      title: emailTitle,
      email: emailText,
    }
    return this.http.post(
      environment.gingr_api + '/admin/sendCustomEmail',
      queryParams
    )
  }
  /**
   *
   * @param username
   * @returns {Observable<Object>}
   */
  sendVerifySms(username: String): Observable<Object> {
    const queryParams = { username: username }
    return this.http.post(environment.gingr_api + 'admin/sendSms', queryParams)
  }

  /**
   * @param {string} username
   * @param {boolean} status
   * @returns {Observable<Object>}
   */
  setStatus(username: String, status: Boolean): Observable<Object> {
    const queryParams = { username: username, active: status }
    return this.http.post(environment.gingr_api + 'admin/active', queryParams)
  }

  /**
   * @param {string} username
   * @returns {Observable<Object>}
   */
  verify(username: String): Observable<Object> {
    const queryParams = { username: username, status: status }
    return this.http.post(environment.gingr_api + 'admin/verify', queryParams)
  }

  getUser(email: String): Observable<Object> {
    const queryParams = { email: email }
    return this.http.post(
      environment.gingr_api + 'admin/selectFullUser',
      queryParams
    )
  }

  /**
   *
   * @param {string} email
   * @returns {Observable<Object>}
   */
  populateConfig(): Observable<Object> {
    return this.http.get(environment.gingr_api + 'config')
  }

  /**
   *
   * @param {Object} user
   * @returns {Observable<Object>}
   */
  updateUser(user: Object): Observable<Object> {
    return this.http.post(environment.gingr_api + 'admin/updateAccount', user)
  }
}
