import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class MediaService {
  constructor(public http: HttpClient) {}

  /**
   * get full list of pending media
   * @returns {Observable<Object>}
   */
  getList(params) {
    const queryParams = {
      offset: params.skip,
      limit: params.range,
      sortBy: params.sort
        ? { name: params.sort, order: params.direction }
        : null,
      where: [],
    }
    if (params.search) {
      queryParams.where.push({ email: params.search })
    }
    if (params.verified !== null && params.verified !== undefined) {
      queryParams.where.push({ verified: params.verified })
    }
    if (params.active !== null && params.active !== undefined) {
      queryParams.where.push({ active: params.active })
    }
    return this.http.post(
      environment.gingr_api + '/admin/listPendingMedia',
      queryParams
    )
  }
  /**
   * @param params
   * @returns {Observable<Object>}
   */
  verifyImage(params): Observable<Object> {
    const queryParams = {
      filename: params.filename,
      state: params.state,
    }
    return this.http.post(
      environment.gingr_api + 'admin/verifyImage',
      queryParams
    )
  }

  declineImage(params): Observable<Object> {
    const queryParams = {
      filename: params.filename,
    }
    return this.http.post(
      environment.gingr_api + 'admin/declineImage',
      queryParams
    )
  }

  /**
   * @param params
   * @returns {Observable<Object>}
   */
  censorImage(params): Observable<Object> {
    const queryParams = {
      filename: params.filename,
      media: params.media,
    }
    return this.http.post(environment.gingr_api + 'admin/censure', queryParams)
  }

  /**
   * @returns {Observable<Object>}
   */
  getPending(): Observable<Object> {
    return this.http.post(
      environment.gingr_api + 'admin/lastPendingMedia',
      null
    )
  }

  last20Pending(): Observable<Object> {
    return this.http.post(
      environment.gingr_api + 'admin/last20PendingMedia',
      null
    )
  }
}
