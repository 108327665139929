import { Observable, of, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { WizAddress } from '@app/admin/models'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { IStep } from '@app/admin/store/step.store'
// import { LocalStorage } from '@ngx-pwa/local-storage'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
// import { FullKycUser, KycUser } from '@app/admin/models/kycUser.model';
import { ApiService } from '@app/shared/services/api.service'
import { BackendService } from '@app/shared/services/backend.service'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'

@Component({
  selector: 'vr-personal-details-address',
  templateUrl: './personal-details-address.component.html',
  styleUrls: ['./personal-details-address.component.scss'],
})
export class PersonalDetailsAddressComponent implements OnInit, OnDestroy {
  address: WizAddress
  isSubmitting: boolean = false
  user: IUser
  saving_address: boolean = false
  public user_id: number
  form: any
  currentStep: number = 2
  steps: any
  private sub: Subscription[] = []

  step$: Observable<IStep[]>
  steps$: Observable<IStep[]>

  saved_address: boolean = false

  constructor(
    private backend: BackendService,
    private cd: ChangeDetectorRef,
    private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService,
    private router: Router,
    private stepQuery: StepQuery,
    private localStorage: LocalStorageService,
    private apiService: ApiService,
    private ggcoinService: GgcoinapiService
  ) {
    // this.sub.push(
    //   /**
    //    * get the current user to retrieve id
    //    */
    //   this.backend.getUser().subscribe(
    //     res => {
    //       this.user = res;
    //       this.address.user_id = this.user.result.id;
    //     },
    //     err => {
    //       throw err;
    //     }
    //   )
    //   /**
    //    * get the current step
    //    */
    //   // this.wizardFlowService.step$.subscribe(
    //   //   step => {
    //   //     console.log('address step', step);
    //   //     this.currentStep = step;
    //   //   },
    //   //   err => {
    //   //     console.warn(err);
    //   //   }
    //   // ),
    //   // this.wizardFlowService.getCurrentStep().subscribe(
    //   //   step => {
    //   //     console.log('the step', step);
    //   //     this.currentStep = step;
    //   //   },
    //   //   err => {
    //   //     console.warn(err);
    //   //   }
    //   // )
    // );
  }

  ngOnInit() {
    this.wizardDataService.getPersonalFormData()
    this.getAddress()

    this.steps = this.stepQuery.selectAll()
    this.sub.push(
      this.steps.subscribe(res => {
        this.steps = res
      })
    )

    this.sub.push(
      this.backend.getUser().subscribe(
        (res: any) => {
          this.user = res.result
        },
        err => {
          throw err
        }
      )
    )
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Get form data
   */
  getAddress() {
    this.sub.push(
      this.wizardDataService.wizardPersonal.subscribe(res => {
        const address = {
          user_id: res.user_id,
          country: res.country,
          address1: res.address1,
          address2: res.address2,
          zip: res.zip,
          city: res.city,
        }
        this.address = address
        this.cd.detectChanges()
      })
    )
  }

  /**
   * save the form
   * @param form
   */
  save(form: any): Observable<any> {
    if (!form.valid) {
      throw new Error('Invalid form')
    }

    /*this.backend.setCountry(this.address.country).subscribe(res => {
      console.log(res)
    })*/
    return this.wizardDataService.setAddress(this.address)
    // console.log('svd address', this.saved_address)
    // return true
  }

  /**
   * submit the form and navigate to the next step
   * @param form
   */
  submitForm(form: any) {
    const sub = this.save(form).subscribe(res => {
      const step = this.steps[this.currentStep]
      this.wizardFlowService.setCurrentStep(this.currentStep + 1)
      this.router.navigate(['admin/profile/kyc/' + step.path])
      sub.unsubscribe()
    })
  }
}
