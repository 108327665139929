import { ToasterConfig, ToasterService } from 'angular2-toaster'

import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
// import { IUser, SessionStore } from '@app/admin/store/session.store'
import { BackendService } from '@app/shared/services/backend.service'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'
import { ReferralsService } from '@app/shared/services/referrals.service'
import { TransactionsService } from '@app/shared/services/transactions.service'

import { JwtService } from '../../../../shared/services/jwt.service'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'vr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  authForm: FormGroup
  isSubmitting: boolean = false
  error: Error
  message: string = ''
  fail_count: number = 0
  can_disable_2fa: boolean = false
  resendEmail: boolean = false

  userEmail: string

  get email() {
    return this.authForm.get('email')
  }

  get password() {
    return this.authForm.get('password')
  }

  @Input()
  mode: string = 'login'

  public config: ToasterConfig = new ToasterConfig({
    animation: 'flyRight',
    tapToDismiss: true,
    timeout: 5000,
    showCloseButton: true,
    preventDuplicates: true,
  })

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private jwt: JwtService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private backend: BackendService,
    public dialog: MatDialog,
    private referrals: ReferralsService,
    private transactions: TransactionsService,
    private toasterService: ToasterService // private sessionStore: SessionStore
  ) {}

  ngOnInit() {
    if (this.mode === 'login') {
      this.authForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        remember: [false],
      })

      const email = this.route.snapshot.queryParams['email']
      if (email) {
        const validation = this.route.snapshot.queryParams['validation']
      }

      const rem_token = this.auth.getRememberToken()
      if (rem_token) {
        this.auth.authenticateWithToken(rem_token).subscribe((data: any) => {
          if (data.status === 200 && data.result.token !== null) {
            this.auth.setAccountType('LOCAL')
            this.auth.finishAuth(data.result.token, data.result.user.id)
            this.getUserProfile()

            if (data.result.remember_token) {
              this.auth.setRememberToken(data.result.remember_token)
            }
          }
        })
      }
    } else {
      this.authForm = this.formBuilder.group({
        code: ['', Validators.required],
      })

      this.backend.getUser().subscribe((user: any) => {
        this.can_disable_2fa = user._2fa.verified === false

        if (user._2fa.type === 'qr_code') {
          this.message = 'Google Authenticator Code'
        } else if (user._2fa.type === 'email') {
          this.message = 'Sending Verification Code Email'
          this.backend.sendAuthEmail().subscribe(data => {
            this.message = 'Email Verification Code'
          })
        }
      })
    }
  }

  getErrorMessage() {
    return this.authForm.value.email.hasError('required')
      ? 'You must enter a value'
      : this.authForm.value.email.hasError('email')
      ? 'Not a valid email'
      : ''
  }

  getUserProfile() {
    this.backend.getUser().subscribe(
      (user: any) => {
        if (user.status === 200) {
          this.router.navigateByUrl('/admin/profile')
        } else if (user._2fa && user._2fa !== 'DISABLED') {
          this.router.navigateByUrl('/auth/auth2fa')
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  submitForm(account_type) {
    this.isSubmitting = true

    if (this.mode === 'login') {
      this.backend.reset()
      this.referrals.reset()
      this.transactions.reset()

      this.userEmail = this.authForm.value.email

      this.auth.authenticate(account_type, this.authForm.value).subscribe(
        (data: any) => {
          if (
            data.status === 200 &&
            data.result &&
            data.result.token !== undefined &&
            data.result.token !== null &&
            (!data.result.twofa || data.result.twofa == 'DISABLED')
          ) {
            this.auth.setAccountType(account_type)
            this.auth.finishAuth(data.result.token, data.result.user.id)
            this.getUserProfile()

            if (data.result.remember_token) {
              this.auth.setRememberToken(data.result.remember_token)
            }
          } else if (
            data.status === 200 &&
            data.result.twofa &&
            data.result.twofa != 'DISABLED'
          ) {
            this.auth.setAccountType(account_type)
            this.auth.finishAuth(data.result.token, data.result.user.id)

            this.mode = '2fa'
            this.isSubmitting = false
            this.authForm = this.formBuilder.group({
              code: ['', Validators.required],
            })

            this.cd.detectChanges()
          } else if (data.status === 418) {
            this.toasterService.pop(
              'error',
              'Authentication error',
              data.message
            )
            this.resendEmail = true
            this.isSubmitting = false
          } else {
            this.toasterService.pop(
              'error',
              'Authentication error',
              data.message
            )
            this.isSubmitting = false
            this.authForm.reset()
          }
        },
        error => {
          if (account_type === 'LOCAL') {
            this.submitForm('GINGR')
          } else {
            this.isSubmitting = false
            this.toasterService.pop(
              'error',
              'AUTHENTICATION ERROR',
              'Could not log you in. Check your username and password.'
            )
          }
        }
      )
    } else {
      if (this.authForm.value.code.length) {
        this.backend
          .verify2fa(this.userEmail, this.authForm.value.code)
          .subscribe(
            (data: any) => {
              if (data.status === 200) {
                if (
                  data.status === 200 &&
                  data.result &&
                  data.result.kyc_token !== undefined &&
                  data.result.kyc_token !== null &&
                  data.result.ggcoin_token !== undefined &&
                  data.result.ggcoin_token !== null
                ) {
                  this.auth.setAccountType(account_type)
                  this.auth.finishAuth(data.result.token, data.result.user.id)
                  this.getUserProfile()

                  if (data.result.remember_token) {
                    this.auth.setRememberToken(data.result.remember_token)
                  }
                }
                this.router.navigateByUrl('/admin/profile')
              } else {
                this.fail_count++
                this.isSubmitting = false
                this.cd.markForCheck()

                this.toasterService.pop(
                  'error',
                  '2FA AUTHENTICATION FAILED',
                  'Check your code and try again'
                )

                if (this.fail_count >= 3) {
                  this.cancel()
                }
              }
            },
            err => {
              this.isSubmitting = false
              this.toasterService.pop(
                'error',
                '2FA AUTHENTICATION FAILED',
                'Check your code and try again'
              )
            }
          )
      } else {
        this.isSubmitting = false
      }
    }
  }

  cancel() {
    this.jwt.destroyToken()
    this.backend.reset()
    this.mode = 'login'
    this.authForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: [false],
    })
    this.router.navigateByUrl('/auth/login')
    this.cd.markForCheck()
  }

  disable() {
    this.authForm.controls.code.setValue('disable')
    this.backend.disable2fa()
    this.submitForm(null)
  }

  openResendDialog(): void {
    const dialogRef = this.dialog.open(DialogResendEmailComponent, {
      width: '300px',
    })

    dialogRef.afterClosed().subscribe(result => {
      this.toasterService.pop(
        'success',
        'VERIFICATION EMAIL SENT',
        'Please check your inbox and confirm your email address to activate your account'
      )
    })
  }
}

/**
 * Dialog for resend email verification form
 */
@Component({
  selector: 'vr-dialog-resend-email',
  template: `
    <h3 style="color: #ffc62d;">Resend Verification Email</h3>
    <form
      [formGroup]="resendEmailForm"
      (ngSubmit)="resendForm()"
      fxLayout="row"
    >
      <mat-form-field>
        <input matInput formControlName="email" placeholder="Email" />
      </mat-form-field>
      <button color="accent" mat-raised-button>
        Send
      </button>
    </form>
  `,
  styles: [
    `
      button {
        max-height: 36px;
        margin-top: 10px;
        margin-left: 5px;
      }
    `,
  ],
})
export class DialogResendEmailComponent implements OnInit {
  resendEmailForm: FormGroup

  constructor(
    public dialogRef: MatDialogRef<DialogResendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    // private auth: AuthService,
    private ggcoinapiService: GgcoinapiService
  ) {}

  ngOnInit() {
    this.resendEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  resendForm() {
    this.ggcoinapiService
      .post('/rest/resendEmailValidation', {
        email: this.resendEmailForm.value.email,
      })
      .subscribe(res => {
        this.dialogRef.close()
      })
  }
}

/**
 * Dynamic component for content in resend verifcation toast
 */
@Component({
  selector: 'vr-toast-resend-email',
  template: `
    <button (click)="openResendDialog()" color="accent">
      Resend Verification Email
    </button>
  `,
  styles: [
    `
      button {
        color: #ffc62d;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        text-transform: capitalize;
        margin-top: 4px;
        text-decoration: none;
        transition: all 0.2s ease;
      }

      button:hover {
        color: #a91892;
        text-decoration: underline;
      }
    `,
  ],
})
export class ToastResendEmailComponent {
  constructor(
    public dialog: MatDialog,
    public toasterService: ToasterService
  ) {}

  openResendDialog(): void {
    const dialogRef = this.dialog.open(DialogResendEmailComponent, {
      width: '300px',
    })

    dialogRef.afterClosed().subscribe(result => {
      this.toasterService.pop(
        'success',
        'VERIFICATION EMAIL SENT',
        'Please check your inbox and confirm your email address to activate your account'
      )
    })
  }
}
