import { ToasterService } from 'angular2-toaster'
import { Observable, Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { StepQuery } from '@app/admin/store/step.query'
import { LocalStorageService } from '@app/core'

import { STEPS } from '../../models/wizardFlow.model'

@Component({
  selector: 'vr-verify-address-type',
  templateUrl: './verify-address-type.component.html',
  styleUrls: ['./verify-address-type.component.scss'],
})
export class VerifyAddressTypeComponent implements OnInit, OnDestroy {
  currentStep: number = 4
  allSteps: Observable<any>
  steps: number
  private sub: Subscription[] = []

  constructor(
    private router: Router,
    private wizardFlowService: WizardFlowService,
    private stepQuery: StepQuery,
    private localStorage: LocalStorageService,
    private wizardDataService: WizardDataService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.allSteps = this.stepQuery.selectAll()
    this.sub.push(
      this.allSteps.subscribe(res => {
        this.steps = res
      })
    )
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  goToType(type: string) {
    const step = this.steps[this.currentStep]
    this.wizardFlowService.setCurrentStep(this.currentStep + 1)
    this.wizardFlowService.validateStep(STEPS.typeAddress)
    this.sub.push(
      this.wizardDataService.updateAddressType(type).subscribe(
        res => {
          this.router.navigate(['/admin/profile/kyc/' + step.path], {
            queryParams: { type },
          })
        },
        err => {
          this.toasterService.pop(
            'error',
            'COULD NOT SAVE DOCUMENT TYPE',
            'Please try refreshing the page and selecting the document type again.'
          )
        }
      )
    )
  }
}
