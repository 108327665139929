import { ToasterService } from 'angular2-toaster'
import { Observable, Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

// 3rd Party Modules
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { DialogTwofaEmailComponent } from '@app/admin/components/dialog-twofa-email/dialog-twofa-email.component'
import { DialogTwofaComponent } from '@app/admin/components/dialog-twofa/dialog-twofa.component'
import { IUser } from '@app/admin/store/session.store'
// Animations
import { ROUTE_TRANSITION } from '@app/app.animation'
import { LIST_FADE_ANIMATION } from '@app/core/utils/list.animation'
import { ApiService } from '@app/shared/services/api.service'
// Services
import { BackendService } from '@app/shared/services/backend.service'

import { SessionQuery } from '../../store/session.query'

@Component({
  selector: 'vr-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [...ROUTE_TRANSITION, ...LIST_FADE_ANIMATION],
  host: { '[@routeTransition]': '' },
})
export class SettingsComponent implements OnInit {
  settings_form: FormGroup
  auth2fa_form: FormGroup
  password_form: FormGroup
  master_password_form: FormGroup
  email_form: FormGroup

  address: FormGroup

  auth2fa_enabled: boolean = true
  auth2fa_type: string = 'DISABLED'
  auth2fa_secret: ''
  auth2fa_qrurl: ''
  auth2fa_verified: boolean = false
  auth2fa_process_completed: boolean = false

  auth2fa_updated: boolean = false

  saving_address: boolean = false
  saving_2fa: boolean = false
  saving_password: boolean = false
  saving_email: boolean = false
  master_password_saving: boolean = false

  ignore_change: boolean = true

  user: IUser

  subscriptions: Subscription[] = []

  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private backend: BackendService,
    public dialog: MatDialog,
    private toasterService: ToasterService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private sessionQuery: SessionQuery,
    private apiService: ApiService
  ) {
    iconRegistry.addSvgIcon(
      'lock-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/lock-icon.min.svg')
    )

    iconRegistry.addSvgIcon(
      'gauth-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/google-authenticator.svg'
      )
    )
  }

  ngOnInit() {
    this.subscriptions.push(
      this.backend
        .getUser()
        .pipe(
          map((res: any) => {
            this.user = res.result
            return res.result
          }),
          switchMap(user => {
            return this.getSettings(user)
          })
        )
        .subscribe((settings: any) => {
          Object.keys(settings.result).some(key => settings.result[key])
            ? (this.auth2fa_enabled = true)
            : (this.auth2fa_enabled = false)

          for (const setting in settings.result) {
            if (settings.result[setting] === true) {
              this.auth2fa_type = setting
            }
          }

          if (this.auth2fa_enabled === true) {
            this.auth2fa_secret = settings.auth2fa.secret
            this.auth2fa_verified = settings.auth2fa.verified

            this.auth2fa_qrurl = settings.auth2fa.qr_url
          }
          // this.auth2fa_enabled = settings.auth2fa.enabled || true
          // this.auth2fa_type = settings.auth2fa.type

          this.cd.markForCheck()
        })
    )
    // this.subscriptions.push(
    // )

    this.settings_form = this.formBuilder.group({
      auth2fa_enabled: [this.auth2fa_enabled, Validators.required],
      auth2fa_type: [this.auth2fa_type, Validators.required],
    })

    this.auth2fa_form = this.formBuilder.group({
      auth2fa_verify: [this.auth2fa_type, Validators.required],
    })

    this.password_form = this.formBuilder.group({
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
    })

    this.master_password_form = this.formBuilder.group({
      mpassword: ['', Validators.required],
      mcpassword: ['', Validators.required],
    })

    this.email_form = this.formBuilder.group({
      email: ['', Validators.required],
      emailc: ['', Validators.required],
    })
  }

  onChange(event: any) {
    if (!this.ignore_change) {
      this.auth2fa_updated = true
    }
    this.ignore_change = false
  }

  dialog2fa = (settings: any) => {
    let dialogComponent

    this.auth2fa_type === 'qr_code'
      ? (dialogComponent = DialogTwofaComponent)
      : (dialogComponent = DialogTwofaEmailComponent)

    const dialogRef = this.dialog.open(dialogComponent, {
      width: '980px',
      data: {
        settings: settings.result,
        completed: this.auth2fa_process_completed,
      },
      disableClose: true,
      panelClass: 'twofa-dialog',
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.twofaEnabled) {
        this.auth2fa_enabled = true
        this.auth2fa_process_completed = result.val
        this.toasterService.pop(
          'success',
          '2FA Enabled',
          'You can now log in to your account using 2FA'
        )
      } else {
        this.disable2fa()
      }
    })
  }

  submitForm() {
    this.saving_2fa = true

    const settings = {
      email: false,
      sms: false,
      qr_code: false,
    }

    const checkSettings = Object.keys(settings)
    const active = checkSettings.find(type => {
      return type === this.settings_form.value.auth2fa_type
    })

    switch (active) {
      case 'email':
        settings.email = true
        break
      case 'qr_code':
        settings.qr_code = true
        break
    }

    this.backend
      .setSettings(this.user.id, settings)
      .subscribe((config: any) => {
        this.auth2fa_updated = false
        this.saving_2fa = false

        settings.qr_code === true
          ? (this.auth2fa_enabled = false)
          : (this.auth2fa_enabled = true)
        this.auth2fa_type = this.settings_form.value.auth2fa_type
        // this.auth2fa_secret = config.auth2fa.secret
        // this.auth2fa_qrurl = config.auth2fa.qr_url
        this.cd.markForCheck()

        if (settings.qr_code === true) {
          this.dialog2fa(config)
        }
      })
  }

  // verify2fa() {
  //   // console.log('verifying 2fa');
  //   this.backend
  //     .test2fa(this.auth2fa_form.controls.auth2fa_verify.value)
  //     .subscribe((res: any) => {
  //       // console.log(res);
  //       if (res.authenticated) {
  //         this.toasterService.pop('success', 'SUCCESS', '2fa succeeded');
  //       } else {
  //         this.toasterService.pop(
  //           'error',
  //           'SOMETHING WENT WRONG',
  //           '2fa failed'
  //         );
  //       }
  //     });
  // }

  getSettings(user: IUser): Observable<any> {
    return this.backend.getSettings(user.id).pipe(
      map((settings: any) => {
        return settings
        // settings.result.hasOwnProperty(true)
        //   ? (this.auth2fa_enabled = true)
        //   : (this.auth2fa_enabled = false)
        //
        // for (let setting in settings.result) {
        //   if (settings.result[setting] === true) {
        //     this.auth2fa_type = setting
        //     console.log(this.auth2fa_type)
        //   }
        // }
        //
        // if (this.auth2fa_enabled === true) {
        //   this.auth2fa_secret = settings.auth2fa.secret
        //   this.auth2fa_verified = settings.auth2fa.verified
        //
        //   this.auth2fa_qrurl = settings.auth2fa.qr_url
        // }
        // // this.auth2fa_enabled = settings.auth2fa.enabled || true
        // // this.auth2fa_type = settings.auth2fa.type
        //
        // this.cd.markForCheck()
      })
    )
  }

  updateEmail() {
    this.saving_email = true

    if (this.email_form.value.email === this.email_form.value.emailc) {
      this.backend.changeEmail(this.email_form.value.email).subscribe(
        (data: any) => {
          this.saving_email = false

          if (data.status === 200 || data.status === 'success') {
            this.toasterService.pop(
              'success',
              'SUCCESS',
              'Your email has been updated'
            )
          } else {
            this.toasterService.pop(
              'error',
              'SOMETHING WENT WRONG',
              'Error: ' + data.result
            )
          }
        },
        err => {
          this.saving_password = false
          this.toasterService.pop(
            'error',
            'SOMETHING WENT WRONG',
            'Your email could not be changed'
          )
        }
      )
    } else {
      this.saving_password = false
      this.toasterService.pop(
        'error',
        'Password Mismatch',
        'Passwords do not match'
      )
    }
  }

  updatePassword() {
    this.saving_password = true

    if (
      this.password_form.value.password === this.password_form.value.cpassword
    ) {
      this.backend
        .changePassword(
          this.password_form.value.current_password,
          this.password_form.value.password
        )
        .subscribe(
          (data: any) => {
            this.saving_password = false
            if (data.status === 200 || data.status === 'success') {
              this.toasterService.pop(
                'success',
                'SUCCESS',
                'Your password has been updated'
              )
            } else {
              this.toasterService.pop(
                'error',
                'SOMETHING WENT WRONG',
                'Error: ' + data.result
              )
            }
          },
          err => {
            console.warn(err)
            this.saving_password = false
            this.toasterService.pop(
              'error',
              'SOMETHING WENT WRONG',
              'Your password could not be changed'
            )
          }
        )
    } else {
      this.saving_password = false
      this.toasterService.pop(
        'error',
        'Password Mismatch',
        'Passwords do not match'
      )
    }
  }

  updateMasterPassword() {
    this.master_password_saving = true

    if (
      this.master_password_form.value.mpassword ===
      this.master_password_form.value.mcpassword
    ) {
      this.backend
        .updateMasterPassword(this.master_password_form.value.mpassword)
        .subscribe(
          data => {
            this.master_password_saving = false
            this.toasterService.pop(
              'success',
              'SUCCESS',
              'Your password has been updated'
            )
          },
          err => {
            this.master_password_saving = false
            this.toasterService.pop(
              'error',
              'SOMETHING WENT WRONG',
              'Your password could not be changed'
            )
          }
        )
    }
  }

  disable2fa() {
    this.apiService
      .postKycApi('/disableQrCodeAuth', { user_id: this.user.id })
      .subscribe(res => {
        if (res.status === 200) {
          this.auth2fa_enabled = false
        }

        this.cd.markForCheck()
      })
  }
}
