import { Component, OnInit, ViewEncapsulation } from '@angular/core'

// import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service'

// import { JwtService } from '../../../../shared/services/jwt.service';
// import { BackendService } from '../../../../shared/services/backend.service';

@Component({
  selector: 'vr-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
  constructor(
    // private router: Router,
    // private jwt: JwtService,
    // private backend: BackendService
    private auth: AuthService
  ) {}

  ngOnInit() {
    // console.log('logged out');
    this.logout()
  }

  logout() {
    this.auth.logout().subscribe(
      function(data: any) {
        if (data.status === 200) {
          // console.log('logged out');
          this.jwt.destroyToken()
          this.auth.removeRememberToken()
          this.backend.reset()

          this.router.navigateByUrl('/login')
        } else if (data.status === 401) {
          this.toasterService.pop('error', 'Logout error ', data.message)
        }
      },
      error => {
        console.warn('auth error', error)
      }
    )
  }
}
