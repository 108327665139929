import { Injectable } from '@angular/core'
import { ID, Store, StoreConfig } from '@datorama/akita'

export interface ITokens {
  ggc: string
  kyc: string
  caa: string
}

export interface IUser {
  id: ID
  addresses: [IAddress]
  company_name: string
  compliance_status: number
  created_at: string
  date_of_birth: string
  date_of_kyc: string
  date_of_registration: string
  date_of_sanction: string
  email: string
  ethAddresses: []
  files: [object]
  first_name: string
  gender: string
  last_name: string
  middle_name: string
  nationality: string
  phone_number: string
  profilePictures: [object]
  role: number
  updated_at: string
  username: string
  ico_status_private: number
  ico_status: number
  id_type: number
  address_type: number
}

export interface IAddress {
  id: number
  address: string
  address_2: string
  city: string
  country: string
  created_at: string
  district: string
  status: number
  updated_at: string
  user_id: ID
  zip_code: string
}

export interface ISessionState {
  user: IUser | null
  // tokens: ITokens | null
}

export function createInitialState(): ISessionState {
  return {
    user: null,
    // tokens: null,
  }
}

export function createSession(user: IUser /*tokens: ITokens*/) {
  return { ...user /*...tokens*/ }
}

@Injectable()
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<ISessionState> {
  constructor() {
    super(createInitialState())
  }

  login(data: IUser /*tokens: ITokens*/) {
    const user = createSession(data /*tokens*/)
    // console.log(user)
    this.update({ user /*tokens*/ })
  }

  logout() {
    this.update(createInitialState())
  }
}
