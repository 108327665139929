import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material'
import { Router } from '@angular/router'

@Component({
  selector: 'vr-personal-details-info',
  templateUrl: './personal-details-info.component.html',
  styleUrls: ['./personal-details-info.component.scss'],
})
export class PersonalDetailsInfoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PersonalDetailsInfoComponent>,
    private router: Router
  ) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close()
  }

  goTo(url: string) {
    this.dialogRef.close()
    this.router.navigate(['/admin/support'])
  }
}
