import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
// 3rd party modules
import { NgModule, Optional, SkipSelf } from '@angular/core'

import { StateService } from '../shared/services/state.service'
import { TransactionsService } from '../shared/services/transactions.service'
// Modules
import { AuthModule } from './auth/auth.module'
import { LayoutModule } from './layout/layout.module'
// Services
import { LocalStorageService } from './local-storage/local-storage.service'
import { MediaReplayService } from './utils/media-replay.service'

export function getInitialState() {
  return LocalStorageService.loadInitialState()
}

@NgModule({
  imports: [CommonModule, HttpClientModule, AuthModule, LayoutModule],
  providers: [
    MediaReplayService,
    LocalStorageService,
    StateService,
    TransactionsService,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule')
    }
  }
}
