import { CookieService } from 'ngx-cookie-service'

import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { LocalStorageService } from '../local-storage/local-storage.service'

@Component({
  selector: 'vr-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  myLanguage: string
  languages = [
    { country: 'gb', value: 'en', viewValue: 'English' },
    { country: 'de', value: 'de', viewValue: 'German' },
    { country: 'es', value: 'es', viewValue: 'Spanish' },
    { country: 'fr', value: 'fr', viewValue: 'French' },
    { country: 'in', value: 'hi', viewValue: 'Hindi' },
    { country: 'it', value: 'it', viewValue: 'Italian' },
    { country: 'jp', value: 'ja', viewValue: 'Japanese' },
    { country: 'kr', value: 'ko', viewValue: 'Korean' },
    { country: 'nl', value: 'nl', viewValue: 'Dutch' },
    { country: 'pt', value: 'pt', viewValue: 'Portuguese' },
    { country: 'ru', value: 'ru', viewValue: 'Russian' },
    // { country: 'ae', value: 'ar', viewValue: 'Arabic' },
    // { country: 'bg', value: 'bg', viewValue: 'Bulgarian' },
    // { country: 'cn', value: 'zh', viewValue: 'Chinese' },
    // { country: 'hr', value: 'hr', viewValue: 'Croatian' },
    // { country: 'cz', value: 'cs', viewValue: 'Czech' },
    // { country: 'dk', value: 'da', viewValue: 'Danish' },
    // { country: 'ee', value: 'et', viewValue: 'Estonian' },
    // { country: 'fi', value: 'fi', viewValue: 'Finnish' },
    // { country: 'gr', value: 'el', viewValue: 'Greek' },
    // { country: 'hu', value: 'hu', viewValue: 'Hungarian' },
    // { country: 'lt', value: 'lt', viewValue: 'Lithuanian' },
    // { country: 'my', value: 'ms', viewValue: 'Malay' },
    // { country: 'no', value: 'no', viewValue: 'Norwegian' },
    // { country: 'pl', value: 'pl', viewValue: 'Polish' },
    // { country: 'ro', value: 'ro', viewValue: 'Romanian' },
    // { country: 'rs', value: 'sr', viewValue: 'Serbian' },
    // { country: 'se', value: 'sv', viewValue: 'Swedish' },
    // { country: 'sk', value: 'sk', viewValue: 'Slovak' },
    // { country: 'si', value: 'sl', viewValue: 'Slovenia' },
    // { country: 'sg', value: 'ta', viewValue: 'Tamil' },
    // { country: 'th', value: 'th', viewValue: 'Thai' },
    // { country: 'tr', value: 'tr', viewValue: 'Turkish' },
    // { country: 'ua', value: 'uk', viewValue: 'Ukranian' },
    // { country: 'vn', value: 'vi', viewValue: 'Vietnamese' },
  ]
  lang: string
  cookieValue = 'UNKNOWN'

  constructor(
    public translate: TranslateService,
    private localStorage: LocalStorageService,
    private cookieService: CookieService
  ) {
    const language = this.localStorage.getItem('lang')
    if (language) {
      this.lang = language.country
      this.translate.use(language.value)
    } else {
      this.lang = 'gb'
      this.translate.use('en')
    }
  }

  ngOnInit() {
    if (this.cookieService.get('vr-lang')) {
      this.cookieValue = this.cookieService.get('vr-lang')
      const cookie = JSON.parse(this.cookieValue)
      const language = {
        value: cookie.value,
        country: cookie.iso,
        viewValue: cookie.name,
      }
      this.selectLocale(language)
    }
  }

  selectLocale(language): void {
    this.translate.use(language.value)
    this.localStorage.setItem('lang', language)
    const lang = {
      value: language.value,
      iso: language.country,
      name: language.viewValue,
    }
    this.cookieService.set('vr-lang', JSON.stringify(lang))
    this.lang = language.country
  }

  mapLang(lang: string) {
    if (lang === 'en') {
      return 'gb'
    } else if (lang === 'zh') {
      return 'cn'
    }

    return lang
  }
}
