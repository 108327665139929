import { Observable } from 'rxjs'

import { noUndefined } from '@angular/compiler/src/util'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'

import { JwtService } from '../../shared/services/jwt.service'

@Injectable()
export class AlwaysAuthGuard implements CanActivate {
  constructor(private router: Router, private jwt: JwtService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.jwt.getToken()
    // TODO Server side validate
    if (token !== undefined && token !== null) {
      return true
    } else {
      this.router.navigate(['/auth/login'])
      return false
    }
  }
}
