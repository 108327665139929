import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Response } from '@angular/http'
import { Router } from '@angular/router'
// import { LocalStorage } from '@ngx-pwa/local-storage'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
import { environment } from '@env/environment'

import { JwtService } from './jwt.service'

@Injectable({
  providedIn: 'root',
})
export class CoinApiService {
  constructor(
    protected http: HttpClient,
    protected jwtService: JwtService,
    protected storage: LocalStorageService,
    protected router: Router,
    handler: HttpBackend
  ) {
    this.http = new HttpClient(handler)
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    }

    if (window.localStorage['appToken']) {
      headersConfig['x-csrf-token'] = window.localStorage['appToken']
    }

    /*if (this.jwtService.getCoinToken()) {
      headersConfig[
        'Authorization'
      ] = `Bearer ${this.jwtService.getCoinToken()}`
      // console.log('Token set: ', this.jwtService.getCoinToken())
    } else {
      console.log('No Coin Token')
    }*/

    return new HttpHeaders(headersConfig)
  }

  private formatErrors(error: any) {
    return observableThrowError(error)
  }

  /**
   * GET request to the GGCoin API
   * @param path
   * @param params
   */
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.coinUrl}${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(
        catchError(this.formatErrors),
        map((res: Response) => res)
      )
  }

  /**
   * POST request to the GGCoin API
   * @param path
   * @param body
   */
  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.coinUrl}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(
        catchError(this.formatErrors),
        map(res => res)
      )
  }
}
