import { AbstractControl } from '@angular/forms'

const WAValidator = require('wallet-address-validator')

export function cryptoValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  // const valid = /^\d+$/.test(control.value);
  if (control.value !== null && control.value !== '') {
    const valid = WAValidator.validate(control.value, 'ETH')
    return valid
      ? null
      : { invalidAddress: { valid: false, value: control.value } }
  }

  return null
}
