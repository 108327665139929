import { Subscription } from 'rxjs'

import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'

import { SLIDE_UP_ROUTE_TRANSITION } from '../../../app.animation'
import { WizardDataService } from '../../../shared/services/wizard-data.service'

@Component({
  selector: 'vr-wizard-personal-details',
  templateUrl: './wizard-personal-details.component.html',
  styleUrls: ['./wizard-personal-details.component.scss'],
  animations: [...SLIDE_UP_ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class WizardPersonalDetailsComponent implements OnInit, OnDestroy {
  @Input() wizardPersonal

  private subscriptions: Subscription[] = []
  private allSteps: any
  private steps: any
  private valid: any
  completed: any
  user: IUser

  constructor(
    private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService,
    public stepQuery: StepQuery
  ) {}

  ngOnInit() {
    /**
     * Init wizard, so that on page reload correct styles are applied
     */
    this.wizardFlowService.initWizard()

    this.setSteps()

    this.allSteps = this.wizardFlowService.getSteps()

    this.subscriptions.push(
      this.wizardDataService.currentUser$.subscribe(
        user => (this.user = user),
        err => {
          throw err
        },
        () => {
          this.wizardFlowService.stepsCompleted(this.user)
        }
      ),
      this.allSteps.subscribe(
        res => {
          this.steps = res
          this.getValid(this.steps)
        },
        err => {
          throw err
        }
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  setSteps() {
    if (this.stepQuery.isPristine) {
      this.wizardFlowService.setSteps()
    }
  }

  getValid(steps: any) {
    let count = 0
    steps.forEach(step => {
      if (step.valid) {
        count += 1
      }
    })
    this.valid = count
    // return count
  }
}
