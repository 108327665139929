import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'vr-nav-bar-pagination',
  templateUrl: './nav-bar-pagination.component.html',
  styleUrls: ['./nav-bar-pagination.component.scss'],
})
export class NavBarPaginationComponent implements DoCheck, AfterViewInit {
  @ViewChild('labelContainer')
  labelContainer: ElementRef
  showScrollButtons = true

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.labelContainer.nativeElement.querySelector(
      '.mat-tab-links'
    ).style.display = 'flex'
  }

  ngDoCheck() {
    if (this.labelContainer) {
      if (
        this.labelContainer.nativeElement.clientWidth -
          this.labelContainer.nativeElement.firstElementChild.clientWidth >
        0
      ) {
        this.showScrollButtons = false
      } else {
        this.showScrollButtons = true
      }
    }
  }

  left() {
    const el = this.el.nativeElement.querySelector('.mat-tab-label-container')
    el.scrollLeft -= 100
  }

  right() {
    const el = this.el.nativeElement.querySelector('.mat-tab-label-container')
    el.scrollLeft += 100
  }
}
