import { AbstractControl } from '@angular/forms'

// Password Confirm validation function
export function PasswordMatcher(control: AbstractControl) {
  if (control.parent) {
    if (control.value == control.parent.get('password').value) {
      return null
    } else {
      return { noMatch: true }
    }
  }
  return null
}
