import { Observable, Subject } from 'rxjs'

import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'

const url = environment.kycUrl + '/file/upload'

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient, private headers: HttpHeaders) {}

  public setHeader(name, value) {
    this.headers.set(name, value)
  }

  public upload(files: Set<File>): { [key: string]: Observable<number> } {
    const status = {}

    files.forEach(file => {
      const formData: FormData = new FormData()
      formData.append('file', file, file.name)

      const req = new HttpRequest('POST', url, formData, {
        headers: this.headers,
        reportProgress: true,
      })

      const progress = new Subject<number>()

      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total)

          progress.next(percentDone)
        } else if (event instanceof HttpResponse) {
          progress.complete()
        }
      })

      status[file.name] = {
        progress: progress.asObservable(),
      }
    })

    return status
  }
}
