// 3rd Party Modules
import { Component, OnInit, ChangeDetectorRef } from '@angular/core'

// Animations
import { ROUTE_TRANSITION } from '../../../app.animation'

// Services
import { BackendService } from '@app/shared/services/backend.service'

@Component({
  selector: 'vr-send-coins',
  templateUrl: './send-coins.component.html',
  styleUrls: ['./send-coins.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class SendCoinsComponent implements OnInit {
  balance = 0
  account = 'Not available yet'
  address: string

  constructor(private cd: ChangeDetectorRef, private backend: BackendService) {}

  ngOnInit() {
    this.getBalance()
  }

  getBalance() {
    const that = this
    this.backend.getBalance().subscribe(
      function(data: any) {
        if (data) {
          that.balance = data.balance
          that.cd.markForCheck()
        }
      },
      error => {
        console.warn('error', 'Failed to get Balance: ', error)
      }
    )
  }

  sendTransaction() {}
}
