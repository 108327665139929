import { Component, OnInit } from '@angular/core'

import { Router, ActivatedRoute } from '@angular/router'
import { BackendService } from '@app/shared/services/backend.service'

@Component({
  selector: 'vr-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  sent: boolean = false
  password: string = ''
  confirmed: string = ''

  constructor(
    private route: ActivatedRoute,
    private backend: BackendService,
    private router: Router
  ) {}

  ngOnInit() {}

  reset() {
    if (this.password === this.confirmed) {
      const token = this.route.snapshot.queryParams['token']

      this.backend.resetPassword(token, this.password).subscribe(data => {
        // console.log(data);
        this.router.navigateByUrl('/auth/login')
      })
    }
  }
}
