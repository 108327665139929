import { Observable, Subscription } from 'rxjs'
import { takeWhile } from 'rxjs/operators'

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { MediaChange } from '@angular/flex-layout'
import { Router } from '@angular/router'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { StepQuery } from '@app/admin/store/step.query'
import { LocalStorageService } from '@app/core'
import { MediaReplayService } from '@app/core/utils/media-replay.service'

import { Step } from '../../models/wizardFlow.model'

@Component({
  selector: 'vr-wizard-nav',
  templateUrl: './wizard-nav.component.html',
  styleUrls: ['./wizard-nav.component.scss'],
})
export class WizardNavComponent implements OnInit, OnDestroy {
  @Input() type: string
  private alive: boolean = true
  steps: any
  allSteps: Observable<any>
  currentStep: number
  hidePrevious: boolean
  isMobile: boolean

  private subscriptions: Subscription[] = []

  constructor(
    private wizardFlowService: WizardFlowService,
    private router: Router,
    private stepQuery: StepQuery,
    private localStorage: LocalStorageService,
    private mediaReplayService: MediaReplayService,
    private cd: ChangeDetectorRef
  ) {
    wizardFlowService.step$
      .pipe(takeWhile(() => this.alive))
      .subscribe(step => {
        this.currentStep = step
      })
  }

  ngOnInit() {
    this.allSteps = this.stepQuery.selectAll()

    this.subscriptions.push(
      this.allSteps.subscribe(res => {
        this.steps = res
      }),
      this.mediaReplayService.media$.subscribe((change: MediaChange) => {
        const isMobile = change.mqAlias === 'xs' || change.mqAlias === 'sm'

        this.isMobile = isMobile
        this.cd.markForCheck()
      })
    )
  }

  ngOnDestroy() {
    this.alive = false
  }

  previousStep() {
    let step = new Step()
    step = this.steps[this.currentStep - 2]
    if (step.id === 4 && step.valid === true) {
      return
    }

    this.wizardFlowService.setCurrentStep(this.currentStep - 1)
    if (step.id !== 6) {
      this.router.navigate(['admin/profile/kyc/' + step.path])
    } else {
      const type = this.localStorage.getItem('id-type')
      this.router.navigate(['admin/profile/kyc/' + step.path], {
        queryParams: { type },
      })
    }
  }

  cancel() {
    this.wizardFlowService.closeWizard()
    this.router.navigateByUrl('/admin/profile')
  }
}
