import { InlineSVGModule } from 'ng-inline-svg'
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha'
import { RecaptchaFormsModule } from 'ng-recaptcha/forms'

import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
// 3rd party modules
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
// import { RouterModule } from '@angular/router'
import { BackendService } from '@app/shared/services/backend.service'
import { ExchangeRatesService } from '@app/shared/services/exchange-rates.service'
import { JwtService } from '@app/shared/services/jwt.service'
import { ReferralsService } from '@app/shared/services/referrals.service'
import { ValidatorService } from '@app/shared/services/validator.service'
import { SharedModule } from '@app/shared/shared.module'

// Components
import {
  DialogResendEmailComponent,
  LoginComponent,
  ToastResendEmailComponent,
} from './components/login/login.component'
import { LogoutComponent } from './components/logout/logout.component'
import {
  RegisterComponent,
  RegisterErrorComponent,
} from './components/register/register.component'
import { JwtInterceptor } from './guards/jwt-interceptor'
// Guards
import { NoAuthGuard } from './guards/no-auth.guard'
import { StatusInterceptor } from './guards/status-interceptor'
// Services
import { AuthService } from './services/auth.service'

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    InlineSVGModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    RegisterErrorComponent,
    DialogResendEmailComponent,
    ToastResendEmailComponent,
  ],
  exports: [LoginComponent, LogoutComponent, RegisterComponent],
  providers: [
    AuthService,
    ValidatorService,
    BackendService,
    ReferralsService,
    ExchangeRatesService,
    JwtService,
    NoAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatusInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LdL-U4UAAAAAFltrm1dxNhtyjuXBy9n3aEQYo-9',
      } as RecaptchaSettings,
    },
  ],
  entryComponents: [
    RegisterErrorComponent,
    DialogResendEmailComponent,
    ToastResendEmailComponent,
  ],
})
export class AuthModule {}
