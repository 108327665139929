import { BehaviorSubject, Observable } from 'rxjs'
import { Router } from '@angular/router'

import { HttpClient } from '@angular/common/http'
import { ApiService } from './api.service'
import { CoinApiService } from './coin-api.service'
import { GgcoinapiService } from './ggcoinapi.service'
import { JwtService } from './jwt.service'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'

import { Injectable } from '@angular/core'
import { Rate, RateGroups, Symbol } from '@app/shared/models/currency-rates'

@Injectable()
export class ExchangeRatesService extends ApiService {
  protected rates: Array<Rate> = []

  constructor(
    protected http: HttpClient,
    protected jwtService: JwtService,
    protected storage: LocalStorageService,
    protected router: Router,
    protected apiService: ApiService,
    private ggcoinapiService: GgcoinapiService,
    private coinapiService: CoinApiService
  ) {
    super(http, jwtService, storage, router)
  }

  private rateToObj(sym: string): Symbol {
    const parts = sym.split(':')
    return {
      cur1: parts[0],
      cur2: parts[1],
    }
  }

  private hasRate(sym: Symbol): boolean {
    const len = this.rates.length

    for (let i = 0; i < len; i++) {
      const r = this.rates[i]
      if (r.cur1 === sym.cur1 && r.cur2 === sym.cur2) {
        return true
      }
    }

    return false
  }

  private splitRates(rates: Array<Symbol>): RateGroups {
    const new_rates = Array<Symbol>()
    const cached_rates = Array<Symbol>()

    for (let i = 0; i < rates.length; i++) {
      if (!this.hasRate(rates[i])) {
        new_rates.push(rates[i])
      } else {
        cached_rates.push(rates[i])
      }
    }

    return {
      new: new_rates,
      cached: cached_rates,
    }
  }

  private getCachedRates(rates: Array<Symbol>): Array<Symbol> {
    const ret = Array<Rate>()

    for (let i = 0; i < rates.length; i++) {
      for (let x = i; x < this.rates.length; x++) {
        if (
          rates[i].cur1 === this.rates[x].cur1 &&
          rates[i].cur2 === this.rates[x].cur2
        ) {
          ret.push(this.rates[x])
        }
      }
    }

    return ret
  }

  //public getRates(rates: Array<Symbol>): Observable<Response> {
  /*const that = this
    const rg: RateGroups = this.splitRates(rates)

    return Observable.create(observer => {
      if (!rg.new.length) {
        observer.next(that.getCachedRates(rg.cached))
        observer.complete()
      } else {
        that.coinapiService.post('/rest/rates/get', rg.new).subscribe(
          function(rates: Array<Rate>) {
            rates.forEach(function(rate) {
              that.rates.push(rate)
            })
            observer.next(that.getCachedRates(rates))
            observer.complete()
          },
          error => {
            console.warn('auth error', error)
          }
        )
      }
    })*/
  //}

  public getCachedRate(rate: Symbol): Rate {
    const len = this.rates.length

    for (let i = 0; i < len; i++) {
      if (
        this.rates[i].cur1 === rate.cur1 &&
        this.rates[i].cur2 === rate.cur2
      ) {
        return this.rates[i]
      }
    }

    return null
  }

  public convertEurToChf(value: number): number {
    const chf_rate: Rate = this.getCachedRate({ cur1: 'CHF', cur2: 'EUR' })
    // console.log(value, chf_rate);
    if (chf_rate) {
      return value * chf_rate.rate
    }

    return 0
  }
}
