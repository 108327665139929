import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material'

@Component({
  selector: 'vr-payment-bank-widget',
  templateUrl: './payment-bank-widget.component.html',
  styleUrls: ['./payment-bank-widget.component.scss'],
})
export class PaymentBankWidgetComponent implements OnInit {
  @Output() event = new EventEmitter<string>()
  @Input() selected

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'bank-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/coins/bank_transfer.svg'
      )
    )
  }

  ngOnInit() {}

  select(cur: string) {
    this.selected = cur
    this.event.emit(cur)
  }
}
