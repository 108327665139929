import { filter, map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Query, toBoolean } from '@datorama/akita'

import { ISessionState, SessionStore } from './session.store'

@Injectable()
export class SessionQuery extends Query<ISessionState> {
  isLoggedIn$ = this.select(({ user }) => toBoolean(user))

  loggedInUser$ = this.select().pipe(
    filter(({ user }) => toBoolean(user)),
    map(
      ({
        user: {
          id: i,
          addresses: a,
          company_name: cn,
          compliance_status: cs,
          created_at: ca,
          date_of_birth: db,
          date_of_kyc: dk,
          date_of_registration: dr,
          date_of_sanction: ds,
          email: e,
          ethAddresses: ea,
          files: f,
          first_name: fn,
          gender: g,
          last_name: ln,
          middle_name: mn,
          nationality: n,
          phone_number: pn,
          profilePictures: pp,
          role: r,
          updated_at: ua,
          username: u,
        },
      }) =>
        `${i} ${a} ${cn} ${cs} ${ca} ${db} ${dk} ${dr} ${ds} ${e} ${ea} ${f} ${fn} ${g} ${ln} ${mn} ${n} ${pn} ${pp} ${r} ${ua} ${u}`
    )
  )

  constructor(protected store: SessionStore) {
    super(store)
  }

  isLoggedIn() {
    return toBoolean(this.getSnapshot().user)
  }
}
