import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { ImagePreviewDialogComponent } from '@app/admin/components/image-preview-dialog/image-preview-dialog.component'
import { WizardAddress } from '@app/admin/models'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { IStep } from '@app/admin/store/step.store'
// import { LocalStorageService } from '@app/core'
import { BackendService } from '@app/shared/services/backend.service'
import { FileValidator } from '@app/shared/validators/file-input.validator'

export const selectedAddressType = {
  0: 'bill',
  1: 'tax_report',
  2: 'bank',
}

export const addressTypes = {
  bill: '0',
  tax_report: '1',
  bank: '2',
}

@Component({
  selector: 'vr-verify-address-upload',
  templateUrl: './verify-address-upload.component.html',
  styleUrls: ['./verify-address-upload.component.scss'],
})
export class VerifyAddressUploadComponent implements OnInit, OnDestroy {
  @ViewChild('imageInput') imageInput: ElementRef
  address: WizardAddress
  form: any
  // isSubmitting: boolean = false;
  files: Set<File> = new Set()
  public message: string
  public imagePath
  private imageUrl = new ReplaySubject<any>()
  public imageUrl$ = this.imageUrl.asObservable()
  user: IUser
  currentStep: number = 5
  step$: Observable<IStep[]>
  steps$: Observable<IStep[]>
  steps: any[]
  fileContent: any
  alreadyHas: boolean = false
  addressType: string

  declinedFirst: boolean = false

  uploading: boolean = false
  progress

  private sub: Subscription[] = []

  constructor(
    private wizardDataService: WizardDataService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private stepQuery: StepQuery,
    private wizardFlowService: WizardFlowService,
    private backendService: BackendService,
    public dialog: MatDialog // private localStorage: LocalStorageService
  ) {
    this.sub.push(
      this.wizardDataService
        .getAddressType()
        .pipe(
          map(
            res =>
              (this.addressType = selectedAddressType[res.result.address_type])
          ),
          switchMap(res => this.backendService.getUser())
        )
        .subscribe((res: any) => {
          this.user = res.result

          if (this.user.files && this.user.files.length > 0) {
            const files: any = this.user.files.filter(
              (file: any) =>
                file.type === 0 &&
                file.doc_type === parseInt(addressTypes[this.addressType])
            )

            if (files.length > 0) {
              this.wizardDataService
                .getImage(files[files.length - 1].id)
                .subscribe(result => {
                  this.showImage(result)
                  this.alreadyHas = true
                })

              files[files.length - 1].status === 2 ||
              files[files.length - 1].status === 3
                ? (this.declinedFirst = true)
                : (this.declinedFirst = false)
            }
          }
        })
    )
  }

  ngOnInit() {
    this.wizardDataService.getPersonalFormData()
    this.address = this.wizardDataService.getVerifyAddress()

    this.steps$ = this.stepQuery.selectAll()
    this.sub.push(
      this.steps$.subscribe(res => {
        this.steps = res
      })
    )
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * show the image if one has already been uploaded
   * @param image
   */
  showImage(image: Blob) {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        this.imageUrl.next(reader.result)
      },
      false
    )

    if (image) {
      reader.readAsDataURL(image)
    }
  }

  /**
   * save the form
   * @param form
   */
  save(form: any): boolean {
    if (this.files.size > 0) {
      this.progress = this.wizardDataService.setVerifyAddress(
        this.files,
        '0',
        addressTypes[this.addressType]
      )
    }

    return true
  }

  /**
   * submit the form and navigate to the next step
   * @param form
   */
  submitForm(form: any) {
    this.uploading = true

    if (this.save(form) && !!this.progress) {
      const allProgressObservables = []
      for (const key in this.progress) {
        if (key) {
          allProgressObservables.push(this.progress[key].progress)
        }
      }

      forkJoin(allProgressObservables).subscribe(
        end => {
          this.uploading = false
          this.wizardFlowService.wizardComplete(this.user).subscribe(res => {
            if (!res) {
              this.nextStep()
              this.wizardFlowService.setCurrentStep(this.currentStep + 1)
            } else {
              this.wizardFlowService.openDialog()
            }
          })
        },
        err => {
          console.warn('something went wrong', err)
        }
      )
    }

    if (this.imageUrl instanceof Observable && this.progress === undefined) {
      this.nextStep()
    }
  }

  nextStep() {
    const step = this.steps[this.currentStep]
    if (step.valid === true) {
      return this.router.navigate(['admin/profile'])
    }
    this.router.navigate(['admin/profile/kyc/' + step.path])
  }

  /**
   * Set the front image to be uploaded
   * @param event
   */
  onUrlChanged(event: any) {
    this.files = new Set()
    const files: { [key: string]: File } = this.imageInput.nativeElement.files
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key])
      }
    }
    this.declinedFirst = false
    this.preview(event.target.files)
    this.cd.detectChanges()
  }

  /**
   * Preview the images to upload
   * @param files
   * @param side
   */
  preview(files) {
    if (files.length === 0) {
      return
    }
    const mimeType = files[0].type
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.'
      return
    }

    const reader = new FileReader()
    this.imagePath = files
    reader.readAsDataURL(files[0])
    reader.onload = _event => {
      this.imageUrl.next(reader.result)
      this.cd.detectChanges()
    }
  }

  /**
   * open dialog with the full size image preview
   * @param image
   */
  openDialog(image: any): void {
    const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
      maxWidth: '960px',
      maxHeight: '90vh',
      panelClass: 'kyc-image-preview',
      data: { img: image },
    })
  }

  /**
   * Go back to dock type selection
   */
  selectDocType() {
    this.router.navigateByUrl('admin/profile/kyc/type-address')
  }
}
