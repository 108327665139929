import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'vr-payment-card-widget',
  templateUrl: './payment-card-widget.component.html',
  styleUrls: ['./payment-card-widget.component.scss'],
})
export class PaymentCardWidgetComponent implements OnInit {
  @Input() selected

  constructor() {}

  ngOnInit() {}
}
