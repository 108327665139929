// 3rd party modules
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FlexLayoutModule } from '@angular/flex-layout'
import {
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatIconModule,
} from '@angular/material'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { InlineSVGModule } from 'ng-inline-svg'
import { LanguageSelectorModule } from '@app/core/language-selector/language-selector.module'

// Components
import { ResetPasswordComponent } from './reset-password.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    InlineSVGModule,
    TranslateModule,
    LanguageSelectorModule,
  ],
  declarations: [ResetPasswordComponent],
})
export class ResetPasswordModule {}
