import { ToasterService } from 'angular2-toaster'

// 3rd party modules
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms'
// Services
import { BackendService } from '@app/shared/services/backend.service'

import { ROUTE_TRANSITION } from '../../../app.animation'

@Component({
  selector: 'vr-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' },
})
export class SupportComponent implements OnInit {
  support: FormGroup
  saving: boolean = false

  constructor(
    private cd: ChangeDetectorRef,
    private backend: BackendService,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    const that = this
    /*this.backend.getState().subscribe((data: any) => {
      that.cd.detectChanges()
    })*/

    this.support = this.formBuilder.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
    })
  }

  sendMessage(data: any, formDirective: FormGroupDirective) {
    if (this.support.valid) {
      this.saving = true

      this.backend
        .sendSupportMessage(
          data.controls.subject.value,
          data.controls.message.value
        )
        .subscribe(
          result => {
            this.toasterService.pop('success', 'Your message has been sent')
            // console.log(result);
            this.saving = false
            // this.clearContactForm();
            formDirective.resetForm()
            this.support.reset()
            this.cd.markForCheck()
          },
          err => {
            this.toasterService.pop(
              'error',
              'There was a problem sending your message. Please try again.'
            )
            this.saving = false
            this.cd.markForCheck()
            throw err
          }
        )
    }
  }
}
