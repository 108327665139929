/**
 * Classes for Personal Details Wizard
 */
export class WizardPersonal {
  firstName: string = ''
  lastName: string = ''
  date_of_birth: string = ''
  user_id: string = ''
  country: string = ''
  address1: string = ''
  address2: string = ''
  zip: string = ''
  city: string = ''
  phoneNumber: string = ''
  phoneNumberPrefix: PhonePrefix
  wallet: string = ''

  // clear() {
  //   this.firstName = '';
  //   this.lastName = '';
  //   this.date_of_birth = '';
  //   this.country = '';
  //   this.address1 = '';
  //   this.address2 = '';
  //   this.zip = '';
  //   this.city = '';
  // }
}

export class WizPersonal {
  firstName: string = ''
  lastName: string = ''
  date_of_birth: string = ''
  phoneNumber: string = ''
  phoneNumberPrefix: PhonePrefix
  // day: string = ''
  // month: string = ''
  // year: string = ''
}

export class PhonePrefix {
  dial_code: string = ''
  name: string = ''
  code: string = ''
}

export class WizAddress {
  user_id: string = ''
  country: string = ''
  address1: string = ''
  address2: string = ''
  zip: string = ''
  city: string = ''
}

export class WizWallet {
  user_id: string = ''
  wallet: string = ''
}

/**
 * Classes for Verify Address Wizard
 */
export class WizardAddress {
  file: object = null
}

/**
 * Classes for Verify ID Wizard
 */
export class WizardId {
  identity_front: object = null
  identity_back?: object = null
  selfie: object = null
}

export class WizId {
  identity_front: object = null
  identity_back?: object = null
}

export class WizSelfie {
  selfie: object = null
}

/**
 * Current step in wizard
 */
export class CurrentStep {
  step: any

  clear() {
    this.step = 0
  }
}
