import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs'

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { ImagePreviewDialogComponent } from '@app/admin/components/image-preview-dialog/image-preview-dialog.component'
import { WizSelfie } from '@app/admin/models'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { IStep } from '@app/admin/store/step.store'
import { BackendService } from '@app/shared/services/backend.service'
import { FileValidator } from '@app/shared/validators/file-input.validator'

import { VerifyIdentityApprovalComponent } from '../verify-identity-approval/verify-identity-approval.component'

@Component({
  selector: 'vr-verify-identity-upload-selfie',
  templateUrl: './verify-identity-upload-selfie.component.html',
  styleUrls: ['./verify-identity-upload-selfie.component.scss'],
})
export class VerifyIdentityUploadSelfieComponent implements OnInit, OnDestroy {
  @ViewChild('imageInput') imageInput: ElementRef
  selfie: WizSelfie
  isSubmitting: boolean = false
  files: Set<File> = new Set()
  file: File
  public message: string
  public imagePath
  private imageUrl = new ReplaySubject<any>()
  public imageUrl$ = this.imageUrl.asObservable()
  user: IUser
  form: any
  steps: any[]
  currentStep: number = 8

  fileContent: any

  step$: Observable<IStep[]>
  steps$: Observable<IStep[]>

  alreadyHas: boolean = false

  declinedFirst: boolean = false

  uploading: boolean = false
  progress

  private sub: Subscription[] = []

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private cd: ChangeDetectorRef,
    private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService,
    private backendService: BackendService,
    private stepQuery: StepQuery
  ) {
    this.sub.push(
      this.backendService.getUser().subscribe((res: any) => {
        this.user = res.result

        if (this.user.files && this.user.files.length > 0) {
          const files: any = this.user.files.filter(
            (file: any) => file.type === 3
          )

          if (files.length > 0) {
            this.wizardDataService
              .getImage(files[files.length - 1].id)
              .subscribe(result => {
                this.showImage(result)
                this.alreadyHas = true
              })

            files[files.length - 1].status === 2 ||
            files[files.length - 1].status === 3
              ? (this.declinedFirst = true)
              : (this.declinedFirst = false)
          }
        }
      })
    )
  }

  showImage(image: Blob) {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        this.imageUrl.next(reader.result)
      },
      false
    )

    if (image) {
      reader.readAsDataURL(image)
    }
  }

  ngOnInit() {
    this.wizardDataService.getPersonalFormData()
    this.selfie = this.wizardDataService.getSelfie()

    this.steps$ = this.stepQuery.selectAll()
    this.sub.push(
      this.steps$.subscribe(res => {
        this.steps = res
      })
    )
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * save the form
   * @param form
   */
  save(form: any): boolean {
    // if (!form.valid) {
    //   return false
    // }
    if (this.files.size > 0) {
      this.progress = this.wizardDataService.setSelfie(this.files, '3', '0')
    }
    return true
  }

  /**
   * submit the form and navigate to the next step
   * @param form
   */
  submitForm(form: any) {
    this.uploading = true

    if (this.save(form) && !!this.progress) {
      const allProgressObservables = []
      for (const key in this.progress) {
        if (key) {
          allProgressObservables.push(this.progress[key].progress)
        }
      }

      // this.router.navigate(['admin/profile/verify-id/upload-selfie']);

      forkJoin(allProgressObservables).subscribe(end => {
        this.uploading = false
        this.wizardFlowService.wizardComplete(this.user).subscribe(res => {
          if (!res) {
            this.nextStep()
            this.wizardFlowService.setCurrentStep(this.currentStep + 1)
            // this.router.navigate(['admin/profile/kyc/' + step.path])
          } else {
            this.wizardFlowService.openDialog()
          }
        })
      })
    }

    if (this.imageUrl instanceof Observable && this.progress === undefined) {
      this.nextStep()
    }
  }

  nextStep() {
    const step = this.steps[this.currentStep]
    if (!step) {
      this.router.navigate(['admin/profile/kyc/'])
    } else {
      this.router.navigate(['admin/profile/kyc/' + step.path])
    }
  }

  /**
   * open confirmation dialog
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(VerifyIdentityApprovalComponent, {
      width: '692px',
      panelClass: 'info-dialog',
    })
    this.sub.push(dialogRef.afterClosed().subscribe())
  }

  /**
   * Set the front image to be uploaded
   * @param event
   */
  onUrlChanged(event) {
    this.files = new Set()
    const files: { [key: string]: File } = this.imageInput.nativeElement.files
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key])
      }
    }
    this.declinedFirst = false
    this.preview(event.target.files)
  }

  /**
   * Preview the images to upload
   * @param files
   * @param side
   */
  preview(files) {
    if (files.length === 0) {
      return
    }
    const mimeType = files[0].type
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.'
      return
    }

    const reader = new FileReader()
    this.imagePath = files
    reader.readAsDataURL(files[0])
    reader.onload = _event => {
      this.imageUrl.next(reader.result)
      this.cd.detectChanges()
    }
  }

  /**
   * open dialog with the full size image preview
   * @param image
   */
  openImageDialog(image: any): void {
    const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
      maxWidth: '960px',
      maxHeight: '90vh',
      // height: '100%',
      panelClass: 'kyc-image-preview',
      data: { img: image },
    })
  }
}
