export class RegUserClient {
  role: number = 3
  email: string
  password: string
  passwordc: string
  captcha: string
}
export class RegUserGingr {
  email: string
  telephone: number
  app_language: number
  password: string
  passwordc: string
}
