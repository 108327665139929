import { CommonModule } from '@angular/common'
// 3rd party modules
import { NgModule } from '@angular/core'

import { Auth2faModule } from './auth2fa/auth2fa.module'
import { ForgotPasswordModule } from './forgot-password/forgot-password.module'
// Modules
import { LoginPageModule } from './login-page/login-page.module'
import { RegisterPageModule } from './register-page/register-page.module'
import { ResetPasswordModule } from './reset-password/reset-password.module'
import { ValidateModule } from './validate/validate.module'

@NgModule({
  imports: [
    CommonModule,
    LoginPageModule,
    RegisterPageModule,
    ForgotPasswordModule,
    Auth2faModule,
    ResetPasswordModule,
    ValidateModule,
  ],
  declarations: [],
})
export class AuthPageModule {}
