import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'

@Component({
  selector: 'vr-verify-private-sale-application',
  templateUrl: './verify-private-sale-application.component.html',
  styleUrls: ['./verify-private-sale-application.component.scss'],
})
export class VerifyPrivateSaleApplicationComponent implements OnInit {
  type: string
  user: string

  loading: boolean

  constructor(
    private route: ActivatedRoute,
    private ggcoinapiService: GgcoinapiService
  ) {}

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type')
    this.user = this.route.snapshot.paramMap.get('user')

    this.loading = true

    this.ggcoinapiService
      .get('/rest/admin/privateSaleApplication/' + this.type + '/' + this.user)
      .subscribe(res => {
        this.loading = false
      })
  }
}
