import { Injectable } from '@angular/core'

@Injectable()
export class StateService {
  private state: any = {}

  constructor() {}

  public set(name: string, val: any) {
    this.state[name] = val
  }

  public get(name: string) {
    return this.state[name]
  }

  public remove(name: string) {
    delete this.state[name]
  }
}
