import { BehaviorSubject, Observable } from 'rxjs'

import { Injectable } from '@angular/core'
import { BackendService } from '@app/shared/services/backend.service'

import { Referrals, ReferralsInterface } from '../models/referrals.model'

@Injectable()
export class ReferralsService {
  protected updated: number = 0
  protected referrals: Referrals = new Referrals()
  protected observable: Observable<ReferralsInterface[]> = null

  constructor(private backend: BackendService) {
    this.sync()
  }

  public sync(): Observable<ReferralsInterface[]> {
    if (!this.observable) {
      this.observable = Observable.create(observer => {
        this.referrals.clear()
        this.backend.getReferrals().subscribe((referrals: any) => {
          this.referrals.clear()

          this.updated = new Date().valueOf()
          this.referrals.insertList(referrals.result)
          observer.next(this.referrals.get())
          observer.complete()
        })
      })
    }
    return this.observable
  }

  public safeGet(): Observable<ReferralsInterface[]> {
    const tm = new Date().valueOf()

    if (this.updated + 3600000 < tm) {
      return this.sync()
    } else {
      return Observable.create(observer => {
        observer.next(this.referrals.get())
        observer.complete()
      })
    }
  }

  public insert(referral: ReferralsInterface) {
    this.referrals.insert(referral)
  }

  public get(): ReferralsInterface[] {
    return this.referrals.get()
  }

  public getLastUpdate(): number {
    return this.updated
  }

  public length() {
    return this.referrals.length()
  }

  public reset() {
    this.referrals = new Referrals()
  }
}
