// 3rd Party Modules
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material'
// Services
import { BackendService } from '@app/shared/services/backend.service'

@Component({
  selector: 'vr-dialog-address',
  templateUrl: './dialog-address.component.html',
  styleUrls: ['./dialog-address.component.scss'],
})
export class DialogAddressComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.openDialog()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogAddressModalComponent, {
      width: '500px',
      panelClass: 'address-dialog',
      disableClose: true,
    })

    dialogRef.afterClosed().subscribe(result => {})
  }
}

@Component({
  selector: 'vr-dialog-address-modal',
  templateUrl: 'dialog-address-modal.html',
  styleUrls: ['./dialog-address.component.scss'],
  providers: [BackendService],
  encapsulation: ViewEncapsulation.None,
})
export class DialogAddressModalComponent implements OnInit {
  address: FormGroup
  saving: boolean = false

  constructor(
    public dialogRef: MatDialogRef<DialogAddressModalComponent>,
    private formBuilder: FormBuilder,
    private backend: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.address = this.formBuilder.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postal_code: ['', Validators.required],
    })
  }

  onNoClick(): void {}

  updateAddress() {
    this.saving = true

    this.backend
      .updateUserAddress(
        this.address.controls.firstname.value,
        this.address.controls.surname.value,
        this.address.controls.address1.value,
        this.address.controls.address2.value,
        this.address.controls.city.value,
        this.address.controls.state.value,
        this.address.controls.postal_code.value
      )
      .subscribe(_ => {
        this.dialogRef.close()
      })
  }
}
