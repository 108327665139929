import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AlwaysAuthGuard } from '@app/shared/guards/always-auth.guard'

import { adminRoutes } from './admin/admin.routing'
import { authPageRoutes } from './auth-page/auth-page.routing'
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component'
import { LayoutComponent } from './core/layout/layout.component'

const routes: Routes = [
  {
    path: '',
    // loadChildren: './pages/admin/admin.module#AdminModule',
    component: LayoutComponent,
    children: [...adminRoutes],
    canActivate: [AlwaysAuthGuard],
  },
  {
    path: 'auth',
    children: [...authPageRoutes],
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
