import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { HttpClient } from '@angular/common/http'
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router'
import { ImagePreviewDialogComponent } from '@app/admin/components/image-preview-dialog/image-preview-dialog.component'
import { WizId } from '@app/admin/models'
import { WizardDataService } from '@app/shared/services/wizard-data.service'
import { WizardFlowService } from '@app/admin/services/wizard-flow.service'
import { IUser } from '@app/admin/store/session.store'
import { StepQuery } from '@app/admin/store/step.query'
import { IStep } from '@app/admin/store/step.store'
import { LocalStorageService } from '@app/core'
import { BackendService } from '@app/shared/services/backend.service'
import { FileValidator } from '@app/shared/validators/file-input.validator'
import { environment } from '@env/environment'

export const selectedIdType = {
  0: 'passport',
  1: 'drivers_license',
  2: 'id',
}

export const idTypes = {
  passport: '0',
  drivers_license: '1',
  id: '2',
}

@Component({
  selector: 'vr-verify-identity-upload-id',
  templateUrl: './verify-identity-upload-id.component.html',
  styleUrls: ['./verify-identity-upload-id.component.scss'],
})
export class VerifyIdentityUploadIdComponent implements OnInit, OnDestroy {
  @ViewChild('frontInput') frontInput: ElementRef
  @ViewChild('backInput') backInput: ElementRef

  id: WizId
  form: any
  front: Set<File> = new Set()
  back: Set<File> = new Set()
  files: Set<File> = new Set()
  public message: string
  public imagePath
  private frontUrl = new ReplaySubject<any>()
  public frontUrl$ = this.frontUrl.asObservable()
  private backUrl = new ReplaySubject<any>()
  public backUrl$ = this.backUrl.asObservable()
  public type: any
  public layout = 'space-between center'
  user: IUser

  fileContent1: any
  fileContent2: any

  private sub: Subscription[] = []

  steps: any
  currentStep: number = 7

  step$: Observable<IStep[]>
  steps$: Observable<IStep[]>

  alreadyHas: boolean = false

  declinedFirst: boolean = false
  declinedSecond: boolean = false

  uploading: boolean = false
  progress

  idType: string

  constructor(
    private wizardDataService: WizardDataService,
    private wizardFlowService: WizardFlowService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private stepQuery: StepQuery,
    private backendService: BackendService,
    private http: HttpClient,
    public dialog: MatDialog,
    private localStorage: LocalStorageService
  ) {
    this.sub.push(
      this.wizardDataService
        .getIdType()
        .pipe(
          map(res => {
            this.idType = selectedIdType[res.result.id_type]
          }),
          switchMap(res => this.backendService.getUser())
        )
        .subscribe((res: any) => {
          this.user = res.result
          if (this.user.files && this.user.files.length > 0) {
            const filesFront: any = this.user.files.filter((file: any) => {
              return (
                file.type === 1 &&
                file.doc_type === parseInt(idTypes[this.idType])
              )
            })
            const filesBack: any = this.user.files.filter((file: any) => {
              return (
                file.type === 2 &&
                file.doc_type === parseInt(idTypes[this.idType])
              )
            })

            if (filesFront.length > 0) {
              this.wizardDataService
                .getImage(filesFront[filesFront.length - 1].id)
                .subscribe(result => {
                  this.showImage(result, 1)
                  this.alreadyHas = true
                })

              filesFront[filesFront.length - 1].status === 2 ||
              filesFront[filesFront.length - 1].status === 3
                ? (this.declinedFirst = true)
                : (this.declinedFirst = false)
            }

            if (filesBack.length > 0) {
              this.wizardDataService
                .getImage(filesBack[filesBack.length - 1].id)
                .subscribe(result => {
                  this.showImage(result, 2)
                  this.alreadyHas = true
                })

              filesBack[filesBack.length - 1].status === 2 ||
              filesBack[filesBack.length - 1].status === 3
                ? (this.declinedSecond = true)
                : (this.declinedSecond = false)
            }
          }
        }),
      wizardFlowService.step$.subscribe(step => {
        this.currentStep = step
      })
    )
  }

  ngOnInit() {
    this.id = this.wizardDataService.getVerifyId()

    this.documentType()

    this.steps$ = this.stepQuery.selectAll()

    this.sub.push(
      this.steps$.subscribe(res => {
        this.steps = res
      })
    )
  }

  ngOnDestroy() {
    this.sub.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * save the form
   * @param form
   */
  save(form: any): boolean {
    const that = this
    this.files = new Set(
      (function*() {
        yield* that.front
        yield* that.back
      })()
    )

    if (this.front.size > 0 && this.back.size === 0) {
      this.progress = this.wizardDataService.setVerifyId(
        this.files,
        '1',
        idTypes[this.idType]
      )
      return true
    }

    if (this.front.size > 0 && this.back.size > 0) {
      this.progress = this.wizardDataService.setVerifyId(
        this.files,
        '1',
        idTypes[this.idType]
      )
      return true
    }

    if (this.front.size === 0 && this.back.size > 0) {
      this.progress = this.wizardDataService.setVerifyId(
        this.files,
        '2',
        idTypes[this.idType]
      )
      return true
    }

    return false
  }

  /**
   * submit the form and navigate to the next step
   * @param form
   */
  submitForm(form: any) {
    this.uploading = true
    const formValid = this.save(form)

    if (formValid && !!this.progress) {
      const allProgressObservables = []
      for (const key in this.progress) {
        if (key) {
          allProgressObservables.push(this.progress[key].progress)
        }
      }

      this.sub.push(
        forkJoin(allProgressObservables).subscribe(end => {
          this.uploading = false
          this.wizardFlowService.wizardComplete(this.user).subscribe(res => {
            if (!res) {
              this.nextStep()
              this.wizardFlowService.setCurrentStep(this.currentStep + 1)
            } else {
              this.wizardFlowService.openDialog()
            }
          })
        })
      )
    }

    if (!formValid && (this.frontUrl || (this.frontUrl && this.backUrl))) {
      this.nextStep()
    }
  }

  nextStep() {
    const step = this.steps[this.currentStep]
    this.router.navigate(['admin/profile/kyc/' + step.path])
  }

  /**
   * Set the front image to be uploaded
   * @param event
   */
  onFrontChanged(event: any) {
    // this.front = event.target.files[0];
    this.front = new Set()
    const files: { [key: string]: File } = this.frontInput.nativeElement.files
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.front.add(files[key])
      }
    }

    this.declinedFirst = false
    this.preview(event.target.files, 'front')
  }

  /**
   * Set the back image to be uploaded
   * @param event
   */
  onBackChanged(event: any) {
    // this.files.delete();
    // this.back = event.target.files[0];
    this.back = new Set()
    const files: { [key: string]: File } = this.backInput.nativeElement.files
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.back.add(files[key])
      }
    }

    this.declinedSecond = false
    this.preview(event.target.files, 'back')
  }

  /**
   * Preview the images to upload
   * @param files
   * @param side
   */
  preview(files, side) {
    if (files.length === 0) {
      return
    }
    const mimeType = files[0].type
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.'
      return
    }

    const reader = new FileReader()
    this.imagePath = files
    reader.readAsDataURL(files[0])
    reader.onload = _event => {
      if (side === 'front') {
        this.frontUrl.next(reader.result)
        this.cd.detectChanges()
      } else if (side === 'back') {
        this.backUrl.next(reader.result)
        this.cd.detectChanges()
      }
    }
  }

  /**
   * check what type of document that the user will be uploading
   * used to determine if the document has to have both sides uploaded
   */
  documentType() {
    if (this.idType) {
      this.type = this.idType
      if (this.type === 'passport') {
        this.layout = 'center center'
      }
    } else {
      this.sub.push(
        this.route.queryParams.subscribe(params => {
          this.type = params['type']
          if (this.type === 'passport') {
            this.layout = 'center center'
          }
        })
      )
    }
  }

  /**
   * open image preview dialog
   * @param image
   */
  openDialog(image: any): void {
    const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
      maxWidth: '1024px',
      maxHeight: '90vh',
      panelClass: 'kyc-image-preview',
      data: { img: image },
    })
  }

  /**
   * get the image if it has already been uploaded
   * @param id
   */
  getImage(id: string): Observable<Blob> {
    return this.http.get(environment.kycUrl + '/file/download/' + id, {
      responseType: 'blob',
    })
  }

  /**
   * display an already uploaded image
   * @param image
   */
  showImage(image: Blob, type: number) {
    const reader = new FileReader()

    reader.onload = e => {
      if (type === 1) {
        this.frontUrl.next(reader.result)
      }

      if (type === 2) {
        this.backUrl.next(reader.result)
      }
    }

    if (image) {
      reader.readAsDataURL(image)
    }
  }

  /**
   * Go back to dock type selection
   */
  selectDocType() {
    this.router.navigateByUrl('admin/profile/kyc/type-id')
  }
}
