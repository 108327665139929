import {
  animate,
  animateChild,
  keyframes,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations'

export const SLIDE_RIGHT_ROUTE_TRANSITION = [
  trigger('routeTransition', [
    state('void', style({ width: '100%', height: '100%' })),
    state('*', style({ width: '100%', height: '100%' })),
    transition(':enter', [
      style({
        transform: 'translateX(-100%)',
        position: 'fixed',
      }),
      animate(
        '0.5s cubic-bezier(0.35, 0, 0.25, 1)',
        style({ transform: 'translateX(0%)' })
      ),
    ]),
    transition(':leave', [
      style({
        transform: 'translateX(0%)',
        position: 'fixed',
      }),
      animate(
        '0.5s cubic-bezier(0.35, 0, 0.25, 1)',
        style({ transform: 'translateX(100%)' })
      ),
    ]),
  ]),
]

export const SLIDE_UP_ROUTE_TRANSITION = [
  trigger('routeTransition', [
    state('void', style({ width: '100%', height: '100%' })),
    state('*', style({ width: '100%', height: '100%' })),
    transition(':enter', [
      style({
        transform: 'translateY(100%)',
        position: 'fixed',
      }),
      animate(
        '0.4s cubic-bezier(0.35, 0, 0.25, 1)',
        style({ transform: 'translateY(-60px)' })
      ),
    ]),
    transition(':leave', [
      style({
        transform: 'translateY(-60px)',
        position: 'fixed',
      }),
      animate(
        '0.4s cubic-bezier(0.35, 0, 0.25, 1)',
        style({ transform: 'translateY(100%)' })
      ),
    ]),
  ]),
]

export const FADE_IN_ROUTE_TRANSITION = [
  trigger('routeTransition', [
    state('void', style({ width: '100%', height: '100%' })),
    state('*', style({ width: '100%', height: '100%' })),
    transition(':enter', [
      style({
        position: 'fixed',
        opacity: '0',
      }),
      animate(
        '0.75s cubic-bezier(0.35, 0, 0.25, 1)',
        style({
          opacity: '1',
        })
      ),
    ]),
    transition(':leave', [
      style({
        opacity: '0',
      }),
    ]),
  ]),
]

export const AUTH_TRANSITION = [
  trigger('routeTransition', [
    state('void', style({ width: '100%', height: '100%' })),
    state('*', style({ width: '100%', height: '100%' })),
    transition(':enter, :leave', [
      query('@*', animateChild(), { optional: true }),
    ]),
    // transition(':enter', [
    //   query(
    //     '.auth-form',
    //     animate(
    //       1000,
    //       keyframes([
    //         style({ transform: 'translateX(-2000px) scale3d(0.2, 0.2, 1)' }),
    //         style({ transform: 'translateX(0px) scale3d(1, 1, 1)' }),
    //       ])
    //     )
    //   ),
    // ]),
    // transition(':leave', [
    //   query(
    //     '.auth-form',
    //     animate(
    //       1000,
    //       keyframes([
    //         style({ transform: 'translateX(0px) scale3d(1, 1, 1)' }),
    //         style({ transform: 'translateX(2000px) scale3d(0.2, 0.2, 1)' }),
    //       ])
    //     )
    //   ),
    // ]),
  ]),
  trigger('flyInOut', [
    transition(':enter', [
      style({
        transform: 'translateX(-3000px) scale3d(0.2, 0.2, 1)',
        overflow: 'hidden',
      }),
      animate(
        1000,
        style({
          transform: 'translateX(0px) scale3d(1, 1, 1)',
          overflow: 'hidden',
        })
      ),
    ]),
    transition(':leave', [
      style({
        transform: 'translateX(0px) scale3d(1, 1, 1)',
        overflow: 'hidden',
      }),
      animate(
        500,
        style({
          transform: 'translateX(2000px) scale3d(0.2, 0.2, 1)',
          overflow: 'hidden',
        })
      ),
    ]),
  ]),
]

export const ROUTE_TRANSITION = FADE_IN_ROUTE_TRANSITION
