import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  HttpClient,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
// import { LocalStorage } from '@ngx-pwa/local-storage'
import { LocalStorageService } from '@app/core/local-storage/local-storage.service'
import { ApiService } from '@app/shared/services/api.service'
import { GgcoinapiService } from '@app/shared/services/ggcoinapi.service'
import { environment } from '@env/environment'

const url = environment.kycUrl + '/contract/upload'

@Injectable({
  providedIn: 'root',
})
export class ContractSigningService {
  constructor(
    protected http: HttpClient,
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private ggcoinapiService: GgcoinapiService
  ) {}

  submitContract(data: Set<File>, type: string) {
    this.upload(data, type)
  }

  sendData(id: any, data: any, type: string): Observable<any> {
    return type === 'public'
      ? this.apiService
          .postKycApi('/contract/createPublic', {
            investment: data.investmentAmount,
          })
          .pipe(map(res => res))
      : this.apiService
          .postKycApi('/contract/create', {
            ggc: data.ggc,
            amount: data.amount,
            currency: data.currency,
            usd_amount: data.usd_amount,
          })
          .pipe(map(res => res))
  }

  downloadContract(fileId: number): Observable<any> {
    return this.apiService
      .getKycApiContract('/contract/download/' + fileId)
      .pipe(map(res => res))
  }

  // whitelistUser(id: number) {
  //   // TODO: when GGCoinAPI is done add method to change user status
  //   // to whitelisted
  // }

  /**
   * File uploader script
   * @param files
   */
  public upload(
    files: Set<File>,
    type: string
  ): { [key: string]: Observable<number> } {
    const status = {}
    const t = type

    let i = 0
    files.forEach(file => {
      const formData: FormData = new FormData()
      i++
      if (i === 1) {
        formData.append('type', type)
      } else {
        const type2 = parseInt(type) + 1
        formData.append('type', type2.toString())
      }

      formData.append('file', file, file.name)

      const req = new HttpRequest('POST', url, formData, {
        reportProgress: true,
      })

      const progress = new Subject<number>()

      this.http.request(req).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total)

            progress.next(percentDone)
          } else if (event instanceof HttpResponse) {
            progress.complete()
          }
        },
        err => {
          this.localStorage.setItem('wizard-upload-' + type, formData)
        },
        () => {
          // this.stepsComplete.next(+1)
        }
      )

      status[file.name] = {
        progress: progress.asObservable(),
      }
    })

    return status
  }

  getContractDetails(type: string): Observable<any> {
    return this.ggcoinapiService
      .post('/rest/getContractDetails', { type })
      .pipe(map(res => res))
  }
}
