import { InlineSVGModule } from 'ng-inline-svg'

import { CommonModule } from '@angular/common'
// 3rd party modules
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule } from '@angular/forms'
import {
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRippleModule,
} from '@angular/material'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'

import { LanguageSelectorModule } from '../language-selector/language-selector.module'
import { ScrollbarModule } from '../scrollbar/scrollbar.module'
import { UtilsModule } from '../utils/utils.module'
import { ToolbarBetaComponent } from './toolbar-beta/toolbar-beta.component'
import { ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    InlineSVGModule,
    MatButtonModule,
    MatIconModule,
    ScrollbarModule,
    MatInputModule,
    MatMenuModule,
    UtilsModule,
    MatRippleModule,
    LanguageSelectorModule,
    TranslateModule,
  ],
  declarations: [ToolbarUserButtonComponent, ToolbarBetaComponent],
  exports: [ToolbarUserButtonComponent, ToolbarBetaComponent],
})
export class ToolbarModule {}
