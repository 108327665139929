import { ToasterService } from 'angular2-toaster'

import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BackendService } from '@app/shared/services/backend.service'

@Component({
  selector: 'vr-dialog-twofa-email',
  templateUrl: './dialog-twofa-email.component.html',
  styleUrls: ['./dialog-twofa-email.component.scss'],
})
export class DialogTwofaEmailComponent implements OnInit {
  auth2fa_form: FormGroup

  constructor(
    public dialogRef: MatDialogRef<DialogTwofaEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private backend: BackendService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.auth2fa_form = this._formBuilder.group({
      auth2fa_verify: [this.data.settings.auth2fa_type, Validators.required],
    })
  }

  verify2fa() {
    // console.log('verifying 2fa');
    // DISABLED FOR NOW
    // this.backend
    //   .test2fa(this.auth2fa_form.controls.auth2fa_verify.value)
    //   .subscribe((res: any) => {
    //     // console.log(res);
    //     const val = this.auth2fa_form.controls.auth2fa_verify.value
    //     if (res.authenticated) {
    //       this.toasterService.pop('success', 'SUCCESS', '2fa succeeded')
    //       this.dialogRef.close({
    //         message: 'Verify',
    //         val,
    //       })
    //     } else {
    //       this.toasterService.pop('error', 'SOMETHING WENT WRONG', '2fa failed')
    //     }
    //   })
  }
}
